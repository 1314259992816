import { ListConfigProperties } from '@workerbase/api/http/user';

import { updateSortingFactory } from '../common/ListConfig/actions';

export enum RolesActions {
  UPDATE_SORTING = '@@roles/UPDATE_SORTING',
  UPDATE_LISTCONFIG_PROPERTIES = '@@roles/UPDATE_LISTCONFIG_PROPERTIES',
  UPDATE_LIST_CONFIG = '@@roles/UPDATE_LIST_CONFIG',
}

/**
 * TODO: needs to be refactored during moving listConfig to the ContextAPI https://workerbase.atlassian.net/browse/WB-4665
 * @deprecated
 */
export const updateSorting = updateSortingFactory(RolesActions.UPDATE_SORTING);
/**
 * TODO: needs to be refactored during moving listConfig to the ContextAPI https://workerbase.atlassian.net/browse/WB-4665
 * @deprecated
 */
export const updateListConfigProperties = (newProperties: ListConfigProperties[]) => ({
  type: RolesActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties },
});
