// TODO: optimize
// This logic is used for every object to handle page sorting, filtering and pagination
// It is here so it is easy to use it in every reducer.
// There is probably a better way to do this...
// https://redux.js.org/recipes/structuring-reducers/reusing-reducer-logic

import { SortOrder } from '@workerbase/domain/common';

import { ListConfigSingle } from '@workerbase/api/http/user';
import { BaseAction } from '../actions';

export interface UpdateListConfigActionPayload extends ListConfigSingle {
  resourceId?: string;
}

export interface UpdateListConfigAction {
  type: string;
  payload: UpdateListConfigActionPayload;
}

// ACTION FACTORIES
export const updatePaginationFactory =
  (action: string) =>
  (pagination: { currentPage?: number; itemsPerPage?: number }): BaseAction => ({
    type: action,
    payload: {
      itemsPerPage: pagination.itemsPerPage,
      currentPage: pagination.currentPage,
    },
  });

export interface UpdateSortingAction {
  type: string;
  payload: {
    selector: string;
    sortDirection: SortOrder;
  };
}
/**
 * TODO: needs to be refactored during moving listConfig to the ContextAPI https://workerbase.atlassian.net/browse/WB-4665
 * @deprecated
 */
export const updateSortingFactory =
  (action: string) =>
  (selector: string, sortOrder: SortOrder): UpdateSortingAction => ({
    type: action,
    payload: {
      selector,
      sortDirection: sortOrder,
    },
  });

export const updateFilteringFactory =
  (action: string) =>
  (searchTerms: string): BaseAction => ({
    type: action,
    payload: {
      searchTerms,
    },
  });

export const updateSelectedTabKeyFactory =
  (action: string) =>
  (selectedTabKey: string): BaseAction => ({
    type: action,
    payload: {
      selectedTabKey,
    },
  });

export const updateListConfigFactory =
  (action: string) =>
  ({ resourceId, sorting, properties }: UpdateListConfigActionPayload): UpdateListConfigAction => {
    const data = {
      type: action,
      payload: {
        resourceId,
        sorting,
        properties,
      },
    };
    return data;
  };
