// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemsList-module__print___WWyC4{overflow:visible !important}.ItemsList-module__itemsList___uWY1y{position:relative}.ItemsList-module__hasCheckbox___WnmW5{height:80px}.ItemsList-module__textField___KVBuD{font-family:Proxima Nova;font-style:normal;font-weight:normal;font-size:16px;line-height:20px;letter-spacing:0px;text-align:center;border:1px solid #cdd2e0;box-sizing:border-box;border-radius:4px;height:40px;display:flex;align-items:center;justify-content:center;overflow:scroll}.ItemsList-module__checkBox___lME6_{flex-basis:100%}.ItemsList-module__error___emH6S{font-family:Proxima Nova;font-style:normal;font-weight:normal;font-size:14px;line-height:20px;letter-spacing:0px;color:#ff6868;display:block;white-space:pre-wrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"print": `ItemsList-module__print___WWyC4`,
	"itemsList": `ItemsList-module__itemsList___uWY1y`,
	"hasCheckbox": `ItemsList-module__hasCheckbox___WnmW5`,
	"textField": `ItemsList-module__textField___KVBuD`,
	"checkBox": `ItemsList-module__checkBox___lME6_`,
	"error": `ItemsList-module__error___emH6S`
};
export default ___CSS_LOADER_EXPORT___;
