import { useContext } from 'react';

import { VariablesContext } from 'context/VariablesContext';

export const useVariables = () => {
  const variables = useContext(VariablesContext);

  if (variables === undefined) {
    console.warn('useVariables hook must be used within a VariablesContextProvider');
  }

  return variables;
};
