import React, { FC, ReactNode, useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import { Badge, Box, IconButton, Stack } from '@mui/material';
import {
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  Title as TitleIcon,
} from '@mui/icons-material';
import { WuiCollapsible } from '@uiKit/WuiCollapsible/WuiCollapsible';

const extensions = [StarterKit, Underline, Placeholder];
// TODO: add translation for the placeholder  'document.builder.info-text.description.placeholder', (there is some issue with instatiating the intl object when using getIntl()
Placeholder.configure({ placeholder: 'Here goes your text....' });

interface WuiRichTextInputProps {
  /** value is stringified HTML */
  value: string;
  isReadOnlyMode?: boolean;
  isToolbarShown?: boolean;
  onChange: (newValue: string) => void;
}

export const WuiRichTextInput: FC<WuiRichTextInputProps> = ({
  value = '',
  isReadOnlyMode,
  isToolbarShown = false,
  onChange,
}) => {
  const editor = useEditor({
    extensions,
    content: value,
    onUpdate: (config) => {
      onChange(config.editor.getHTML());
    },
    editable: !isReadOnlyMode,
  });

  // useEditor "editable" is not triggering a re-render when the value changes, so we need to use this useEffect to update the editable state
  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.setEditable(!isReadOnlyMode);
  }, [editor, isReadOnlyMode]);

  if (!editor) {
    return null;
  }

  return (
    <Stack
      sx={{
        alignItems: 'start',
        [`& .is-empty:first-child::before`]: {
          color: 'grey.400',
          content: 'attr(data-placeholder)',
          float: 'left',
          height: 0,
          fontStyle: 'italic',
          pointerEvents: 'none',
        },
        // reset the default text styles for <strong>, <em>, <u>, <h1>, <h2>, <h3>, don't know why they are unset by default in the reset.css
        '& strong': { fontWeight: 'bold' },
        '& em': { fontStyle: 'italic' },
        '& u': { textDecoration: 'underline' },
        '& h1': { fontSize: '22px', margin: '0.67em 0', fontWeight: 'bold' },
        '& h2': { fontSize: '16px', margin: '0.83em 0', fontWeight: 'bold' },
        '& h3': { fontSize: '14px', margin: '1em 0', fontWeight: 'bold' },
      }}
    >
      <Box
        component={EditorContent}
        editor={editor}
        width="100%"
        sx={{
          cursor: isReadOnlyMode ? 'default' : 'auto',
          '& > *': { outline: 'none' },
        }}
      />
      {isToolbarShown && (
        <WuiCollapsible isOpen>
          <Stack
            direction="row"
            sx={{
              mt: 2,
              direction: 'row',
              width: 'auto',
              border: 1,
              borderRadius: 1,
              borderColor: 'grey.300',
              overflow: 'hidden',
              '& > *:not(:last-child)': {
                p: 1,
                borderRight: 1,
                borderColor: 'grey.300',
              },
            }}
          >
            <ToolbarButton
              icon={TitleIcon}
              isActive={editor.isActive('heading', { level: 1 })}
              badgeContent={1}
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            />
            <ToolbarButton
              icon={TitleIcon}
              isActive={editor.isActive('heading', { level: 2 })}
              badgeContent={2}
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            />
            <ToolbarButton
              icon={TitleIcon}
              isActive={editor.isActive('heading', { level: 3 })}
              badgeContent={3}
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            />
            <ToolbarButton
              icon={FormatBoldIcon}
              isActive={editor.isActive('bold')}
              onClick={() => editor.chain().focus().toggleBold().run()}
            />

            <ToolbarButton
              icon={FormatItalicIcon}
              isActive={editor.isActive('italic')}
              onClick={() => editor.chain().focus().toggleItalic().run()}
            />
            <ToolbarButton
              icon={FormatUnderlinedIcon}
              isActive={editor.isActive('underline')}
              onClick={() => editor.chain().focus().toggleUnderline().run()}
            />
          </Stack>
        </WuiCollapsible>
      )}
    </Stack>
  );
};

interface ToolbarButton {
  icon: typeof FormatBoldIcon;
  isActive?: boolean;
  badgeContent?: ReactNode;
  onClick: () => void;
}
const ToolbarButton: FC<ToolbarButton> = ({ isActive, icon: Icon, badgeContent, onClick }) => (
  <IconButton
    color={isActive ? 'primary' : 'default'}
    sx={{
      width: '56px',
      height: '56px',
      borderRadius: 0,
      p: 1.5,
      backgroundColor: isActive ? 'secondary.main' : 'white',
    }}
    onClick={onClick}
  >
    <Badge
      badgeContent={badgeContent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      overlap="circular"
    >
      <Icon />
    </Badge>
  </IconButton>
);
