import { ListConfigProperties } from '@workerbase/api/http/user';
import { WorkinstructionPOST, WorkinstructionPUT } from '@workerbase/types/api/workinstructions';
import { PaginationMeta } from '@workerbase/types/Response';

import { Workinstruction } from 'services/types/Workinstruction';
import { BaseAction } from '../common/actions';
import {
  updateFilteringFactory,
  updateListConfigFactory,
  updatePaginationFactory,
  updateSortingFactory,
} from '../common/ListConfig/actions';

export enum WorkinstructionsActions {
  GET_WORKINSTRUCTIONS_REQUEST = '@@workinstructions/GET_WORKINSTRUCTIONS_REQUEST',
  GET_WORKINSTRUCTIONS_SUCCESS = '@@workinstructions/GET_WORKINSTRUCTIONS_SUCCESS',
  GET_WORKINSTRUCTIONS_FAILURE = '@@workinstructions/GET_WORKINSTRUCTIONS_FAILURE',
  DELETE_WORKINSTRUCTION_BY_ID_REQUEST = '@@workinstructions/DELETE_WORKINSTRUCTION_BY_ID_REQUEST',
  DELETE_WORKINSTRUCTION_BY_ID_SUCCESS = '@@workinstructions/DELETE_WORKINSTRUCTION_BY_ID_SUCCESS',
  DELETE_WORKINSTRUCTION_BY_ID_FAILURE = '@@workinstructions/DELETE_WORKINSTRUCTION_BY_ID_FAILURE',
  GET_WORKINSTRUCTION_BY_ID_REQUEST = '@@workinstructions/GET_WORKINSTRUCTION_BY_ID_REQUEST',
  GET_WORKINSTRUCTION_BY_ID_SUCCESS = '@@workinstructions/GET_WORKINSTRUCTION_BY_ID_SUCCESS',
  GET_WORKINSTRUCTION_BY_ID_FAILURE = '@@workinstructions/GET_WORKINSTRUCTION_BY_ID_FAILURE',
  CREATE_WORKINSTRUCTION_REQUEST = '@@workinstructions/CREATE_WORKINSTRUCTION_REQUEST',
  UPDATE_WORKINSTRUCTION_BY_ID_REQUEST = '@@workinstructions/UPDATE_WORKINSTRUCTION_BY_ID_REQUEST',
  UPDATE_PAGINATION = '@@workinstructions/UPDATE_PAGINATION',
  UPDATE_SORTING = '@@workinstructions/UPDATE_SORTING',
  UPDATE_FILTERING = '@@workinstructions/UPDATE_FILTERING',
  UPDATE_LISTCONFIG_PROPERTIES = '@@workinstructions/UPDATE_LISTCONFIG_PROPERTIES',
  UPDATE_LIST_CONFIG = '@@workinstructions/UPDATE_LIST_CONFIG',
}

export const getWorkinstructionsRequestAction = (projectId: string): BaseAction => ({
  type: WorkinstructionsActions.GET_WORKINSTRUCTIONS_REQUEST,
  payload: { projectId },
});

export const getWorkinstructionsSuccess = (data: Workinstruction[], meta: PaginationMeta): BaseAction => ({
  type: WorkinstructionsActions.GET_WORKINSTRUCTIONS_SUCCESS,
  payload: {
    workinstructions: data,
    meta,
  },
});

export const getWorkinstructionsError = (errorMessage: string): BaseAction => ({
  type: WorkinstructionsActions.GET_WORKINSTRUCTIONS_FAILURE,
  payload: {
    errorMessage,
  },
});

// GET_WORKINSTRUCTION_BY_ID
export const getWorkinstructionByIdRequestAction = (workinstructionId: string): BaseAction => ({
  type: WorkinstructionsActions.GET_WORKINSTRUCTION_BY_ID_REQUEST,
  payload: { workinstructionId },
});

export const getWorkinstructionByIdSuccess = (data: Workinstruction): BaseAction => ({
  type: WorkinstructionsActions.GET_WORKINSTRUCTION_BY_ID_SUCCESS,
  payload: {
    workinstruction: data,
  },
});

export const getWorkinstructionByIdError = (errorMessage: string): BaseAction => ({
  type: WorkinstructionsActions.GET_WORKINSTRUCTION_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// DELETE_WORKINSTRUCTION_BY_ID
export interface DeleteWorkinstructionByIdRequestAction {
  type: string;
  payload: {
    workinstructionId: string;
  };
}
export const deleteWorkinstructionByIdRequest = (
  workinstructionId: string,
): DeleteWorkinstructionByIdRequestAction => ({
  type: WorkinstructionsActions.DELETE_WORKINSTRUCTION_BY_ID_REQUEST,
  payload: { workinstructionId },
});

export const deleteWorkinstructionByIdSuccess = (
  workinstructionId: string,
): DeleteWorkinstructionByIdRequestAction => ({
  type: WorkinstructionsActions.DELETE_WORKINSTRUCTION_BY_ID_SUCCESS,
  payload: {
    workinstructionId,
  },
});

export const deleteWorkinstructionByIdError = (errorMessage: string): BaseAction => ({
  type: WorkinstructionsActions.DELETE_WORKINSTRUCTION_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// UPDATE_WORKINSTRUCTION_BY_ID
export interface UpdateWorkinstructionByIdRequestAction {
  type: string;
  payload: {
    workinstruction: WorkinstructionPUT;
    workinstructionId: string;
  };
}
export const updateWorkinstructionByIdRequest = (
  workinstructionId: string,
  workinstruction: WorkinstructionPUT,
): UpdateWorkinstructionByIdRequestAction => ({
  type: WorkinstructionsActions.UPDATE_WORKINSTRUCTION_BY_ID_REQUEST,
  payload: { workinstruction, workinstructionId },
});

export interface CreateWorkinstructionRequestAction {
  type: string;
  payload: {
    workinstruction: WorkinstructionPOST;
  };
}
export const createWorkinstructionRequest = (
  workinstruction: WorkinstructionPOST,
): CreateWorkinstructionRequestAction => ({
  type: WorkinstructionsActions.CREATE_WORKINSTRUCTION_REQUEST,
  payload: { workinstruction },
});

export const updatePagination = updatePaginationFactory(WorkinstructionsActions.UPDATE_PAGINATION);
export const updateSorting = updateSortingFactory(WorkinstructionsActions.UPDATE_SORTING);
export const updateFiltering = updateFilteringFactory(WorkinstructionsActions.UPDATE_FILTERING);

export const updateListConfigProperties = (newProperties: ListConfigProperties[]) => ({
  type: WorkinstructionsActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties },
});

export const updateListConfig = updateListConfigFactory(WorkinstructionsActions.UPDATE_LIST_CONFIG);
