import { isEmpty, isNumber } from 'lodash';
import { IFilter, IFilterCondition, isFilterCondition, isFilterGroup } from '@workerbase/domain/common';
import { ConditionOperator, DirectConditionOperator } from '@workerbase/domain/rule';
import { getVariableNamesFromString } from '../getVariableNamesFromString';

const isConditionEmpty = (condition: IFilterCondition<ConditionOperator>) =>
  !isNumber(condition.value) &&
  isEmpty(condition.value) &&
  // Direct condition operators do not have a field
  !Object.values(DirectConditionOperator).includes(condition.operator as DirectConditionOperator);

/*
 * Condition isn't valid if the value is empty OR value is variable without the value
 * TODO: remove after implementation of filter validation - https://workerbase.atlassian.net/browse/WB-4308
 */
export const excludeInvalidConditions = <FilterOperators extends ConditionOperator = ConditionOperator>(
  filter: IFilter<FilterOperators>,
  testVariables?: Record<string, string> | null,
): IFilter<FilterOperators> => ({
  ...filter,
  conditions: filter.conditions.reduce((accumulator: IFilter<FilterOperators>['conditions'], conditionOrGroup) => {
    if (isFilterGroup(conditionOrGroup) && conditionOrGroup.conditions.length) {
      const filterWithoutEmptyConditions = excludeInvalidConditions(conditionOrGroup);
      if (filterWithoutEmptyConditions.conditions.length) {
        accumulator.push(filterWithoutEmptyConditions);
      }
    }
    if (
      isFilterCondition(conditionOrGroup) &&
      !isConditionEmpty(conditionOrGroup) &&
      (!testVariables ||
        getVariableNamesFromString(conditionOrGroup.value).every((variableUsed) => !!testVariables[variableUsed]))
    ) {
      accumulator.push(conditionOrGroup);
    }

    return accumulator;
  }, []),
});
