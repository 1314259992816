import React, { MouseEvent } from 'react';

import { Settings, Toc, CloudDownload } from '@mui/icons-material';

// TODO: use code splitting:
// https://reactjs.org/docs/code-splitting.html
import magnifyingGlassIcon from '../../assets/icons/magnifying-glass.svg';
import plusIcon from '../../assets/icons/plus.svg';

import styles from './ActionButton.module.scss';

export enum ButtonIcons {
  NONE = 'none',
  MAGNIFYING_GLASS = 'magnifying-glass',
  PLUS = 'plus',
  SETTINGS = 'settings',
  PROPERTIES = 'table',
  CLOUD_DOWNLOAD = 'cloud-download',
}

interface Props {
  title: string;
  onClick?;
  icon?: ButtonIcons;
  name?: string;
  disabled?: boolean;
}

const ActionButton: React.FC<Props> = ({ title, onClick, icon = ButtonIcons.NONE, name, disabled = false }) => {
  let iconSource = null;
  let IconComponent;
  switch (icon) {
    case ButtonIcons.MAGNIFYING_GLASS:
      iconSource = magnifyingGlassIcon;
      break;
    case ButtonIcons.PLUS:
      iconSource = plusIcon;
      break;
    case ButtonIcons.SETTINGS:
      IconComponent = Settings;
      break;
    case ButtonIcons.PROPERTIES:
      IconComponent = Toc;
      break;
    case ButtonIcons.CLOUD_DOWNLOAD:
      IconComponent = CloudDownload;
      break;
    default:
      break;
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button className={styles.button} onClick={handleClick} name={name} type="button" disabled={disabled}>
      {iconSource && <img className={styles.icon} src={iconSource} />}
      {IconComponent && <IconComponent fontSize="small" className={styles.icon} />}
      {title}
    </button>
  );
};

export default ActionButton;
