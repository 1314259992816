import { StepTypes } from '../StepTypes.enum';
import { IModuleStep, IStepCore } from '../steps';
import { IStep } from '../Step.interface';

export const RENDERED_STEP_TYPES = Object.values(StepTypes).filter((type) => ![StepTypes.MODULE].includes(type));

export type RenderedStepTypes = (typeof RENDERED_STEP_TYPES)[number];

export type RenderedStep = Exclude<IStep, IModuleStep>;

/**
 * Usually workinstruction steps are rendered, which means that the step will be shown to the user, when executed.
 * There are though exceptions, one is the module step.
 *
 * Rendered and non-rendered steps have very different properties, so this
 * guard was introduced in order to differentiate easier between rendered and non-rendered steps in the code.
 */
export const isRendered = (step: IStepCore): step is RenderedStep =>
  RENDERED_STEP_TYPES.includes(step.type as RenderedStepTypes);
