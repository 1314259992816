/* eslint-disable max-classes-per-file */
import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { IButtonBase, IButtonBaseStyles } from '@workerbase/domain/workinstruction';
import { Type } from 'class-transformer';
import { IsDefined, IsOptional, IsString, ValidateNested } from 'class-validator';
import {
  StepAction,
  StepActionBase,
  StepActionBaseSchema,
  StepActionClassByActionType,
  StepActionDiscriminator,
} from './StepAction';

@Schema({ _id: false })
export class ButtonBaseStyles implements IButtonBaseStyles {
  @Prop()
  @IsString()
  backgroundColor: string;

  @Prop()
  @IsString()
  textColor: string;
}

@Schema({ _id: false })
export class ButtonBase implements IButtonBase {
  @Prop()
  @IsString()
  text: string;

  @Prop({
    type: StepActionBaseSchema,
    discriminators: StepActionDiscriminator,
  })
  @ValidateNested()
  @IsDefined()
  @Type(() => StepActionBase, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: Object.entries(StepActionClassByActionType).map(([actionType, action]) => ({
        name: actionType,
        value: action,
      })),
    },
  })
  action: StepAction;

  @Prop({ type: SchemaFactory.createForClass(ButtonBaseStyles) })
  @IsOptional()
  @ValidateNested()
  @Type(() => ButtonBaseStyles)
  styles?: ButtonBaseStyles;
}
