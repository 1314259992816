import React, { FC } from 'react';
import { IBuilderStepInfoText } from '@workerbase/domain/document';
import { WuiRichTextInput } from '../../../uiKit/WuiRichTextInput/WuiRichTextInput';

interface BuilderStepInfoTextProps {
  value: IBuilderStepInfoText;
  isPreviewMode?: boolean;
  isSelected?: boolean;
  onChange: (value: IBuilderStepInfoText) => void;
}

export const BuilderStepInfoTextInner: FC<BuilderStepInfoTextProps> = ({
  value: stepValue,
  isPreviewMode,
  isSelected,
  onChange,
}) => (
  <WuiRichTextInput
    value={stepValue.description || ''}
    isReadOnlyMode={isPreviewMode}
    isToolbarShown={isSelected}
    onChange={(stringValue) => onChange({ ...stepValue, description: stringValue })}
  />
);

export const BuilderStepInfoText = React.memo(
  BuilderStepInfoTextInner,
  (prevProps, nextProps) =>
    prevProps.value.description === nextProps.value.description &&
    prevProps.isPreviewMode === nextProps.isPreviewMode &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.value.title === nextProps.value.title,
);
