import React, { FC } from 'react';
import { clone, get } from 'lodash';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { Add } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { IFilterGroup } from '@workerbase/domain/common';
import { InputNumberOperators } from '@workerbase/domain/document';
import { WuiActionButton } from '@uiKit/WuiButtons/WuiActionButton';
import { InputNumberSettingsFormValues } from '../../types';
import { FlaggingCriteriaCondition } from './FlaggingCriteriaCondition';
import { DEFAULT_FLAGGING_CRITERIA_CONDITION } from './constants';

interface FlaggingCriteriaGroupProps {
  name: string;
  onDelete: () => void;
}

export const FlaggingCriteriaGroup: FC<FlaggingCriteriaGroupProps> = ({ name, onDelete }) => {
  const intl = useIntl();
  const formik = useFormikContext<InputNumberSettingsFormValues>();

  const flaggingCriteriaGroup: IFilterGroup<InputNumberOperators> = get(formik.values, name);

  const handleAddCondition = () => {
    formik.setFieldValue(`${name}.conditions`, [
      ...flaggingCriteriaGroup.conditions,
      DEFAULT_FLAGGING_CRITERIA_CONDITION,
    ]);
  };

  const handleDeleteCondition = (index: number) => {
    const groupConditions = clone(flaggingCriteriaGroup.conditions);
    groupConditions.splice(index, 1);

    if (groupConditions.length) {
      formik.setFieldValue(`${name}.conditions`, groupConditions);
    } else {
      onDelete();
    }
  };

  return (
    <Stack gap={1.5} sx={{ pb: 2 }}>
      {flaggingCriteriaGroup.conditions?.map((_condition, index) => (
        <FlaggingCriteriaCondition name={`${name}.conditions.${index}`} onDelete={() => handleDeleteCondition(index)} />
      ))}
      <WuiActionButton startIcon={<Add />} sx={{ width: 'fit-content' }} onClick={handleAddCondition}>
        {intl.formatMessage({ id: 'document.builder.input-step-settings.and' })}
      </WuiActionButton>
    </Stack>
  );
};
