import { ApiResponse } from '@workerbase/types/Response';
import { NoteBody, NoteResponse, NotesResponse } from '@workerbase/types/api/notes';
import { IConnectedTo } from '@workerbase/domain/common';
import { api } from './api';

const NOTES_ENDPOINT = '/api/v1/notes';

export const getResourceNotes = async ({ resourceId, resourceType }: IConnectedTo) => {
  const { data } = await api.get<ApiResponse<NotesResponse>>(NOTES_ENDPOINT, {
    params: {
      resourceType,
      resourceId,
    },
  });
  return data.data.notes;
};

export const createNote = async (data: NoteBody) => {
  const { data: responseData } = await api.post<ApiResponse<NoteResponse>>(`${NOTES_ENDPOINT}`, data);

  return responseData.data;
};

export const getNote = async (noteId: string) => {
  const { data: responseData } = await api.get<ApiResponse<NoteResponse>>(`${NOTES_ENDPOINT}/${noteId}`);
  return responseData;
};

export const updateNote = async (noteId: string, data: NoteBody) => {
  const { data: responseData } = await api.put<ApiResponse<NoteResponse>>(`${NOTES_ENDPOINT}/${noteId}`, data);
  return responseData.data;
};

export const deleteNote = async (noteId: string) => {
  await api.delete<{ success: boolean }>(`${NOTES_ENDPOINT}/${noteId}`);
  return true;
};
