import React, { FC } from 'react';
import type { IBuilderStepInputScancode, IDocumentBuilderStepInputScancodeResult } from '@workerbase/domain/document';
import { Button, Stack } from '@mui/material';
import { QrCodeScanner as QrCodeScannerIcon } from '@mui/icons-material';
import { StepTextFieldPlaceholder } from '../StepTextInputPlaceholder';

interface BuilderStepInputScancodeProps {
  value: IBuilderStepInputScancode;
  result?: IDocumentBuilderStepInputScancodeResult;
}

export const BuilderStepInputScancode: FC<BuilderStepInputScancodeProps> = ({ value, result }) => (
  <Stack direction="row" spacing={1} justifyContent="center">
    {value.allowManualInput && <StepTextFieldPlaceholder>{result?.value || null}</StepTextFieldPlaceholder>}
    {!result && (
      <Button disabled>
        <QrCodeScannerIcon />
      </Button>
    )}
  </Stack>
);
