import React from 'react';
import { Delete } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { FileUploadStatus } from './types';

// TODO: add translations
export const LABEL_BY_STATUS = {
  [FileUploadStatus.Loading]: 'Loading',
  [FileUploadStatus.Success]: 'Success',
  [FileUploadStatus.Error]: 'Error',
} as const;

export const ACTION_ICON_BY_STATUS = {
  [FileUploadStatus.Loading]: <CircularProgress size={24} />,
  [FileUploadStatus.Success]: <Delete fontSize="small" />,
  [FileUploadStatus.Error]: <Delete fontSize="small" />,
} as const;
