import React from 'react';
import Box from '@mui/material/Box';
import { FCWithChildren, SuggestiveStringLiteral } from '@types';
import { Duration, Easing, duration as durationPresets, easing as easingPresets } from '@mui/material';

/**
 * A collapsible component that can be used to hide and show content. Advantage of this approach compared to others
 * is that it will adjust the height based on the content inside with the usage of CSS only (no JS required).
 * Meaning that the animation is smooth and cheap.
 */
export const WuiCollapsible: FCWithChildren<{
  isOpen: boolean;
  duration?: keyof Duration | number;
  easing?: SuggestiveStringLiteral<keyof Easing>;
}> = ({ isOpen, children, duration, easing }) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateRows: isOpen ? '1fr' : '0fr',
      overflow: 'hidden',
      transition: (theme) =>
        theme.transitions.create('grid-template-rows', {
          duration: (duration && durationPresets[duration]) ?? duration ?? theme.transitions.duration.enteringScreen,
          easing: (easing && easingPresets[easing]) ?? easing ?? theme.transitions.easing.easeOut,
        }),
    }}
  >
    <Box sx={{ minHeight: 0 }}>{children}</Box>
  </Box>
);
