import { AnyAction } from 'redux';

import { ConnectorTypes } from '@workerbase/domain/connector';
import { SortOrder } from '@workerbase/domain/common';
import { ListConfigPropertySelectors, TabActiveColorTypes } from '@workerbase/domain/user';
import {
  ListConfigFiltering,
  ListConfigPagination,
  ListConfigSorting,
  ListConfigTabs,
} from '@workerbase/api/http/user';
import { ConnectorsListConfig } from '@workerbase/api/http/connector';
import { PaginationMeta } from '@workerbase/types/Response';

import { normalizeConnector } from 'services/normalizers/connectors';
import { Connector } from 'services/types/Connector';

import { UpdateListConfigActionPayload } from '../common/ListConfig/actions';
import {
  updateFiltering,
  updateListConfig,
  updatePagination,
  updateSorting,
  updateStateTabs,
  updateSelectedTabKey,
} from '../common/ListConfig/reducers';
import { ConnectorsActions, IncomingMqttEventAction } from './actions';

export type ConnectorsState = Readonly<{
  connectorsById: { [key: string]: Connector };
  listConfigs: ConnectorsListConfig;
  currentListItemsIds: string[];
  errorMessage: string | null;
}>;

export const initialState: ConnectorsState = {
  connectorsById: {},
  listConfigs: {
    properties: [
      {
        selector: 'id',
        omit: true,
      },
      {
        omit: false,
        selector: 'type',
      },
      {
        selector: 'name',
        omit: false,
      },
      {
        selector: 'description',
        omit: true,
      },
      {
        selector: 'triggeredAt',
        omit: false,
      },
      {
        selector: 'variables',
        omit: false,
      },
      {
        selector: ListConfigPropertySelectors.LINKS,
        omit: false,
      },
    ],
    tabs: {
      selectedTabKey: ConnectorTypes.HTTP_WEBHOOK,
      categoryKey: 'type',
      categories: ['type'],
      options: [
        {
          key: ConnectorTypes.HTTP_WEBHOOK,
          titleTranslationKey: 'connectors.http-webhooks',
          count: 0,
          activeColor: TabActiveColorTypes.DEFAULT,
        },
        {
          key: ConnectorTypes.MQTT_CONNECTOR,
          titleTranslationKey: 'connectors.mqtt-connectors',
          count: 0,
          activeColor: TabActiveColorTypes.DEFAULT,
        },
      ],
    },
    pagination: { currentPage: 1, itemsPerPage: 20 },
    sorting: { selector: 'name', sortDirection: SortOrder.ASC },
    filtering: {
      searchTerms: '',
    },
  },
  currentListItemsIds: [],
  errorMessage: null,
};

interface GetConnectorsSuccessActionPayload {
  connectors: Connector[];
  meta: PaginationMeta;
}

interface GetConnectorSuccessActionPayload {
  connector: Connector;
}

interface GetConnectorsFailureActionPayload {
  errorMessage: string;
}

// eslint-disable-next-line complexity
const reducer = (state: ConnectorsState = initialState, action: AnyAction): ConnectorsState => {
  switch (action.type) {
    case ConnectorsActions.GET_CONNECTORS_SUCCESS: {
      const payload = action.payload as GetConnectorsSuccessActionPayload;

      const connectorsById = payload.connectors.reduce((prev, connector) => {
        const updatedConnectors = prev;
        updatedConnectors[connector.id] = connector;
        return prev;
      }, {});

      return {
        ...state,
        connectorsById: {
          ...state.connectorsById,
          ...connectorsById,
        },
        listConfigs: {
          ...state.listConfigs,
          properties: [
            ...(state.listConfigs.properties || []),
            ...(state.listConfigs.tabs?.selectedTabKey === ConnectorTypes.MQTT_CONNECTOR &&
            !state.listConfigs.properties?.find((prop) => prop.selector === 'status')
              ? [
                  {
                    selector: 'status',
                    omit: false,
                  },
                ]
              : []),
          ],
          tabs: updateStateTabs(
            initialState.listConfigs.tabs,
            state.listConfigs.tabs,
            payload.meta.category,
            payload.meta.categoryKey,
            payload.meta.stats,
          ),
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
        },
        currentListItemsIds: payload.connectors.map((connector) => connector.id),
      };
    }
    case ConnectorsActions.GET_CONNECTOR_BY_ID_SUCCESS: {
      const payload = action.payload as GetConnectorSuccessActionPayload;
      const fetchedConnector = payload.connector;
      const connector = {
        [fetchedConnector.id]: fetchedConnector,
      };

      return {
        ...state,
        connectorsById: {
          ...state.connectorsById,
          ...connector,
        },
      };
    }
    case ConnectorsActions.GET_CONNECTOR_BY_ID_FAILURE: {
      const payload = action.payload as GetConnectorsFailureActionPayload;
      return {
        ...initialState,
        listConfigs: {
          ...state.listConfigs,
          tabs: updateStateTabs(initialState.listConfigs.tabs, state.listConfigs.tabs),
        },
        errorMessage: payload.errorMessage,
      };
    }
    case ConnectorsActions.DELETE_CONNECTOR_BY_ID_SUCCESS: {
      const connectorId: string = action.payload.connectorId;

      const connectorsById = { ...state.connectorsById };
      if (connectorsById[connectorId]) {
        delete connectorsById[connectorId];
      }

      return {
        ...state,
        connectorsById,
        currentListItemsIds: state.currentListItemsIds.filter((itemId) => itemId !== connectorId),
      };
    }
    case ConnectorsActions.DELETE_CONNECTOR_BY_ID_FAILURE: {
      const payload = action.payload as GetConnectorsFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    }
    case ConnectorsActions.UPDATE_PAGINATION: {
      const payload = action.payload as ListConfigPagination;
      return updatePagination(state, payload);
    }
    case ConnectorsActions.UPDATE_SORTING: {
      const payload = action.payload as ListConfigSorting;
      return updateSorting(state, payload);
    }
    case ConnectorsActions.UPDATE_FILTERING: {
      const payload = action.payload as ListConfigFiltering;
      return updateFiltering(state, payload);
    }
    case ConnectorsActions.UPDATE_LISTCONFIG_PROPERTIES:
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          properties: [
            ...action.payload.properties,
            ...(state.listConfigs.tabs?.selectedTabKey === ConnectorTypes.MQTT_CONNECTOR &&
            !action.payload.properties.find((prop) => prop.selector === 'status')
              ? [
                  {
                    selector: 'status',
                    omit: false,
                  },
                ]
              : []),
          ],
        },
      };
    case ConnectorsActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    case ConnectorsActions.UPDATE_SELECTED_TAB_KEY: {
      const payload = action.payload as ListConfigTabs;
      return updateSelectedTabKey(state, payload);
    }
    case ConnectorsActions.HANDLE_INCOMING_MQTT_EVENT: {
      const { payload } = action as IncomingMqttEventAction;
      const connector = normalizeConnector(payload.message.data);
      if (!connector) {
        return { ...state };
      }

      return {
        ...state,
        connectorsById: {
          ...state.connectorsById,
          [connector.id]: connector,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
