import { capitalize } from 'lodash';
import { Option } from './types/Option';
import { GroupOption } from './types/GroupOption';

export const enumToOptions = (enumObj: Record<string, string>): Option[] =>
  Object.values(enumObj).map((enumValue) => ({ label: capitalize(enumValue), value: enumValue }));

export const enableAllGroupOptions = (groupOptions: GroupOption[]): GroupOption[] =>
  groupOptions.map((group) => ({
    ...group,
    options: group.options.map((option) => ({ ...option, isDisabled: false })),
  }));

export const disableGroupOptionsBy = (
  groupOptions: GroupOption[],
  iteratee: (group: GroupOption) => boolean,
): GroupOption[] =>
  groupOptions.map((group) => ({
    ...group,
    options: group.options.map((option) => ({ ...option, isDisabled: iteratee(group) })),
  }));
