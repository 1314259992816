import { IInputStepBase, IStepCore } from '../steps/Base';
import { StepTypes } from '../StepTypes.enum';

export const INPUTABLE_STEPS = [
  StepTypes.INPUT_BARCODE,
  StepTypes.INPUT_TEXT,
  StepTypes.INPUT_NUMBER,
  StepTypes.INPUT_NUMBER_PICKER,
  StepTypes.INPUT_VALUE_LIST,
  StepTypes.INPUT_CHECKBOX_LIST,
  StepTypes.INPUT_INDEX_LIST,
  StepTypes.INPUT_LOCATION,
  StepTypes.INPUT_BUTTON_LIST,
  StepTypes.INPUT_TILE_LIST,
  StepTypes.INPUT_AUDIO,
  StepTypes.INPUT_PHOTO,
  StepTypes.INPUT_STEP_MENU,
  StepTypes.INPUT_VIDEO,
  StepTypes.INPUT_NOISE_LEVEL,
] as const;

export type InputableSteps = (typeof INPUTABLE_STEPS)[number];

export const isInputable = (step: IStepCore): step is IInputStepBase =>
  INPUTABLE_STEPS.includes(step.type as InputableSteps);
