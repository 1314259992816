import React, { FC, FocusEvent, useMemo } from 'react';
import Select, { Props as ReactSelectProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import Field from 'components/Input/Field';
import { getStyles, theme } from './SelectInput.styles';
import { Option } from './types/Option';

interface FieldProps {
  label?: string;
  labelIsTranslationId?: boolean;
  tooltipTranslationId?: string;
  required?: boolean;
  error?: string | null;
  isEnabled?: boolean;
  onToggle?: (isEnabled: boolean) => void;
}

interface SelectProps
  extends Pick<
    ReactSelectProps<Option>,
    | 'placeholder'
    | 'isMulti'
    | 'isSearchable'
    | 'onInputChange'
    | 'filterOption'
    | 'isDisabled'
    | 'isClearable'
    | 'isLoading'
    | 'isOptionDisabled'
  > {
  // @override - use only options but not groups
  options?: Option[];
}

export interface SelectInputsProps extends FieldProps, SelectProps {
  isCreatable?: boolean;
  field: {
    onChange: (values: string | string[]) => void;
    name?: string;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    value?: string | string[];
  };
  form?: {
    setFieldValue?: (fieldName?: string, value?: string | string[]) => void;
  };
  variables?: string[];
}

export const SelectInput: FC<SelectInputsProps> = ({
  error,
  label,
  field,
  options: optionsBase = [],
  isMulti,
  isSearchable,
  form,
  required = false,
  isDisabled = false,
  labelIsTranslationId = true,
  isCreatable = false,
  isEnabled,
  isClearable = false,
  isLoading = false,
  filterOption,
  placeholder,
  tooltipTranslationId,
  onToggle,
  variables,
  onInputChange = () => {},
}) => {
  const options = [...optionsBase];

  if (variables) {
    options.push(
      ...variables.map((variable) => ({
        value: `$${variable}`,
        label: `$${variable}`,
      })),
    );
  }

  const onChange: ReactSelectProps['onChange'] = (option, action) => {
    let value: string | string[];
    if (isMulti) {
      if (option) {
        value = (option as Option[]).map((item: Option) => item.value);
      } else {
        value = [];
      }
    } else if (action.action === 'clear') {
      value = '';
    } else {
      value = (option as Option).value;
    }

    if (form && form.setFieldValue) {
      form.setFieldValue(field.name, value);
    } else {
      field.onChange(value);
    }
  };

  const mapValuesToOptions = (options: Option[], value: SelectInputsProps['field']['value']): Option[] => {
    const valueAsArray = Array.isArray(value) ? value : [value];
    const defaultValue: Option[] = options.filter((option) => !!valueAsArray?.includes(option.value));

    return defaultValue;
  };

  const selectedOptions = useMemo(() => mapValuesToOptions(options, field.value), [field.value, options]);

  const selectProps: ReactSelectProps<Option> = {
    name: field.name,
    placeholder,
    value: selectedOptions,
    defaultValue: selectedOptions,
    options,
    filterOption,
    onChange,
    onInputChange,
    onBlur: (e: FocusEvent<HTMLInputElement>) => {
      if (field.onBlur && field.name) {
        e.target.name = field.name;
        field.onBlur(e);
      }
    },
    classNamePrefix: 'baseSelect',
    styles: getStyles(!!error),
    theme,
    isLoading,
    isDisabled,
    isClearable,
    isMulti,
    isSearchable,
    isOptionDisabled: (option: Option) => option.disabled || false,
  };

  // eslint-disable-next-line react/jsx-props-no-spreading -- allow spread operator here
  const getSelectComponent = () => (isCreatable ? <CreatableSelect {...selectProps} /> : <Select {...selectProps} />);

  if (label || error) {
    return (
      <Field
        isEnabled={isEnabled}
        onToggle={onToggle}
        error={error}
        label={label}
        fieldName={field.name}
        tooltipTranslationId={tooltipTranslationId}
        required={required}
        labelIsTranslationId={labelIsTranslationId}
      >
        {getSelectComponent()}
      </Field>
    );
  }

  return getSelectComponent();
};
