import React, { FC } from 'react';
import { Replay, UploadFile } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { ACTION_ICON_BY_STATUS, LABEL_BY_STATUS } from './WuiFileInput.constant';
import { FileUploadStatus } from './types';

interface FileUploadItemProps {
  status: FileUploadStatus;
  name: string;
  size: string;
  disabled?: boolean;
  onRetry: () => void;
  onDelete: () => void;
}

export const WuiFileInputItem: FC<FileUploadItemProps> = ({ status, name, size, disabled, onRetry, onDelete }) => (
  <Stack
    direction="row"
    sx={{
      alignItems: 'center',
      boxSizing: 'border-box',
      width: '100%',
      height: '100px',
      px: 2,
      borderColor: 'divider',
    }}
  >
    <UploadFile sx={{ color: status === FileUploadStatus.Error ? 'error.main' : 'primary.main', ml: 1, mr: 2 }} />

    <Stack>
      <Typography lineHeight="21px">{name}</Typography>
      <Stack direction="row" gap={1}>
        {size && (
          <Typography color="grey.500" variant="body2">
            {size}
          </Typography>
        )}
        <Typography color="grey.500" variant="body2">
          •
        </Typography>
        <Stack direction="row" alignItems="center">
          <Typography color="grey.500" variant="body2" mr="3px">
            {LABEL_BY_STATUS[status]}
          </Typography>
        </Stack>
      </Stack>
    </Stack>

    {status === FileUploadStatus.Error && (
      <IconButton
        sx={{
          ml: 'auto',
          backgroundColor: 'grey.50',
          pointerEvents: 'auto',
        }}
        disabled={disabled}
        onClick={onRetry}
      >
        <Replay fontSize="small" />
      </IconButton>
    )}

    <IconButton
      sx={{
        ml: 'auto',
        backgroundColor: status === FileUploadStatus.Loading ? 'transparent' : 'grey.50',
        pointerEvents: status === FileUploadStatus.Loading ? 'none' : 'auto',
      }}
      onClick={onDelete}
      disabled={disabled}
    >
      {ACTION_ICON_BY_STATUS[status]}
    </IconButton>
  </Stack>
);
