import React, { FC } from 'react';
import type { IBuilderStepInputNumber, IDocumentBuilderStepInputNumberResult } from '@workerbase/domain/document';
import { StepTextFieldPlaceholder } from '../StepTextInputPlaceholder';

interface BuilderStepInputNumberProps {
  value: IBuilderStepInputNumber;
  result?: IDocumentBuilderStepInputNumberResult;
}

export const BuilderStepInputNumber: FC<BuilderStepInputNumberProps> = ({ value, result }) => (
  <StepTextFieldPlaceholder>{result?.value ?? value.defaultValue ?? ''}</StepTextFieldPlaceholder>
);
