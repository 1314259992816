import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { VariablesMentionInput } from 'components/Input/VariablesMentionInput';
import Field from 'components/Input/Field';
import { useFocus } from 'hooks/useFocus';
import React, { ChangeEvent, useState, FocusEvent } from 'react';
import styles from './TextInput.module.scss';

// This component can work as standalone or as a Formik Field
export interface TextInputProps {
  type: string;
  isPositive?: boolean;
  label?: string;
  description?: string;
  min?: number;
  max?: number;
  placeholder?: string;
  labelIsTranslationId?: boolean;
  field: {
    name?: string;
    onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    value?: string;
    onHitEnter?: () => void; // called when user hit ENTER, works only in non variable mode
  };
  error?: string | null;
  successMessage?: string | null;
  required?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  tooltipTranslationId?: string;
  prefix?: string;
  // if provided, will activate the variable autocomplete mode.
  // variable should NOT have the $ already set.
  variablesAutocompleteEnabled?: boolean;
  enableCopy?: boolean;
  enableShowPassword?: boolean;
}

// eslint-disable-next-line complexity
export const TextInput: React.FC<TextInputProps> = ({
  type,
  min,
  max,
  isPositive = false,
  label,
  description,
  field,
  placeholder,
  labelIsTranslationId = true,
  error,
  successMessage,
  required = false,
  autoComplete,
  disabled = false,
  tooltipTranslationId,
  prefix,
  variablesAutocompleteEnabled = false,
  enableCopy = false,
  enableShowPassword = false,
}) => {
  const [inputRef, setFocus] = useFocus<HTMLInputElement>();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleCopy = (e) => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && field.onHitEnter) {
      field.onHitEnter();
    }
  };

  const ensureMinAndMaxValue = (e) => {
    const result = e;
    if (min !== undefined && Number(e.target.value) < min) {
      result.target.value = String(min);
    }
    if (max !== undefined && Number(e.target.value) > max) {
      result.target.value = String(max);
    }
    return result;
  };
  const inputType = enableShowPassword && isPasswordVisible ? 'text' : type;

  const inputComponent = (
    <div className={`${styles.inputContainer} ${error ? styles.redBorder : ''} ${disabled ? styles.disabled : ''}`}>
      {prefix && (
        <div className={styles.prefix} onClick={setFocus}>
          {prefix}
        </div>
      )}
      {variablesAutocompleteEnabled ? (
        <VariablesMentionInput
          name={field.name}
          onChange={field.onChange}
          value={field.value}
          onBlur={field.onBlur}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : (
        <input
          ref={inputRef}
          name={field.name}
          className={styles.input}
          min={type === 'number' && isPositive ? 0 : min}
          max={type === 'number' && max ? max : undefined}
          step={type === 'number' && isPositive ? 1 : undefined}
          type={inputType}
          placeholder={placeholder}
          onChange={(e) => {
            field.onChange(ensureMinAndMaxValue(e));
          }}
          value={field.value}
          onBlur={field.onBlur}
          autoComplete={autoComplete}
          disabled={disabled}
          onKeyDown={handleKeyDown}
        />
      )}
      {enableShowPassword && (
        <div className={styles.suffix}>
          {isPasswordVisible ? (
            <Tooltip title="Hide password">
              <IconButton aria-label="hide-password" size="small" onClick={() => setIsPasswordVisible(false)}>
                <VisibilityOffIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Show password">
              <IconButton aria-label="show-password" size="small" onClick={() => setIsPasswordVisible(true)}>
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
      {enableCopy && (
        <div className={styles.suffix}>
          <Tooltip title="Copy to clipboard">
            <IconButton aria-label="copy-to-clipboard" size="small" onClick={handleCopy}>
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );

  if (label || description) {
    return (
      <Field
        error={error}
        successMessage={successMessage}
        label={label}
        description={description}
        labelIsTranslationId={labelIsTranslationId}
        fieldName={field.name}
        required={required}
        tooltipTranslationId={tooltipTranslationId}
      >
        {inputComponent}
      </Field>
    );
  }

  return inputComponent;
};

export default TextInput;
