import { ComparingConditionOperator } from '../../rule';
import { ValueOf } from '../../utils';

export const InputNumberOperators = Object.freeze({
  EQUAL: ComparingConditionOperator.EQUAL,
  DOES_NOT_EQUAL: ComparingConditionOperator.DOES_NOT_EQUAL,
  GREATER_THAN: ComparingConditionOperator.GREATER_THAN,
  GREATER_OR_EQUAL: ComparingConditionOperator.GREATER_OR_EQUAL,
  LESS_THAN: ComparingConditionOperator.LESS_THAN,
  LESS_OR_EQUAL: ComparingConditionOperator.LESS_OR_EQUAL,
});

export type InputNumberOperators = ValueOf<typeof InputNumberOperators>;
