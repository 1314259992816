export enum TriggerSource {
  BARCODE_SCAN = 'BarcodeScan',
  DATABASE_ITEM_CHANGE = 'DatabaseItemChange',
  FORM_CHANGE = 'FormChange',
  FUNCTION_CALL = 'FunctionCall',
  INCOMING_EMAIL = 'IncomingEmail',
  INCOMING_EVENT = 'IncomingEvent', // Webhook Connector
  LOCATION_CHANGE = 'LocationChange',
  MQTT_CONNECTOR = 'MqttConnector',
  SCHEDULE = 'Schedule',
  TASK_CHANGE = 'TaskChange',
  USER_CHANGE = 'UserChange',
  USER_LOGIN = 'UserLogin',
  USER_LOGOUT = 'UserLogout',
}
