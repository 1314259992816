import { IPaginatedQueryParams } from '@workerbase/api/http/common';
import { ListConfigSorting } from '@workerbase/api/http/user';

import {
  LocationStructureItemPOST,
  LocationStructurePOST,
  LocationStructureCanDeleteGET,
} from 'services/types/Location';
import { LocationPartGET, LocationGET, LocationBody } from '@workerbase/types/api/locations';
import { PaginatedResponse } from '@workerbase/types/api/pagination';
import { api } from 'services/networking/api';

const LOCATIONS_ENDPOINT = '/api/v1/locations';

type GetLocations = (
  page: number,
  perPage: number,
  sorting?: ListConfigSorting,
  filtering?: string,
) => Promise<PaginatedResponse<LocationGET[]>>;

export const getLocations: GetLocations = async (page = 1, perPage = 20, sorting, filtering) => {
  const params: IPaginatedQueryParams = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
  };

  const { data } = await api.get<PaginatedResponse<LocationGET[]>>(LOCATIONS_ENDPOINT, { params });

  return data;
};

export const getLocationParts = async (levelId: string, partId?: string): Promise<LocationPartGET[]> => {
  const url = `${LOCATIONS_ENDPOINT}/parts/${levelId}${partId ? `/${partId}` : ''}`;

  const {
    data: { data },
  } = await api.get<{ data: LocationPartGET[] }>(url);

  return data;
};

export const getLocationById = async (locationId: string): Promise<LocationGET> => {
  const {
    data: { data },
  } = await api.get<{ data: LocationGET }>(`${LOCATIONS_ENDPOINT}/${locationId}`);

  return data;
};

export const createLocation = async (location: LocationBody): Promise<LocationGET> => {
  const {
    data: { data },
  } = await api.post<{ data: LocationGET }>(LOCATIONS_ENDPOINT, location);

  return data;
};

export const updateLocationById = async (locationId: string, location: LocationBody): Promise<LocationGET> => {
  const {
    data: { data },
  } = await api.put<{ data: LocationGET }>(`${LOCATIONS_ENDPOINT}/${locationId}`, location);

  return data;
};

export const getCanDeleteLocation = async (): Promise<LocationStructureCanDeleteGET> => {
  const {
    data: { data },
  } = await api.get<{ data: LocationStructureCanDeleteGET }>(`${LOCATIONS_ENDPOINT}/structures/can_delete`);

  return data;
};

export const updateLocationStructure = async (locationStructure: LocationStructureItemPOST[]): Promise<LocationGET> => {
  const payload: LocationStructurePOST = { locationStructure };

  const {
    data: { data },
  } = await api.put<{ data: LocationGET }>(`${LOCATIONS_ENDPOINT}/structures`, payload);

  return data;
};

export const deleteLocationById = async (locationId: string): Promise<boolean> => {
  await api.delete<{ success: boolean }>(`${LOCATIONS_ENDPOINT}/${locationId}`);

  return true;
};
