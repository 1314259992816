import camelCase from 'camelcase';

export const camelCaseConfig = {
  preserveConsecutiveUppercase: true,
  pascalCase: true,
  locale: 'de-DE',
};

export const slugify = (value) => {
  const preparedValue = value
    .replace('\t', ' ')
    .replace(/\u00df/g, 'ss')
    .replace(/\u00e4/g, 'ae')
    .replace(/\u00f6/g, 'oe')
    .replace(/\u00fc/g, 'ue')
    .replace(/\u00c4/g, 'Ae')
    .replace(/\u00d6/g, 'Oe')
    .replace(/\u00dc/g, 'Ue')
    .replace(/[^a-zA-Z0-9 ]/g, '');
  const slug = camelCase(preparedValue, camelCaseConfig);
  return slug;
};

export const generateUniqueSlug = (name: string, existingSlugs: string[] = []): string => {
  let slug = slugify(name);

  // Make sure this generated slug is unique
  while (existingSlugs.indexOf(slug) !== -1) {
    const randomString = Math.random().toString(36).substr(2, 3);
    slug = `${slugify(name)}-${randomString}`;
  }

  return slug;
};
