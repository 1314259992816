import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import type { IBuilderStepInputText, IDocumentBuilderStepInputTextResult } from '@workerbase/domain/document';
import { StepTextFieldPlaceholder } from '../StepTextInputPlaceholder';

interface BuilderStepInputTextProps {
  value: {
    step: IBuilderStepInputText;
  };
  result?: IDocumentBuilderStepInputTextResult;
}

export const BuilderStepInputText: FC<BuilderStepInputTextProps> = ({ value: { step }, result }) => {
  const intl = useIntl();
  return (
    <StepTextFieldPlaceholder hasResult={!!result}>
      {step.maxCharacters
        ? intl.formatMessage({ id: 'document.builder.input-text.placeholder' }, { characters: step.maxCharacters })
        : undefined}

      {result?.value || null}
    </StepTextFieldPlaceholder>
  );
};
