import { IPaginatedQueryParams } from '@workerbase/api/http/common';
import {
  ConnectorGET,
  ConnectorLogGET,
  ConnectorPOST,
  ConnectorPUT,
  GetConnectorsQueryParams,
} from '@workerbase/api/http/connector';
import { ListConfigSorting } from '@workerbase/api/http/user';
import { normalizeConnector, normalizeConnectorLog } from 'services/normalizers/connectors';
import { Connector, ConnectorLog } from 'services/types/Connector';
import { PaginatedResponse } from '@workerbase/types/api/pagination';

import { api } from './api';

export const CONNECTORS_ENDPOINT = '/api/v1/connectors';

type GetConnectorLogs = (
  eventId: string,
  page: number,
  perPage: number,
  sorting?: ListConfigSorting,
) => Promise<PaginatedResponse<ConnectorLog[]>>;

export const getConnectorLogs: GetConnectorLogs = async (eventId, page = 1, perpage = 20, sorting?) => {
  const params: IPaginatedQueryParams = {
    page,
    perpage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
  };

  const {
    data: { data, ...rest },
  } = await api.get<PaginatedResponse<ConnectorLogGET[]>>(`${CONNECTORS_ENDPOINT}/${eventId}/history`, { params });

  return { data: data.map(normalizeConnectorLog), ...rest };
};

type GetConnectors = (options: {
  projectId: string;
  page?: number;
  perPage?: number;
  sorting?: ListConfigSorting;
  filtering?: string;
  selectedTabKey?: string;
  categoryKey?: string;
  aggregateLinks?: boolean;
}) => Promise<PaginatedResponse<Connector[]>>;

export const getConnectors: GetConnectors = async ({
  projectId,
  page = 1,
  perPage = 20,
  sorting,
  filtering,
  selectedTabKey,
  categoryKey,
  aggregateLinks,
}) => {
  const params: GetConnectorsQueryParams = {
    project: projectId,
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
    category: selectedTabKey && categoryKey ? selectedTabKey : undefined,
    categoryKey: selectedTabKey && categoryKey ? categoryKey : undefined,
    aggregateLinks,
  };

  const {
    data: { data, ...rest },
  } = await api.get<PaginatedResponse<ConnectorGET[]>>(CONNECTORS_ENDPOINT, { params });

  return { data: data.map(normalizeConnector), ...rest };
};

export const getConnectorById = async (connectorId: string): Promise<Connector> => {
  const {
    data: { data: connectorData },
  } = await api.get<{ data: ConnectorGET }>(`${CONNECTORS_ENDPOINT}/${connectorId}`);

  return normalizeConnector(connectorData);
};

export const createConnector = async (connector: ConnectorPOST): Promise<Connector> => {
  const {
    data: { data: connectorData },
  } = await api.post<{ data: ConnectorGET }>(CONNECTORS_ENDPOINT, connector);

  return normalizeConnector(connectorData);
};

// TODO: consider returning removed role instead of { success: true }
// The successfulness of the delete operation should be reflected by http code
export const deleteConnectorById = async (connectorId: string): Promise<boolean> => {
  await api.delete<{ success: boolean }>(`${CONNECTORS_ENDPOINT}/${connectorId}`);

  return true;
};

export const updateConnectorById = async (connectorId: string, connector: ConnectorPUT): Promise<Connector> => {
  const {
    data: { data: connectorData },
  } = await api.put<{ data: ConnectorGET }>(`${CONNECTORS_ENDPOINT}/${connectorId}`, connector);

  return normalizeConnector(connectorData);
};
