import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton, Tooltip } from '@mui/material';
import Switch from '@mui/material/Switch';
import React from 'react';
import { useIntl } from 'react-intl';

import generateRandomId from '../../../utils/generateRandomId';
import styles from './SwitchWithLabel.module.scss';

export enum SwitchPosition {
  LEFT,
  RIGHT,
}

interface SwitchWithLabelProps {
  labelTranslationId: string;
  checked?: boolean;
  wide?: boolean;
  switchPosition?: SwitchPosition;
  onChange: (checked: boolean) => void;
  tooltipTranslationId?: string;
  useLabelTranslationIdAsKey?: boolean;
}

const SwitchWithLabel: React.FC<SwitchWithLabelProps> = ({
  labelTranslationId,
  wide,
  onChange,
  checked = false,
  switchPosition = SwitchPosition.RIGHT,
  tooltipTranslationId,
  useLabelTranslationIdAsKey,
}) => {
  const intl = useIntl();
  const triggerSwitch = () => {
    onChange(!checked);
  };
  const componentKey = useLabelTranslationIdAsKey ? labelTranslationId : generateRandomId();
  const elements = [
    <div key={`label-${componentKey}`} className={styles.label} onClick={triggerSwitch}>
      {labelTranslationId && intl.formatMessage({ id: labelTranslationId })}
    </div>,
    <React.Fragment key={`tooltip-${componentKey}`}>
      {tooltipTranslationId && (
        <div className={styles.tooltip}>
          <Tooltip title={intl.formatMessage({ id: tooltipTranslationId })}>
            <IconButton aria-label="tooltip" size="small" color="inherit" edge="start">
              <HelpOutlineIcon fontSize="inherit" color="inherit" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </React.Fragment>,
    <Switch
      key={`switch-${componentKey}`}
      color="primary"
      size="small"
      checked={checked}
      onChange={triggerSwitch}
      sx={switchPosition === SwitchPosition.RIGHT ? { ml: 'auto' } : {}}
    />,
  ];

  return (
    <div className={`${styles.container} ${wide ? styles.wide : ''}`}>
      {switchPosition === SwitchPosition.RIGHT ? elements : elements.reverse()}
    </div>
  );
};

export default SwitchWithLabel;
