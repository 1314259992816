import { ListConfigProperties } from '@workerbase/api/http/user';
import { FunctionType, FunctionWizard } from '@workerbase/types/api/functions';
import { PaginationMeta } from '@workerbase/types/Response';

import { BaseAction } from '../common/actions';
import { updateFilteringFactory, updateSortingFactory, updatePaginationFactory } from '../common/ListConfig/actions';

export enum FunctionsActions {
  GET_FUNCTIONS_REQUEST = '@@functions/GET_FUNCTIONS_REQUEST',
  GET_FUNCTIONS_SUCCESS = '@@functions/GET_FUNCTIONS_SUCCESS',
  GET_FUNCTIONS_FAILURE = '@@functions/GET_FUNCTIONS_FAILURE',
  GET_FUNCTION_BY_ID_REQUEST = '@@functions/GET_FUNCTION_BY_ID_REQUEST',
  GET_FUNCTION_BY_ID_SUCCESS = '@@functions/GET_FUNCTION_BY_ID_SUCCESS',
  GET_FUNCTION_BY_ID_FAILURE = '@@functions/GET_FUNCTION_BY_ID_FAILURE',
  DELETE_FUNCTION_BY_ID_REQUEST = '@@functions/DELETE_FUNCTION_BY_ID_REQUEST',
  DELETE_FUNCTION_BY_ID_SUCCESS = '@@functions/DELETE_FUNCTION_BY_ID_SUCCESS',
  DELETE_FUNCTION_BY_ID_FAILURE = '@@functions/DELETE_FUNCTION_BY_ID_FAILURE',
  CREATE_FUNCTION_REQUEST = '@@functions/CREATE_FUNCTION_REQUEST',
  UPDATE_FUNCTION_BY_ID_REQUEST = '@@functions/UPDATE_FUNCTION_BY_ID_REQUEST',
  UPDATE_PAGINATION = '@@functions/UPDATE_PAGINATION',
  UPDATE_SORTING = '@@functions/UPDATE_SORTING',
  UPDATE_FILTERING = '@@functions/UPDATE_FILTERING',
  UPDATE_LISTCONFIG_PROPERTIES = '@@functions/UPDATE_LISTCONFIG_PROPERTIES',
  UPDATE_LIST_CONFIG = '@@functions/UPDATE_LIST_CONFIG',
  DEPLOY_FUNCTION_REQUEST = '@@functions/DEPLOY_FUNCTION_REQUEST',
  DEPLOY_FUNCTION_SUCCESS = '@@functions/DEPLOY_FUNCTION_SUCCESS',
  DEPLOY_FUNCTION_FAILURE = '@@functions/DEPLOY_FUNCTION_FAILURE',
  SET_IS_DEPLOYING = '@@functions/SET_IS_DEPLOYING',
  INVOKE_FUNCTION_REQUEST = '@@functions/INVOKE_FUNCTION_REQUEST',
  MQTT_SUBSCRIBE_REALTIME_LOGS = '@@functions/MQTT_SUBSCRIBE_REALTIME_LOGS',
  MQTT_UNSUBSCRIBE_REALTIME_LOGS = '@@functions/MQTT_UNSUBSCRIBE_REALTIME_LOGS',
  HANDLE_MQTT_REALTIME_LOGS = '@@functions/HANDLE_MQTT_REALTIME_LOGS',
  MQTT_UPDATE_REALTIME_LOGS_CALLBACK = '@@functions/MQTT_UPDATE_REALTIME_LOGS_CALLBACK',
}

export const getFunctionsRequestAction = (projectId: string): BaseAction => ({
  type: FunctionsActions.GET_FUNCTIONS_REQUEST,
  payload: { projectId },
});

export const getFunctionsSuccess = (data: FunctionType[], meta: PaginationMeta): BaseAction => ({
  type: FunctionsActions.GET_FUNCTIONS_SUCCESS,
  payload: {
    functions: data,
    meta,
  },
});
export const subscribeToRealtimeLogs = (functionId: string, callback) => ({
  type: FunctionsActions.MQTT_SUBSCRIBE_REALTIME_LOGS,
  payload: { functionId, callback },
});

export const updateRealtimeLogsCallback = (functionId: string, callback) => ({
  type: FunctionsActions.MQTT_UPDATE_REALTIME_LOGS_CALLBACK,
  payload: { functionId, callback },
});

export const unsubscribeToRealtimeLogs = (functionId: string, callback) => ({
  type: FunctionsActions.MQTT_UNSUBSCRIBE_REALTIME_LOGS,
  payload: { functionId, callback },
});

export const getFunctionsError = (errorMessage: string): BaseAction => ({
  type: FunctionsActions.GET_FUNCTIONS_FAILURE,
  payload: {
    errorMessage,
  },
});

// GET_FUNCTION_BY_ID
export const getFunctionByIdRequestAction = (functionId: string): BaseAction => ({
  type: FunctionsActions.GET_FUNCTION_BY_ID_REQUEST,
  payload: { functionId },
});

export const getFunctionByIdSuccess = (data: FunctionType): BaseAction => ({
  type: FunctionsActions.GET_FUNCTION_BY_ID_SUCCESS,
  payload: {
    functionData: data,
  },
});

export const getFunctionByIdError = (errorMessage: string): BaseAction => ({
  type: FunctionsActions.GET_FUNCTION_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// CREATE_FUNCTION
export interface CreateFunctionRequestAction {
  type: string;
  payload: {
    functionData: FunctionWizard & { projectId: string };
  };
}
export const createFunctionRequest = (
  functionData: FunctionWizard & { projectId: string },
): CreateFunctionRequestAction => ({
  type: FunctionsActions.CREATE_FUNCTION_REQUEST,
  payload: { functionData },
});

// DELETE_FUNCTION_BY_ID
export interface DeleteFunctionByIdRequestAction {
  type: string;
  payload: {
    functionId: string;
  };
}
export const deleteFunctionByIdRequest = (functionId: string): DeleteFunctionByIdRequestAction => ({
  type: FunctionsActions.DELETE_FUNCTION_BY_ID_REQUEST,
  payload: { functionId },
});

export const deleteFunctionByIdSuccess = (functionId: string): DeleteFunctionByIdRequestAction => ({
  type: FunctionsActions.DELETE_FUNCTION_BY_ID_SUCCESS,
  payload: {
    functionId,
  },
});

export const deleteFunctionByIdError = (errorMessage: string): BaseAction => ({
  type: FunctionsActions.DELETE_FUNCTION_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// UPDATE_FUNCTION_BY_ID
export interface UpdateFunctionByIdRequestAction {
  type: string;
  payload: {
    functionData: FunctionWizard;
    functionId: string;
  };
}
export const updateFunctionByIdRequest = (
  functionId: string,
  functionData: FunctionWizard,
): UpdateFunctionByIdRequestAction => ({
  type: FunctionsActions.UPDATE_FUNCTION_BY_ID_REQUEST,
  payload: { functionData, functionId },
});

// PAGINATION
export const updatePagination = updatePaginationFactory(FunctionsActions.UPDATE_PAGINATION);
export const updateSorting = updateSortingFactory(FunctionsActions.UPDATE_SORTING);
export const updateFiltering = updateFilteringFactory(FunctionsActions.UPDATE_FILTERING);

export const updateListConfigProperties = (newProperties: ListConfigProperties[]) => ({
  type: FunctionsActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties },
});

export const deployFunctionRequest = (functionId: string) => ({
  type: FunctionsActions.DEPLOY_FUNCTION_REQUEST,
  payload: { functionId },
});
export const deployFunctionSuccess = (functionId: string) => ({
  type: FunctionsActions.DEPLOY_FUNCTION_SUCCESS,
  payload: { functionId },
});
export const deployFunctionFailure = (errorMessage: string) => ({
  type: FunctionsActions.DEPLOY_FUNCTION_FAILURE,
  payload: { errorMessage },
});

export const setIsDeploying = (isDeploying: boolean) => ({
  type: FunctionsActions.SET_IS_DEPLOYING,
  payload: { isDeploying },
});

export const invokeFunctionRequest = (functionId: string, payload: string) => ({
  type: FunctionsActions.INVOKE_FUNCTION_REQUEST,
  payload: { functionId, payload },
});
