import { DEFAULT_SKILL_LEVEL } from '@workerbase/types/Skill';
import { SkillResponse } from '@workerbase/api/http/skill';

import { Skill } from '../types/Skill';

export function normalizeSkill(skill: SkillResponse): Skill {
  return {
    id: skill._id,
    name: skill.name,
    description: skill.description,
    level: skill.level || DEFAULT_SKILL_LEVEL,
    lastUsedAt: skill.lastUsedAt,
  };
}
