import { AnyAction } from 'redux';

import { SortOrder } from '@workerbase/domain/common';
import { ListConfigPropertySelectors, TabActiveColorTypes } from '@workerbase/domain/user';
import {
  ListConfigFiltering,
  ListConfigPagination,
  ListConfigSorting,
  ListConfigTabs,
} from '@workerbase/api/http/user';
import { RulesListConfig } from '@workerbase/api/http/rule';

import { Rule } from 'services/types/Rule';
import { PaginationMeta } from '@workerbase/types/Response';

import { RulesActions, ActivateOrDeactivateRuleByIdAction } from './actions';
import { UpdateListConfigActionPayload } from '../common/ListConfig/actions';
import {
  updatePagination,
  updateSorting,
  updateFiltering,
  updateSelectedTabKey,
  updateStateTabs,
  updateListConfig,
} from '../common/ListConfig/reducers';

export type RulesState = Readonly<{
  rulesById: { [key: string]: Rule };
  listConfigs: RulesListConfig;
  currentListItemsIds: string[];
  errorMessage: string | null;
}>;

export const initialState: RulesState = {
  rulesById: {},
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
    filtering: {
      searchTerms: '',
    },
    sorting: { selector: 'name', sortDirection: SortOrder.ASC },
    properties: [
      {
        selector: 'id',
        omit: true,
      },
      {
        selector: 'name',
        omit: false,
      },
      {
        selector: 'createdAt',
        omit: false,
      },
      {
        selector: 'triggeredAt',
        omit: false,
      },
      {
        selector: 'datasource',
        omit: false,
      },
      {
        selector: 'assignment',
        omit: false,
      },
      {
        selector: 'conditions',
        omit: false,
      },
      {
        selector: ListConfigPropertySelectors.LINKS,
        omit: false,
      },
    ],
    tabs: {
      selectedTabKey: 'true',
      categoryKey: 'active',
      categories: ['active'],
      options: [
        {
          key: 'true',
          titleTranslationKey: 'global.active',
          count: 0,
          activeColor: TabActiveColorTypes.DEFAULT,
        },
        {
          key: 'false',
          titleTranslationKey: 'global.inactive',
          count: 0,
          activeColor: TabActiveColorTypes.DEFAULT,
        },
      ],
    },
  },
  currentListItemsIds: [],
  errorMessage: null,
};

interface GetRuleSuccessActionPayload {
  rule: Rule;
}

interface GetRulesSuccessActionPayload {
  rules: Rule[];
  meta: PaginationMeta;
}

interface GetRulesFailureActionPayload {
  errorMessage: string;
}

const reducer = (state: RulesState = initialState, action: AnyAction): RulesState => {
  switch (action.type) {
    case RulesActions.GET_RULES_SUCCESS: {
      const payload = action.payload as GetRulesSuccessActionPayload;

      const rulesById = payload.rules.reduce((prev, rule) => {
        const updatedRules = prev;
        updatedRules[rule.id] = rule;
        return prev;
      }, {});

      return {
        ...state,
        rulesById: {
          ...state.rulesById,
          ...rulesById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
          tabs: updateStateTabs(
            initialState.listConfigs.tabs,
            state.listConfigs.tabs,
            payload.meta.category,
            payload.meta.categoryKey,
            payload.meta.stats,
          ),
        },
        currentListItemsIds: payload.rules.map((rule) => rule.id),
      };
    }
    case RulesActions.DELETE_RULE_BY_ID_FAILURE:
    case RulesActions.GET_RULES_FAILURE:
    case RulesActions.GET_RULE_BY_ID_FAILURE: {
      const payload = action.payload as GetRulesFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    }
    case RulesActions.GET_RULE_BY_ID_SUCCESS: {
      const payload = action.payload as GetRuleSuccessActionPayload;
      const fetchedRule = payload.rule;
      const rule = {
        [fetchedRule.id]: fetchedRule,
      };

      return {
        ...state,
        rulesById: {
          ...state.rulesById,
          ...rule,
        },
      };
    }
    case RulesActions.DELETE_RULE_BY_ID_SUCCESS: {
      const ruleId: string = action.payload.ruleId;

      const rulesById = { ...state.rulesById };
      if (rulesById[ruleId]) {
        delete rulesById[ruleId];
      }

      return {
        ...state,
        rulesById,
        currentListItemsIds: state.currentListItemsIds.filter((itemId) => itemId !== ruleId),
      };
    }
    case RulesActions.ACTIVATE_OR_DEACTIVATE_RULE_BY_ID_SUCCESS: {
      const typedAction = action as ActivateOrDeactivateRuleByIdAction;
      const payload = typedAction.payload;

      const updatedRule: { [key: string]: Rule } = {
        [payload.ruleId]: {
          ...state.rulesById[payload.ruleId],
          active: payload.activate,
        },
      };

      return {
        ...state,
        rulesById: {
          ...state.rulesById,
          ...updatedRule,
        },
      };
    }
    case RulesActions.UPDATE_PAGINATION: {
      const payload = action.payload as ListConfigPagination;
      return updatePagination(state, payload);
    }
    case RulesActions.UPDATE_SORTING: {
      const payload = action.payload as ListConfigSorting;
      return updateSorting(state, payload);
    }
    case RulesActions.UPDATE_FILTERING: {
      const payload = action.payload as ListConfigFiltering;
      return updateFiltering(state, payload);
    }
    case RulesActions.UPDATE_SELECTED_TAB_KEY: {
      const payload = action.payload as ListConfigTabs;
      return updateSelectedTabKey(state, payload);
    }
    case RulesActions.UPDATE_LISTCONFIG_PROPERTIES:
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          properties: action.payload.properties,
        },
      };
    case RulesActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    default:
      return state;
  }
};

export default reducer;
