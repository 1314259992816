import { StepTypes } from '../StepTypes.enum';
import { IStepBase } from './Base';

export const INFO_TASK_STEP_ID = 'task';

// This step is only used on the frontend.
// So it can be used in the Device preview (it complies with other step structure)
export interface IInfoTaskStep extends IStepBase {
  type: StepTypes.INFO_TASK;
  id: typeof INFO_TASK_STEP_ID;
  taskTitle: string;
  description: string;
  priority: string;
}
