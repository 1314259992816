import { ListConfigProperties } from '@workerbase/api/http/user';
import { PaginationMeta } from '@workerbase/types/Response';

import { ProjectLog, ProjectLogView } from 'services/types/ProjectLog';
import { BaseAction } from '../common/actions';
import {
  updateFilteringFactory,
  updateSortingFactory,
  updatePaginationFactory,
  updateSelectedTabKeyFactory,
} from '../common/ListConfig/actions';

export enum ProjectLogsActions {
  GET_PROJECT_LOGS_REQUEST = '@@projectLogs/GET_PROJECT_LOGS_REQUEST',
  GET_PROJECT_LOGS_SUCCESS = '@@projectLogs/GET_PROJECT_LOGS_SUCCESS',
  GET_PROJECT_LOGS_FAILURE = '@@projectLogs/GET_PROJECT_LOGS_FAILURE',
  UPDATE_PAGINATION = '@@projectLogs/UPDATE_PAGINATION',
  UPDATE_SORTING = '@@projectLogs/UPDATE_SORTING',
  UPDATE_FILTERING = '@@projectLogs/UPDATE_FILTERING',
  UPDATE_LISTCONFIG_PROPERTIES = '@@projectLogs/UPDATE_LISTCONFIG_PROPERTIES',
  UPDATE_LIST_CONFIG = '@@projectLogs/UPDATE_LIST_CONFIG',
  UPDATE_CATEGORY_KEY = '@@projectLogs/UPDATE_CATEGORY_KEY',
  UPDATE_SELECTED_TAB_KEY = '@@projectLogs/UPDATE_SELECTED_TAB_KEY',
}

export const getProjectLogsRequestAction = (projectId: string): BaseAction => ({
  type: ProjectLogsActions.GET_PROJECT_LOGS_REQUEST,
  payload: { projectId },
});

export const getProjectLogsSuccess = (logs: ProjectLog[], meta: PaginationMeta): BaseAction => ({
  type: ProjectLogsActions.GET_PROJECT_LOGS_SUCCESS,
  payload: {
    logs,
    meta,
  },
});

export const getProjectLogsError = (errorMessage: string): BaseAction => ({
  type: ProjectLogsActions.GET_PROJECT_LOGS_FAILURE,
  payload: {
    errorMessage,
  },
});

// PAGINATION
export const updatePagination = updatePaginationFactory(ProjectLogsActions.UPDATE_PAGINATION);
export const updateSorting = updateSortingFactory(ProjectLogsActions.UPDATE_SORTING);
export const updateFiltering = updateFilteringFactory(ProjectLogsActions.UPDATE_FILTERING);

export const updateListConfigProperties = (projectId: string, newProperties: ListConfigProperties[]) => ({
  type: ProjectLogsActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties, projectId },
});

export const updateCategoryKey = (category: ProjectLogView) => ({
  type: ProjectLogsActions.UPDATE_CATEGORY_KEY,
  payload: {
    category,
  },
});

export const updateSelectedTabKey = updateSelectedTabKeyFactory(ProjectLogsActions.UPDATE_SELECTED_TAB_KEY);
