import { AnyAction } from 'redux';

import { SortOrder } from '@workerbase/domain/common';
import { ListConfigFiltering, ListConfigPagination, ListConfigSorting } from '@workerbase/api/http/user';
import { FunctionLogsListConfig } from '@workerbase/api/http/function';
import { FunctionLog } from '@workerbase/types/api/functions';
import { PaginationMeta } from '@workerbase/types/Response';

import { FunctionLogsActions } from './actions';
import { UpdateListConfigActionPayload } from '../common/ListConfig/actions';
import { updatePagination, updateSorting, updateListConfig, updateFiltering } from '../common/ListConfig/reducers';

export type FunctionLogsState = Readonly<{
  functionLogsById: { [key: string]: FunctionLog };
  listConfigs: FunctionLogsListConfig;
  currentListItemsIds: string[];
  errorMessage: string | null;
  isLoading: boolean;
}>;

export const initialState: FunctionLogsState = {
  functionLogsById: {},
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
    properties: [],
    sorting: { selector: 'meta.createdAt', sortDirection: SortOrder.DESC },
    filtering: {
      searchTerms: '',
    },
  },
  currentListItemsIds: [],
  errorMessage: null,
  isLoading: false,
};

interface GetFunctionLogsSuccessActionPayload {
  functionLogs: FunctionLog[];
  meta: PaginationMeta;
}

interface GetFunctionLogsFailureActionPayload {
  errorMessage: string;
}

const reducer = (state: FunctionLogsState = initialState, action: AnyAction): FunctionLogsState => {
  switch (action.type) {
    case FunctionLogsActions.GET_FUNCTIONLOGS_REQUEST: {
      if (action.payload.forceLoading) {
        return { ...state, isLoading: true };
      }
      return state;
    }
    case FunctionLogsActions.GET_FUNCTIONLOGS_SUCCESS: {
      const payload = action.payload as GetFunctionLogsSuccessActionPayload;

      const functionLogsById = payload.functionLogs.reduce((prev, functionlog) => {
        const updatedFunctionLogs = prev;
        updatedFunctionLogs[functionlog.id] = functionlog;
        return prev;
      }, {});

      return {
        ...state,
        functionLogsById: {
          ...state.functionLogsById,
          ...functionLogsById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
        },
        isLoading: false,
        errorMessage: null,
        currentListItemsIds: payload.functionLogs.map((functionlog) => functionlog.id),
      };
    }
    case FunctionLogsActions.GET_FUNCTIONLOGS_FAILURE: {
      const payload = action.payload as GetFunctionLogsFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
        isLoading: false,
        currentListItemsIds: [],
      };
    }
    case FunctionLogsActions.UPDATE_PAGINATION: {
      const payload = action.payload as ListConfigPagination;
      return updatePagination(state, payload);
    }
    case FunctionLogsActions.UPDATE_SORTING: {
      const payload = action.payload as ListConfigSorting;
      return updateSorting(state, payload);
    }
    case FunctionLogsActions.UPDATE_FILTERING: {
      const payload = action.payload as ListConfigFiltering;
      return updateFiltering(state, payload);
    }
    case FunctionLogsActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    default:
      return state;
  }
};

export default reducer;
