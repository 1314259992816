import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  useEffect,
  useCallback,
} from 'react';

interface DrawerProviderValues {
  drawerContent: ReactNode;
  setDrawerContent: (content: ReactNode) => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const defaultState: DrawerProviderValues = {
  drawerContent: null,
  setDrawerContent: () => {},
  isOpen: false,
  setIsOpen: () => {},
};

const DrawerContext = createContext<DrawerProviderValues>(defaultState);

export const DrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [drawerContent, setDrawerContent] = useState<ReactNode>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const memoisedValue: DrawerProviderValues = useMemo(
    () => ({
      drawerContent,
      setDrawerContent,
      isOpen,
      setIsOpen,
    }),
    [drawerContent, setDrawerContent, isOpen, setIsOpen],
  );

  return <DrawerContext.Provider value={memoisedValue}>{children}</DrawerContext.Provider>;
};

export const useDrawerContext = (content?: ReactNode) => {
  const drawerContext = useContext(DrawerContext);

  if (!drawerContext) {
    throw new Error('useDrawer must be used within DrawerProvider');
  }

  return drawerContext;
};

interface UseDrawerOptions {
  /** default value is `true` */
  initialIsOpen?: boolean;
  /** default value is `true` */
  clearOnUnmount?: boolean;
  /** Manually update drawer content by using "refreshDrawer" (for complex situations).
   * Also closing on unmount will not be handled automatically.
   * If you want to close the drawer on unmount, you need to handle it manually.
   */
  isControlledMode?: boolean;
}

const defaultOptions: UseDrawerOptions = {
  initialIsOpen: true,
  clearOnUnmount: true,
  isControlledMode: false,
};

/** The hook for setting the content of the drawer and controlling its visibility
 * @param setContent - a function that returns the content of the drawer, make sure to memoize it (useCallback)
 * @param options - optional options for the hook
 * @returns an object with the following properties:
 * - `isOpen` - a boolean value that indicates whether the drawer is open
 * - `setIsOpen` - a function that sets the visibility of the drawer
 * @example const memoizedDrawerContent = useCallback(()=> <MyDrawerContent count={count} />, [count]);
            const { isOpen, setDrawerContent, setIsOpen } = useDrawer(memoizedDrawerContent, { initialIsOpen: false });
 */
export const useDrawer = (setContent: ReactNode, options?: UseDrawerOptions) => {
  const { initialIsOpen, clearOnUnmount } = useMemo(() => ({ ...defaultOptions, ...options }), []);
  const { setDrawerContent, setIsOpen, isOpen } = useDrawerContext();
  const { isControlledMode } = options ?? defaultOptions;

  useEffect(() => {
    if (isControlledMode) {
      return;
    }

    setDrawerContent(setContent);
  }, [setDrawerContent, setContent, isControlledMode]);

  useEffect(() => {
    if (initialIsOpen) {
      setIsOpen(true);
    }
  }, [isControlledMode, initialIsOpen, setDrawerContent, setIsOpen]);

  useEffect(() => {
    if (isControlledMode) {
      return;
    }

    if (clearOnUnmount) {
      return () => {
        setDrawerContent(null);
        setIsOpen(false);
      };
    }
  }, [clearOnUnmount, isControlledMode, setDrawerContent, setIsOpen]);

  /**
   * Refreshes/Resets the drawer content.
   * Useful when the content is updated from multiple components and you want to conditionally show it.
   */
  const refreshDrawer = useCallback(() => {
    setDrawerContent(setContent);
  }, [setContent, setDrawerContent]);

  return {
    isOpen,
    setIsOpen,
    refreshDrawer,
  };
};
