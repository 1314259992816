import React, { FC } from 'react';
import { get } from 'lodash';
import { useFormikContext } from 'formik';
import { getFieldMeta } from 'utils/formik';
import { SelectInput, SelectInputsProps } from './SelectInput';

type FormikRolesSelectProps = Omit<SelectInputsProps, 'field' | 'form' | 'error'> & { name: string };

export const FormikSelectInput: FC<FormikRolesSelectProps> = ({ name, ...props }) => {
  const { setFieldValue, values, errors, touched, setFieldTouched } = useFormikContext();
  const { isTouched, errorText } = getFieldMeta(name, { touched, errors });

  const value = get(values, name);

  return (
    <SelectInput
      {...props}
      field={{
        name,
        value,
        onChange: (val) => {
          setFieldValue(name, val);
        },
        onBlur: () => {
          !isTouched && setFieldTouched(name, true);
        },
      }}
      error={isTouched ? errorText : null}
    />
  );
};
