import React, { FC, useCallback, useState } from 'react';
import { Divider } from '@mui/material';
import { deleteMedia as deleteMediaRequest, uploadMedia, uploadMediaFromGDrive } from 'services/networking/media';
import { formatFileSize } from 'utils/formatFileSize';
import { useMutation } from '@tanstack/react-query';
import { GoogleDrivePicker } from 'components/UploadFiles/GoogleDrivePicker/GoogleDrivePicker';
import { WuiFileDropzone } from '../WuiFileDropzone';

import { FileOrigin, FileUploadStatus, isComputerFile, WuiFileInputFile, WuiFileInputValue } from './types';
import { WuiFileInputItem } from './WuiFileInputItem';

type FileUploadRow =
  | {
      id: string;
      status: FileUploadStatus;
      file: WuiFileInputFile;
      media?: never;
      mediaId?: string;
    }
  | {
      id: string;
      status: FileUploadStatus;
      file?: never;
      media: WuiFileInputValue;
      mediaId: string;
    };

export interface WuiFileInputStatus {
  total: number;
  successful: number;
  isLoading: boolean;
  hasError: boolean;
}

interface WuiFileInputProps {
  initialFiles?: WuiFileInputValue[];
  multiple?: boolean;
  // If true, the media will be deleted from the server
  hardDelete?: boolean;
  accept: string[];
  disabled?: boolean;
  error?: boolean;
  googleDriveUploadEnabled?: boolean;
  onSuccess?: (filesSuccessfullyUploaded: WuiFileInputValue[]) => void;
  onStatusChange?: (status: WuiFileInputStatus) => void;
  /** Sends the mediaIds that were deleted either soft or hard */
  onDelete?: ((id: string) => void) | ((id: string) => Promise<void>);
  /**
   * Called after the media is uploaded successfully.
   * Use it to make an additional request with the mediaId. e.g. create a document with the mediaId.
   * Provides stable UI for multiple requests that are dependent on the mediaId, instead of having multiple loading states across components.
   * `onSuccess` is called AFTER the additional request is successful.
   *
   * @param mediaId The mediaId that was uploaded
   * @returns A string that will be used for the onSuccess and onDelete callbacks instead of the mediaId
   */
  onMediaUploadSuccessAdditionalRequest?: (mediaId: string) => Promise<string>;
}

const getUploadsStatus = (uploadRows: FileUploadRow[]): WuiFileInputStatus =>
  uploadRows.reduce<WuiFileInputStatus>(
    (acc, row) => {
      acc.total += 1;
      if (row.status === FileUploadStatus.Success) {
        acc.successful += 1;
      }
      if (row.status === FileUploadStatus.Loading) {
        acc.isLoading = true;
      }
      if (row.status === FileUploadStatus.Error) {
        acc.hasError = true;
      }
      return acc;
    },
    { total: 0, successful: 0, isLoading: false, hasError: false },
  );

const mapRowsToUploadedFiles = (row: FileUploadRow): WuiFileInputValue => {
  if (row.media) {
    return row.media;
  }
  return { id: row.mediaId || row.id, name: row.file.file.name, size: row.file.file.size, type: row.file.file.type };
};

export const WuiFileInput: FC<WuiFileInputProps> = ({
  initialFiles,
  accept,
  disabled,
  error,
  multiple = false,
  hardDelete = false,
  googleDriveUploadEnabled = false,
  onSuccess,
  onStatusChange,
  onDelete,
  onMediaUploadSuccessAdditionalRequest,
}) => {
  const [uploadRows, setUploadRows] = useState<FileUploadRow[]>(
    initialFiles?.map((media) => ({ id: media.id, status: FileUploadStatus.Success, media, mediaId: media.id })) ?? [],
  );

  const onSuccessFileUpload = useCallback(
    (newRows: FileUploadRow[]) => {
      const successfulUploads = newRows.filter((row) => row.status === FileUploadStatus.Success);

      onSuccess?.(successfulUploads.map(mapRowsToUploadedFiles));
      onStatusChange?.(getUploadsStatus(newRows));
    },
    [onStatusChange, onSuccess],
  );

  const handleFileUpload = async ({ file, rowId }: { file: WuiFileInputFile; rowId: string }): Promise<string> => {
    const existingRowMediaId = uploadRows.find((row) => row.id === rowId)?.mediaId;
    const mediaId =
      existingRowMediaId ||
      (isComputerFile(file)
        ? await uploadMedia(file.file)
        : await uploadMediaFromGDrive(file.file.id, file.accessToken));

    let additionalRequestIdentifier: string | undefined;
    try {
      additionalRequestIdentifier = await onMediaUploadSuccessAdditionalRequest?.(mediaId);
    } catch (e) {
      // As the media was already uploaded, we don't need to upload it again on retry, also we can clean it up later if needed
      setUploadRows((oldRows) => {
        const newRows = [...oldRows];
        const row = newRows.find((row) => row.id === rowId);

        if (!row) {
          console.error('Something went wrong, row with the queried id was not found');
          return newRows;
        }

        row.mediaId = mediaId;

        return newRows;
      });

      throw e;
    }

    // When using subsequent request rely a new string identifier e.g will be used for document creation, deletion etc.
    return additionalRequestIdentifier || mediaId;
  };

  const { mutate: deleteMedia } = useMutation({
    mutationFn: deleteMediaRequest,
    onSuccess(data, variables) {
      onDelete?.(variables);
    },
  });

  const { mutate: uploadFile } = useMutation({
    mutationFn: handleFileUpload,
    onSettled(data, err, variables) {
      setUploadRows((oldRows) => {
        const newRows = [...oldRows];
        const row = newRows.find((r) => r.id === variables.rowId);

        if (!row) {
          console.error('Something went wrong, row with the queried id was not found');
          return newRows;
        }

        if (err) {
          row.status = FileUploadStatus.Error;
        } else {
          row.status = FileUploadStatus.Success;
          row.mediaId = data;

          onSuccessFileUpload(newRows);
        }

        return newRows;
      });
    },
  });

  const handleOnDrop = async (newFiles: WuiFileInputFile[]) => {
    const newFilesWithId = newFiles.map((file) => ({ file, id: `${file.file.name}-${file.file.type}-${Date.now()}` }));

    newFilesWithId.forEach(({ file, id }) => {
      uploadFile({
        file,
        rowId: id,
      });
    });

    setUploadRows((oldRows) => {
      const newRows = [
        ...oldRows,
        ...newFilesWithId.map(({ file, id }) => ({
          id,
          file,
          status: FileUploadStatus.Loading,
        })),
      ];

      onStatusChange?.(getUploadsStatus(newRows));
      return newRows;
    });
  };

  const handleRowDelete = useCallback(
    (index: number) => async () => {
      const row = uploadRows[index];

      if (row.mediaId) {
        await onDelete?.(row.mediaId);
      }

      if (hardDelete && row.mediaId && !onMediaUploadSuccessAdditionalRequest) {
        deleteMedia(row.mediaId);
      }

      setUploadRows((oldRows) => {
        const newRows = [...oldRows];
        newRows.splice(index, 1);

        onStatusChange?.(getUploadsStatus(newRows));
        return newRows;
      });
    },
    [uploadRows, hardDelete, onMediaUploadSuccessAdditionalRequest, onDelete, deleteMedia, onStatusChange],
  );

  const handleRowRetry = useCallback(
    (index: number) => () => {
      const row = uploadRows[index];
      if (row.status !== FileUploadStatus.Error || !row.file) {
        return;
      }
      uploadFile({ file: row.file, rowId: row.id });
    },
    [uploadFile, uploadRows],
  );

  const isPickerShown = multiple || !uploadRows.length;

  return (
    <>
      {isPickerShown && googleDriveUploadEnabled && (
        <>
          <GoogleDrivePicker
            multiple={multiple}
            onFilesSelected={({ files, accessToken }) =>
              handleOnDrop(files.map((file) => ({ file, accessToken, origin: FileOrigin.GOOGLE_DRIVE })))
            }
          />
          <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
        </>
      )}
      {isPickerShown && (
        <WuiFileDropzone
          accept={accept}
          onDrop={(files) => handleOnDrop(files.map((file) => ({ file, origin: FileOrigin.COMPUTER })))}
          multiple={multiple}
          error={error}
        />
      )}

      {uploadRows.map((row, index) => {
        const { name, size } = row.media ?? row.file.file;
        return (
          <WuiFileInputItem
            key={row.id}
            disabled={disabled}
            status={row.status}
            name={name}
            size={formatFileSize(size ?? 0)}
            onRetry={handleRowRetry(index)}
            onDelete={handleRowDelete(index)}
          />
        );
      })}
    </>
  );
};
