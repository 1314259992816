import { Divider, FormHelperText, Stack, Typography } from '@mui/material';
import { WuiFormikTextField } from '@uiKit';
import { InputNumberOperators, type IBuilderStepInputNumber } from '@workerbase/domain/document';
import { validateInputNumber } from '@workerbase/utils/validateInputNumber';
import { FormikToggle } from 'components/Input/Field';
import { Form, Formik, FormikProps } from 'formik';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { FormikObserver } from 'utils/formik';
import * as Yup from 'yup';
import { FormBuilderSettingsTabsWrapper } from 'components/DocumentBuilder/FormBuilderSettingsTabsWrapper';
import { FilterGroupOperator, FilterTypes } from '@workerbase/domain/common';
import { DEFAULT_FLAGGING_CRITERIA, FlaggingCriteriaForm } from './components/FlaggingCriteriaForm';
import { InputNumberSettingsFormValues } from './types';

interface BuilderStepInputNumberSettingsProps {
  value: IBuilderStepInputNumber;
  onChange: (value: IBuilderStepInputNumber) => void;
}

const conditionSchema = Yup.object().shape({
  type: Yup.string().oneOf([FilterTypes.CONDITION]).required(),
  name: Yup.string().required(),
  operator: Yup.string().oneOf(Object.values(InputNumberOperators)).required(),
  value: Yup.string().required('Value is required'),
});

const groupSchema = Yup.object().shape({
  type: Yup.string().oneOf([FilterTypes.GROUP]).required(),
  groupOperator: Yup.string().oneOf(Object.values(FilterGroupOperator)).required(),
  conditions: Yup.array()
    .of(Yup.lazy((value) => (value.type === FilterTypes.GROUP ? groupSchema : conditionSchema)))
    .required()
    .min(1),
});

const validationSchema = Yup.object<InputNumberSettingsFormValues>({
  integerDigits: Yup.number().integer().required().min(1),
  decimalDigits: Yup.number().integer().required().min(0),
  defaultValue: Yup.number(),
  required: Yup.boolean(),
  hasFlaggingCriteria: Yup.boolean(),
  flaggingCriteria: Yup.object().when('hasFlaggingCriteria', {
    is: (val) => val === true,
    then: () => groupSchema,
    otherwise: () => Yup.object().nullable(),
  }),
});

export const BuilderStepInputNumberSettings: FC<BuilderStepInputNumberSettingsProps> = ({ value, onChange }) => {
  const intl = useIntl();

  const handleChange =
    (formik: FormikProps<InputNumberSettingsFormValues>) => (values: InputNumberSettingsFormValues) => {
      onChange({ ...value, ...values, defaultValue: String(values.defaultValue) === '' ? null : values.defaultValue });
    };

  return (
    <Formik<InputNumberSettingsFormValues>
      initialValues={{
        integerDigits: value.integerDigits,
        decimalDigits: value.decimalDigits,
        defaultValue: value.defaultValue,
        required: value.required,
        hasFlaggingCriteria: !!((value?.flaggingCriteria?.conditions || []).length > 0),
        flaggingCriteria: value.flaggingCriteria || null,
      }}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      validateOnChange
    >
      {(formik) => (
        <>
          <FormikObserver<InputNumberSettingsFormValues> onChange={handleChange(formik)} />
          <FormBuilderSettingsTabsWrapper
            header={
              <Typography variant="h6">
                {intl.formatMessage({ id: 'document.builder.input-number.settings' })}
              </Typography>
            }
            flaggingCriteria={
              <>
                <FormikToggle
                  name="hasFlaggingCriteria"
                  label={intl.formatMessage({ id: 'document.set-flagging-criteria' })}
                  labelIsTranslationId={false}
                  onToggleCallback={(hasFlaggingCriteria: boolean) => {
                    formik.setFieldValue('flaggingCriteria', hasFlaggingCriteria ? DEFAULT_FLAGGING_CRITERIA : null);
                  }}
                />
                {formik.values.hasFlaggingCriteria && <FlaggingCriteriaForm name="flaggingCriteria" />}
              </>
            }
            general={
              <Stack spacing={1} component={Form} id="step-input-number-settings">
                <WuiFormikTextField
                  variant="outlined"
                  type="number"
                  name="integerDigits"
                  label="form.number.integer-digits"
                  required
                  fullWidth
                  InputProps={{ inputProps: { min: 1 } }}
                />
                <WuiFormikTextField
                  variant="outlined"
                  type="number"
                  name="decimalDigits"
                  label="form.number.decimal-digits"
                  required
                  fullWidth
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <WuiFormikTextField
                  variant="outlined"
                  type="number"
                  name="defaultValue"
                  label="form.label.default-value"
                  required
                  fullWidth
                />
                {!validateInputNumber(value.defaultValue, value.integerDigits, value.decimalDigits) && (
                  <FormHelperText error>
                    {intl.formatMessage({ id: 'document.builder.input-number.default-value-invalid' })}
                  </FormHelperText>
                )}
                <Divider />
                <FormikToggle name="required" label="form-validation.required" />
              </Stack>
            }
          />
        </>
      )}
    </Formik>
  );
};
