import { isString } from 'lodash';
import { useDispatch } from 'react-redux';

import { IPaginatedQueryParams } from '@workerbase/api/http/common';
import { RoleResponse } from '@workerbase/api/http/role';
import { SkillResponse } from '@workerbase/api/http/skill';
import {
  LicenseUsageGET,
  ListConfig,
  ListConfigSorting,
  UserCreateBody,
  UserGET,
  UserUpdateBody,
} from '@workerbase/api/http/user';
import { ApiResponse, PaginationMeta } from '@workerbase/types/Response';
import { AvailableCondition } from '@workerbase/types/RuleCondition/AvailableCondition';
import { GraphQLOperators } from '@workerbase/types/graphql/GraphQLOperators';
import { GraphQLQueryVariables } from '@workerbase/types/graphql/GraphQLQueryVariables';
import { KeyPaths } from '@workerbase/utils/TS';

import { updateLoggedInUser } from '@redux/Login';
import { GraphQLGroupResponse, GraphQLListResponse, GroupOperation, ListArgs, PageInfo } from 'services/types/GraphQL';
import { User, UsersCategories } from 'services/types/User';

import { Options, useApiRequest } from '../../hooks/useRequest';
import { normalizeUser } from '../normalizers/users';
import { api } from './api';
import { makeGraphqlGroupRequest, makeGraphqlListRequest } from './graphql';

const USERS_ENDPOINT = '/api/v1/users';
const USERS_GRAPHQL_MODEL = 'users';

type UserGraphQlPaths = KeyPaths<Omit<UserGET, 'roles' | 'skills'>, 2> | UserRolesGraphQlPaths | UserSkillsGraphQlPaths;

type UserRolesGraphQlPaths = KeyPaths<{ roles: RoleResponse }, 2>;
type UserSkillsGraphQlPaths = KeyPaths<{ skills: SkillResponse }, 2>;

const defaultPagination: IPaginatedQueryParams = {
  page: 1,
  perpage: 20,
};

const defaultFields: UserGraphQlPaths[] = [
  '_id',
  'firstName',
  'lastName',
  'active',
  'language',
  'department',
  'email',
  'phoneNumber',
  'roles._id',
  'roles.description',
  'roles.name',
  'roles.apps',
  'roles.deviceApps',
  'skills._id',
  'skills.name',
  'shiftPlan',
  'superUser',
  'watchOnly',
  'disableDeviceLock',
  'isDeveloper',
  'location.name',
  'pinCredentials.qrCodeIdentifier',
  'usageType',
];

type GetUsers = (args: {
  page?: number;
  perPage?: number;
  sorting?: ListConfigSorting;
  textSearch?: string;
  category?: UsersCategories;
  usersIds?: string[];
  fields?: UserGraphQlPaths[];
  preciseCount?: boolean;
  filter?: GraphQLQueryVariables;
}) => Promise<GetUsersResponse>;
export type UsersMeta = PageInfo & Pick<PaginationMeta, 'category' | 'stats'>;
export interface GetUsersResponse {
  data: User[];
  meta: UsersMeta;
}
export { UsersCategories };
export type GetUsersParams = Parameters<GetUsers>;
export const getUsers: GetUsers = async (params) => {
  const {
    page = 1,
    perPage = defaultPagination.perpage,
    sorting,
    textSearch,
    fields = defaultFields,
    usersIds,
    category,
    preciseCount = true,
    filter: filterFromParams,
  } = params;
  if (isString(category) && !Object.values(UsersCategories).includes(category)) {
    throw new Error("Category is invalid or doesn't exist");
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- legacy code to be refactored
  const filter: GraphQLQueryVariables = {
    [GraphQLOperators.AND]: [
      {
        deleted: { EQ: false },
      },
    ],
  };
  if (category) {
    filter[GraphQLOperators.AND].push({
      active: { EQ: category === UsersCategories.ACTIVE },
    });
  }

  if (filterFromParams) {
    filter[GraphQLOperators.AND].push(filterFromParams);
  }

  if (usersIds) {
    filter[GraphQLOperators.AND].push({
      _id: { IN: [...new Set(usersIds)] },
    });
  }

  const gqlArgs: ListArgs = {
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch,
    filter,
  };

  const {
    data: { data, errors },
  } = await makeGraphqlListRequest<{ users?: GraphQLListResponse<UserGET> }>(
    USERS_GRAPHQL_MODEL,
    fields as string[],
    gqlArgs,
    preciseCount,
  );

  if (errors) {
    throw new Error(errors.map(({ message }) => message).join('; '));
  }

  if (!data.users?.edges || !data.users?.pageInfo) {
    throw new Error('Oops, fetching user list failed. Try again or contact administrator.'); // "edges" or "pageInfo" missing
  }

  const statsResponse = await getUserStats();
  const stats = statsResponse.map(({ active, count }) => ({
    count,
    category: active === 'true' ? UsersCategories.ACTIVE : UsersCategories.INACTIVE,
  }));

  const users = data.users.edges;
  const meta = {
    ...data.users.pageInfo,
    category,
    stats,
  };
  const normalizedUsers = users.map((user: UserGET) => normalizeUser(user));

  return {
    data: normalizedUsers,
    meta,
  };
};

export const useGetUsersRequest = (options?: Options<GetUsersResponse, GetUsersParams>) => {
  const dispatch = useDispatch();

  return useApiRequest(getUsers, {
    ...options,
    onSuccess: (data, params) => {
      data.data.forEach((user) => dispatch(updateLoggedInUser(user))); // try to update loggedInUser if it is present
      options?.onSuccess?.(data, params);
    },
  });
};

const getUserStats = async (): Promise<{ active: string; count: number }[]> => {
  const args: ListArgs = {
    group: { key: 'active', operation: GroupOperation.COUNT },
    filter: { deleted: { EQ: false } },
  };

  const {
    data: {
      data: { users },
      errors,
    },
  } = await makeGraphqlGroupRequest<{ users?: GraphQLGroupResponse }>(USERS_GRAPHQL_MODEL, args);

  if (errors) {
    throw new Error(errors.map(({ message }) => message).join('; '));
  }

  if (!users?.groupedEdges) {
    throw new Error('Unexpected error: "groupedEdges" missing.');
  }

  return users.groupedEdges.map(({ _id, value }) => ({
    active: String(_id),
    count: value,
  }));
};

type GetUser = (userId: string) => Promise<GetUserResponse>;
export type GetUserResponse = User;
export type GetUserParams = Parameters<GetUser>;
export const getUser: GetUser = async (userId) => {
  const {
    data: { data },
  } = await api.get<{ data: UserGET }>(`${USERS_ENDPOINT}/${userId}`);

  return normalizeUser(data);
};

export const useGetUserRequest = (options?: Options<GetUserResponse, GetUserParams>) => {
  const dispatch = useDispatch();

  return useApiRequest(getUser, {
    ...options,
    onSuccess: (data, params) => {
      dispatch(updateLoggedInUser(data));
      options?.onSuccess?.(data, params);
    },
  });
};

export const getProfilePictureByUserId = async (userId: string): Promise<Blob> => {
  const { data } = await api.get<Blob>(`${USERS_ENDPOINT}/${userId}/profile`, { responseType: 'blob' });

  return data;
};

type ActivateUser = (userId: string) => Promise<ActivateUserResponse>;
export type ActivateUserResponse = void;
export type ActivateUserParams = Parameters<ActivateUser>;
export const activateUser: ActivateUser = async (userId) => {
  await api.post<unknown>(`${USERS_ENDPOINT}/${userId}/activate`);
};

type DeactivateUser = (userId: string) => Promise<DeactivateUserResponse>;
export type DeactivateUserResponse = void;
export type DeactivateUserParams = Parameters<DeactivateUser>;
export const deactivateUser: DeactivateUser = async (userId) => {
  await api.post<unknown>(`${USERS_ENDPOINT}/${userId}/deactivate`);
};

type DeleteUser = (userId: string) => Promise<DeleteUserResponse>;
export type DeleteUserResponse = boolean;
export type DeleteUserParams = Parameters<DeleteUser>;
export const deleteUser: DeleteUser = async (userId) => {
  await api.delete<unknown>(`${USERS_ENDPOINT}/${userId}`);

  return true;
};

type CreateUser = (user: UserCreateBody) => Promise<CreateUserResponse>;
export type CreateUserResponse = User;
export type CreateUserParams = Parameters<CreateUser>;
export const createUser: CreateUser = async (user) => {
  const {
    data: { data },
  } = await api.post<{ data: UserGET }>(USERS_ENDPOINT, user);

  return normalizeUser(data);
};

export const sendMessageToUserId = async (userId: string, message: string): Promise<boolean> => {
  await api.post<unknown>(`${USERS_ENDPOINT}/${userId}/message`, { text: message });

  return true;
};

type UpdateUser = (userId: string, partialUser: UserUpdateBody) => Promise<UpdateUserResponse>;
export type UpdateUserResponse = User;
export type UpdateUserParams = Parameters<UpdateUser>;
export const updateUser: UpdateUser = async (userId, partialUser) => {
  const {
    data: { data: user },
  } = await api.put<{ data: UserGET }>(`${USERS_ENDPOINT}/${userId}`, partialUser);

  return normalizeUser(user);
};

export const getListConfig = async (userId: string): Promise<ListConfig[]> => {
  const {
    data: { data },
  } = await api.get<{ data: ListConfig[] }>(`${USERS_ENDPOINT}/${userId}/list-config`);
  return data;
};

export const syncListConfig = async (userId: string, listConfigData: ListConfig): Promise<boolean> => {
  await api.post<unknown>(`${USERS_ENDPOINT}/${userId}/list-config`, listConfigData);
  return true;
};

export const getUsersLicenseUsage = async (): Promise<LicenseUsageGET> => {
  const {
    data: { data },
  } = await api.get<{ data: LicenseUsageGET }>(`${USERS_ENDPOINT}/license-usage/`);

  return data;
};

export const getTestRunDataByFunction = async (userId: string, functionId): Promise<{ testRunData: string }> => {
  const {
    data: { data },
  } = await api.get<{ data: { testRunData: string } }>(`${USERS_ENDPOINT}/${userId}/test-run-data/${functionId}`);

  return data;
};

export const updateTestRunDataByFunction = async (
  userId: string,
  functionId: string,
  testRunData: string,
): Promise<boolean> => {
  await api.post<unknown>(`${USERS_ENDPOINT}/${userId}/test-run-data/${functionId}`, { testRunData });

  return true;
};

type GetUsersFilterConditions = () => Promise<GetUsersFilterConditionsResponse>;
type GetUsersFilterConditionsResponse = AvailableCondition[];

export const getUsersFilterConditions: GetUsersFilterConditions = async () => {
  const {
    data: { data: conditions },
  } = await api.get<ApiResponse<GetUsersFilterConditionsResponse>>(`${USERS_ENDPOINT}/conditions`);

  return conditions;
};
