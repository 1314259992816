import { MetaInterface } from '../common';
import { DatabaseTypes } from './DatabaseTypes.enum';
import { IDatabaseColumn } from './DatabaseColumn.interface';

export enum DatabaseExternalIdColumnTypes {
  DOUBLE = 'Double',
  STRING = 'String',
  INTEGER = 'Integer',
}

export const COLUMN_ARRAY_TYPE_SUFFIX = 'Array';

export interface IDatabaseHistoryConfig {
  enabled: boolean;
  expiresInSec: number;
}

export interface IDatabase {
  _id?: string;
  slug?: string;
  type: DatabaseTypes;
  name: string;
  history: IDatabaseHistoryConfig;
  externalIdType: DatabaseExternalIdColumnTypes;
  readOnlyRoles: string[];
  readWriteRoles: string[];
  columns: IDatabaseColumn[];
  meta: MetaInterface;
}
