import { useFormikContext } from 'formik';
import { useEffect } from 'react';

type FormikObserverProps<Values> = {
  onChange?: (v: Values) => void;
  fields?: (keyof Values)[];
};

export const FormikObserver = <Values,>({ onChange, fields }: FormikObserverProps<Values>) => {
  const { values } = useFormikContext<Values>();

  useEffect(() => onChange?.(values), [values]);

  return null;
};
