import React, { FC } from 'react';
import { isUndefined } from 'lodash';
import { Field, FastField, FieldProps, FieldInputProps, useFormikContext } from 'formik';
import { getFieldMeta } from 'utils/formik';
import { TextInputProps, TextInput } from './TextInput';

type TextInputMiddlewareProps = Omit<TextInputProps, 'field' | 'error'> & {
  outgoingValueMapper?: (value: string | number) => string | number | undefined;
  incomingValueMapper?: (value: string | number) => string | number | undefined;
} & FieldProps;
const TextInputMiddleware: FC<TextInputMiddlewareProps> = ({
  field,
  form,
  outgoingValueMapper,
  incomingValueMapper,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const { isTouched, errorText } = getFieldMeta(field.name, form);
  const { type } = props;
  const modifiedField: Partial<FieldInputProps<unknown>> = {};

  /* due to TextInput component specific behaviour when incoming value is of number type, we need to map it
   into string, and then back into number for some cases */
  if (type === 'number' && (!isUndefined(props.min) || !isUndefined(props.max))) {
    if (incomingValueMapper) {
      modifiedField.value = incomingValueMapper(field.value);
    } else {
      modifiedField.value = field.value ? field.value.toString() : undefined;
    }
    modifiedField.onChange = (e) => {
      setFieldValue(field.name, outgoingValueMapper ? outgoingValueMapper(e.target.value) : Number(e.target.value));
    };
  } else {
    modifiedField.onChange = (e) => {
      setFieldValue(field.name, outgoingValueMapper ? outgoingValueMapper(e.target.value) : e.target.value);
    };
  }

  return <TextInput {...props} field={{ ...field, ...modifiedField }} error={isTouched ? errorText : null} />;
};

type FormikTextInputProps = Omit<TextInputProps, 'field' | 'error'> & {
  name: string;
  isFast?: boolean;
  outgoingValueMapper?: TextInputMiddlewareProps['outgoingValueMapper'];
  incomingValueMapper?: TextInputMiddlewareProps['incomingValueMapper'];
};
export const FormikTextInput: FC<FormikTextInputProps> = ({ name, isFast, ...props }) => {
  // Formik optimization feature. See FastField
  if (isFast) {
    return <FastField component={TextInputMiddleware} name={name} {...props} />;
  }

  return <Field component={TextInputMiddleware} name={name} {...props} />;
};
