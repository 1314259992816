import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';

import { LoggedUser } from 'services/types/User';

import HeaderContainer from 'components/HeaderContainer';
import HeaderNavigation from 'components/HeaderNavigation';
import MainContainer from 'components/MainContainer';

interface Props {
  user: LoggedUser | null;
  children: ReactNode;
}
interface State {
  hasError: boolean;
  error?: string;
  eventId?: string;
  errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: undefined,
      error: undefined,
      errorInfo: undefined,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, errorInfo, error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
      this.setState({ hasError: true, errorInfo });
    });
  }

  render() {
    const { hasError, eventId, errorInfo, error } = this.state;
    const { user, children } = this.props;

    const userDetails = { email: '', name: '' };
    if (user) {
      userDetails.email = user.email;
      userDetails.name = `${user.firstName} ${user.lastName}`;
    }

    if (hasError) {
      Sentry.showReportDialog({
        eventId,
        user: userDetails,
      });

      return (
        <React.Fragment>
          <HeaderContainer>
            <HeaderNavigation />
          </HeaderContainer>
          <MainContainer sx={{ padding: '28px 40px' }}>
            <h2>Something went wrong.</h2>
            <p style={{ marginBottom: '20px' }}>
              We are sorry, the app encountered an unexpected exception.
              <br />A bug report has been sent to the development team.
              <br />
              Sentry ID: {eventId}
            </p>
            <p style={{ marginBottom: '20px' }}>
              <details style={{ whiteSpace: 'pre-wrap' }}>
                {error && error.toString()}
                <br />
                {errorInfo?.componentStack}
              </details>
            </p>
          </MainContainer>
        </React.Fragment>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
