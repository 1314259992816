import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { IFilterGroup } from '@workerbase/domain/common';
import { InputNumberOperators } from '@workerbase/domain/document';
import { useFormikContext } from 'formik';
import { clone, get } from 'lodash';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { InputNumberSettingsFormValues } from '../../types';
import { FlaggingCriteriaGroup } from './FlaggingCriteriaGroup';
import { DEFAULT_FLAGGING_CRITERIA, DEFAULT_FLAGGING_CRITERIA_GROUP } from './constants';

interface FlaggingCriteriaFormProps {
  name: string;
}

export const FlaggingCriteriaForm: FC<FlaggingCriteriaFormProps> = ({ name }) => {
  const intl = useIntl();
  const formik = useFormikContext<InputNumberSettingsFormValues>();

  const criteriaGroups: IFilterGroup<InputNumberOperators>[] = get(formik.values, name)?.conditions || [];

  const handleAddGroup = () => {
    if (!formik.values.flaggingCriteria) {
      formik.setFieldValue(`${name}`, DEFAULT_FLAGGING_CRITERIA);
    } else {
      formik.setFieldValue(`${name}.conditions`, [...criteriaGroups, DEFAULT_FLAGGING_CRITERIA_GROUP]);
    }
  };

  const handleDeleteGroup = (index: number) => {
    const groups = clone(criteriaGroups);
    groups.splice(index, 1);

    if (groups.length) {
      formik.setFieldValue(`${name}.conditions`, groups);
    } else {
      formik.setValues({
        ...formik.values,
        [name]: null,
        hasFlaggingCriteria: false,
      });
    }
  };

  return (
    <>
      {criteriaGroups.map((group, index) => (
        <>
          <Typography
            sx={{
              fontFamily: 'Proxima Nova',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '12px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              mb: '12px',
            }}
            color="##00000099"
          >
            {index === 0
              ? intl.formatMessage({ id: 'document.builder.steps.input-step-number.flagging-criteria.value-if' })
              : intl.formatMessage({ id: 'document.builder.steps.input-step-number.flagging-criteria.or-if' })}
          </Typography>
          <FlaggingCriteriaGroup name={`${name}.conditions.${index}`} onDelete={() => handleDeleteGroup(index)} />
        </>
      ))}
      <Button variant="contained" color="primary" size="small" onClick={handleAddGroup}>
        <Add />
        {intl.formatMessage({ id: 'document.add-flagged-value' })}
      </Button>
    </>
  );
};
