import React, { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import type { IBuilderStepInputText } from '@workerbase/domain/document';
import { WuiFormikTextField } from '@uiKit';
import { Formik, Form, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { FormikToggle } from 'components/Input/Field';
import { FormikObserver } from 'utils/formik';
import { FormBuilderSettingsTabsWrapper } from 'components/DocumentBuilder/FormBuilderSettingsTabsWrapper';

interface BuilderStepInputTextSettingsProps {
  value: IBuilderStepInputText;
  onChange: (value: IBuilderStepInputText) => void;
}

const validationSchema = Yup.object({
  maxCharacters: Yup.number()
    .when('openMaxCharacters', {
      is: true,
      then: (schema) => schema.required('form-validation.number'),
    })
    .min(1),
  openMaxCharacters: Yup.boolean(),
  required: Yup.boolean(),
});

interface InputTextConfigFormValues {
  maxCharacters: number | null;
  openMaxCharacters: boolean;
  required: boolean;
}

export const BuilderStepInputTextSettings: FC<BuilderStepInputTextSettingsProps> = ({ value, onChange }) => {
  const intl = useIntl();

  const handleChange = (formik: FormikProps<InputTextConfigFormValues>) => (values: InputTextConfigFormValues) => {
    if (!values.openMaxCharacters && values.maxCharacters !== null) {
      // TODO: Formik doesn't like null for input values and drops an error, find another way to handle maxCharacter error
      formik.setFieldValue('maxCharacters', null);
    }
    if (values.openMaxCharacters && values.maxCharacters === null) {
      formik.setFieldValue('maxCharacters', 30);
    }
    onChange({ ...value, maxCharacters: values.maxCharacters, required: values.required });
  };

  return (
    <Formik<InputTextConfigFormValues>
      initialValues={{
        maxCharacters: value.maxCharacters,
        openMaxCharacters: !!value.maxCharacters,
        required: value.required,
      }}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      validateOnChange
    >
      {(formik) => (
        <FormBuilderSettingsTabsWrapper
          header={
            <Typography variant="h6">{intl.formatMessage({ id: 'document.builder.input-text.settings' })}</Typography>
          }
          general={
            <Stack spacing={1} component={Form} id="note-form">
              <FormikObserver<InputTextConfigFormValues> onChange={handleChange(formik)} />
              <FormikToggle name="openMaxCharacters" label="form.string.max-characters" />
              {formik.values.openMaxCharacters && (
                <WuiFormikTextField
                  required
                  variant="outlined"
                  type="number"
                  name="maxCharacters"
                  label="form.string.maximum-characters"
                  fullWidth
                  InputProps={{ inputProps: { min: 1 } }}
                />
              )}
              <Divider />
              <FormikToggle name="required" label="form-validation.required" />
            </Stack>
          }
        />
      )}
    </Formik>
  );
};
