import Field from 'components/Input/Field';
import { isEqual } from 'lodash';
import React, { FC, FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Select, { Props as ReactSelectProps } from 'react-select';
import { SelectableVariable } from '@workerbase/types/Variable';
import { VariablesMentionSuggestion } from 'components/Input/VariablesMentionInput';
import { getStyles, theme } from './SelectInput.styles';
import { disableGroupOptionsBy, enableAllGroupOptions } from './SelectInputUtils';
import { GroupOption } from './types/GroupOption';
import { Option } from './types/Option';

const VARIABLES_GROUP_TYPE = 'variables';

interface FieldProps {
  label?: string;
  labelIsTranslationId?: boolean;
  tooltipTranslationId?: string;
  required?: boolean;
  error?: string | null;
  isEnabled?: boolean;
  onToggle?: (isEnabled: boolean) => void;
}

interface SelectProps
  extends Pick<
    ReactSelectProps<Option, boolean, GroupOption>,
    | 'placeholder'
    | 'isMulti'
    | 'isSearchable'
    | 'onInputChange'
    | 'filterOption'
    | 'isDisabled'
    | 'isClearable'
    | 'isLoading'
  > {
  // @override - use only groups but not options
  options: GroupOption[];
}

type SingleValue = string;
type MultiValue = string[];

type Value = SingleValue | MultiValue;

interface GroupSelectedValue<V extends Value> {
  group: string;
  isVar: boolean;
  values: V;
}

interface IGroupSelectInput<V extends SingleValue | MultiValue> extends FieldProps, SelectProps {
  field: {
    onChange: (value: GroupSelectedValue<V> | null) => void;
    name?: string;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    value?: V | null;
  };
  variables?: SelectableVariable[];
}

type GroupSelectInputSingleProps = IGroupSelectInput<SingleValue> & { isMulti?: false };
type GroupSelectInputMultiProps = IGroupSelectInput<MultiValue> & { isMulti: true };

type GroupSelectInputProps = GroupSelectInputSingleProps | GroupSelectInputMultiProps;

/**
 * Dropdown that allows to show options grouped by category (type)
 * Supports single and multi choice
 *
 * @important Support selection within ONLY one group.
 * When user select value from on group, other groups become disabled
 */
export const GroupSelectInput: FC<GroupSelectInputProps> = ({
  error,
  label,
  field,
  options: groupOptions = [],
  isMulti,
  isSearchable,
  required = false,
  isDisabled = false,
  labelIsTranslationId = true,
  isEnabled,
  isLoading = false,
  isClearable,
  filterOption,
  placeholder,
  tooltipTranslationId,
  onToggle,
  variables,
  onInputChange = () => {},
}) => {
  const intl = useIntl();
  const [options, setOptions] = useState<GroupOption[]>(() => enableAllGroupOptions(groupOptions));

  useEffect(() => {
    let variableOptionsGroup: GroupOption | undefined;
    if (variables?.length) {
      variableOptionsGroup = {
        label: intl.formatMessage({ id: 'global.variables' }),
        type: VARIABLES_GROUP_TYPE,
        options: variables.map((variable) => ({
          value: `$${variable.name}`,
          label: (
            <div style={{ display: 'flex' }}>
              <VariablesMentionSuggestion
                name={variable.name as string}
                datatype={variable.datatype}
                lastValue={variable.lastValue}
              />
            </div>
          ),
          isDisabled: false,
        })),
      } as GroupOption;
    }
    setOptions([...groupOptions, ...(variableOptionsGroup ? [variableOptionsGroup] : [])]);
  }, [groupOptions, variables]);

  const mapValuesToOptions = (options: GroupOption[], values?: string | string[] | null): Option[] => {
    if (!values) {
      return [];
    }

    const result: Option[] = [];
    const valueAsArray = Array.isArray(values) ? values : [values];

    options.forEach((group) => {
      const selectedOptions = group.options.filter((option) => valueAsArray.includes(option.value));
      result.push(...selectedOptions);
    });

    return result;
  };

  /**
   * Allows to select options from one group only
   * Disables all group options that differ from the first selected value
   */
  const disableOptionGroups = useCallback(
    (value: GroupSelectInputProps['field']['value']) => {
      let newGroupOptions: GroupOption[] = options;

      if (value === null || typeof value === 'undefined' || value === '') {
        newGroupOptions = enableAllGroupOptions(options);
      } else {
        const selectedOptions = mapValuesToOptions(options, value);
        const selectedGroup = options.find((group) => group.options.includes(selectedOptions[0]));

        newGroupOptions = disableGroupOptionsBy(options, (group) => group.type !== selectedGroup?.type);
      }

      if (!isEqual(options, newGroupOptions)) {
        setOptions(newGroupOptions);
      }
    },
    [options],
  );

  useEffect(() => {
    if (isMulti) {
      disableOptionGroups(field.value);
    }
  }, [field.value]);

  const onChange: ReactSelectProps<Option, boolean, GroupOption>['onChange'] = useCallback(
    (option, action) => {
      if (option === null || action.action === 'clear' || (isMulti && Array.isArray(option) && !option.length)) {
        field.onChange(null);
        return;
      }

      const getGroupTypeFromSelectedOption = (option: Option) =>
        options.find((group) => group.options.includes(option as Option))?.type;

      const groupType = isMulti
        ? getGroupTypeFromSelectedOption(option[0])
        : getGroupTypeFromSelectedOption(option as Option);

      if (!groupType) {
        return;
      }

      const isVar = groupType === VARIABLES_GROUP_TYPE;

      if (isMulti) {
        const values = (option as Option[]).map((opt) => opt.value);
        field.onChange({ group: groupType, isVar, values });
      } else {
        field.onChange({ group: groupType, isVar, values: (option as Option).value });
      }
    },
    [field, isMulti, options],
  );

  const selectedOptions = useMemo(() => mapValuesToOptions(options, field.value), [field.value, options]);

  const selectProps: ReactSelectProps<Option, boolean, GroupOption> = {
    name: field.name,
    placeholder,
    value: selectedOptions,
    defaultValue: selectedOptions,
    options,
    filterOption,
    onChange,
    onInputChange,
    onBlur: (e: FocusEvent<HTMLInputElement>) => {
      if (field.onBlur && field.name) {
        e.target.name = field.name;
        field.onBlur(e);
      }
    },
    classNamePrefix: 'baseSelect',
    styles: getStyles(false),
    theme,
    isLoading,
    isDisabled,
    isClearable,
    isMulti,
    isSearchable,
  };

  // eslint-disable-next-line react/jsx-props-no-spreading -- allow spread operator here
  const getSelectComponent = () => <Select {...selectProps} />;

  if (label || error) {
    return (
      <Field
        isEnabled={isEnabled}
        onToggle={onToggle}
        error={error}
        label={label}
        fieldName={field.name}
        tooltipTranslationId={tooltipTranslationId}
        required={required}
        labelIsTranslationId={labelIsTranslationId}
      >
        {getSelectComponent()}
      </Field>
    );
  }

  return getSelectComponent();
};
