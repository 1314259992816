import { ListConfigProperties } from '@workerbase/api/http/user';
import { DatabasesListConfig } from '@workerbase/api/http/database';
import { updateSortingFactory } from '../common/ListConfig/actions';

export enum DatabasesActions {
  UPDATE_SORTING = '@@databases/UPDATE_SORTING',
  UPDATE_LIST_CONFIG = '@@databases/UPDATE_LIST_CONFIG',
}

export interface DatabasesStateListConfig extends DatabasesListConfig {
  propertiesByDatabaseId: {
    [key: string]: ListConfigProperties[];
  };
}

export const updateSorting = updateSortingFactory(DatabasesActions.UPDATE_SORTING);
