import React, { createContext, useContext, useMemo, useState } from 'react';
import { IConnectedTo, ResourceTypes } from '@workerbase/domain/common';

export interface NotesContextProps {
  resource?: IConnectedTo;
  setResource: React.Dispatch<React.SetStateAction<IConnectedTo<ResourceTypes> | undefined>>;
}

export const NotesContext = createContext<NotesContextProps | undefined>(undefined);

export const NotesProvider = ({ children }) => {
  const [resource, setResource] = useState<IConnectedTo>();

  const notesContext = useMemo(
    () => ({
      resource,
      setResource,
    }),
    [resource, setResource],
  );

  return <NotesContext.Provider value={notesContext}>{children}</NotesContext.Provider>;
};

export const useNotesContext = (): NotesContextProps => {
  const context = useContext(NotesContext);

  if (!context) {
    throw new Error('useNotes must be used within NotesProvider');
  }
  return context;
};
