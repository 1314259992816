export enum UserListConfigTypes {
  CONNECTORS = 'connectors',
  CONNECTOR_LOGS = 'connectorLogs',
  MQTT_CONNECTOR_LOGS = 'mqttConnectorLogs',
  DATABASES = 'databases',
  DATABASE_ITEMS = 'databaseItems',
  DEVICES = 'devices',
  FUNCTIONS = 'functions',
  FUNCTION_LOGS = 'functionLogs',
  LOCATIONS = 'locations',
  PROJECTS = 'projects',
  TASKS = 'tasks',
  ROLES = 'roles',
  RULES = 'rules',
  USERS = 'users',
  WORKINSTRUCTION = 'workinstructions',
  SKILLS = 'skills',
  PROJECT_LOGS = 'projectLogs',
}
