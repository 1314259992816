import { VariableDataTypes } from '../../../../variable/VariableDataTypes.enum';
import { StepTypes } from '../../StepTypes.enum';
import { InputableSteps } from '../../guards/Inputable';

// TODO: consider moving to variables domain
export const InputPayloadTypeMapping: Record<InputableSteps, VariableDataTypes> = {
  [StepTypes.INPUT_AUDIO]: VariableDataTypes.ARRAY,
  [StepTypes.INPUT_BARCODE]: VariableDataTypes.STRING,
  [StepTypes.INPUT_CHECKBOX_LIST]: VariableDataTypes.ARRAY,
  [StepTypes.INPUT_INDEX_LIST]: VariableDataTypes.OBJECT,
  [StepTypes.INPUT_TILE_LIST]: VariableDataTypes.OBJECT,
  [StepTypes.INPUT_NUMBER]: VariableDataTypes.STRING,
  [StepTypes.INPUT_NUMBER_PICKER]: VariableDataTypes.STRING,
  [StepTypes.INPUT_PHOTO]: VariableDataTypes.ARRAY,
  [StepTypes.INPUT_STEP_MENU]: VariableDataTypes.OBJECT,
  [StepTypes.INPUT_TEXT]: VariableDataTypes.STRING,
  [StepTypes.INPUT_LOCATION]: VariableDataTypes.OBJECT,
  [StepTypes.INPUT_NOISE_LEVEL]: VariableDataTypes.NUMBER,
  [StepTypes.INPUT_VALUE_LIST]: VariableDataTypes.OBJECT,
  [StepTypes.INPUT_VIDEO]: VariableDataTypes.ARRAY,
  [StepTypes.INPUT_BUTTON_LIST]: VariableDataTypes.OBJECT,
};
