/* eslint-disable complexity */
import { AnyAction } from 'redux';

import { SortOrder } from '@workerbase/domain/common';
import { ListConfigSorting, UsersListConfig } from '@workerbase/api/http/user';

import { User } from 'services/types/User';
import { UsersActions } from './actions';
import { UpdateListConfigActionPayload } from '../common/ListConfig/actions';
import { updateListConfig, updateSorting } from '../common/ListConfig/reducers';

export type UsersState = Readonly<{
  usersById: { [key: string]: User };
  listConfigs: UsersListConfig;
  testRunDataByFunctionId: Record<string, string>;
  errorMessage: string | null;
}>;

export const initialState: UsersState = {
  usersById: {},
  listConfigs: {
    properties: [
      {
        selector: 'id',
        omit: true,
      },
      {
        selector: 'firstName',
        omit: false,
      },
      {
        selector: 'lastName',
        omit: false,
      },
      {
        selector: 'department',
        omit: false,
      },
      {
        selector: 'rolesIds',
        omit: true,
      },
      {
        selector: 'skillsIds',
        omit: true,
      },
      {
        selector: 'locationName',
        omit: false,
      },
    ],
    sorting: { selector: 'lastName', sortDirection: SortOrder.ASC },
  },
  testRunDataByFunctionId: {},
  errorMessage: null,
};

interface GetUserSuccessActionPayload {
  user: User;
}

const reducer = (state: UsersState = initialState, action: AnyAction): UsersState => {
  switch (action.type) {
    case UsersActions.GET_USER_BY_ID_SUCCESS: {
      const payload = action.payload as GetUserSuccessActionPayload;
      const fetchedUser = payload.user;
      const user = {
        [fetchedUser.id]: fetchedUser,
      };

      return {
        ...state,
        usersById: {
          ...state.usersById,
          ...user,
        },
      };
    }
    case UsersActions.GET_USER_BY_ID_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    }
    case UsersActions.UPDATE_SORTING: {
      const payload = action.payload as ListConfigSorting;
      return updateSorting(state, payload);
    }
    case UsersActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    case UsersActions.UPDATE_LISTCONFIG_PROPERTIES:
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          properties: action.payload.properties,
        },
      };
    case UsersActions.GET_FUNCTION_TEST_RUN_DATA_SUCCESS:
    case UsersActions.UPDATE_FUNCTION_TEST_RUN_DATA_SUCCESS:
      return {
        ...state,
        testRunDataByFunctionId: {
          ...state.testRunDataByFunctionId,
          [action.payload.functionId]: action.payload.testRunData,
        },
      };
    default:
      return state;
  }
};

export default reducer;
