import React, { createContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { uniqBy } from 'lodash';
import { SelectableVariable } from '@workerbase/types/Variable';
import { FCWithChildren } from '@types';

export const VariablesContext = createContext<{ variables: SelectableVariable[]; isLoading: boolean }>({
  variables: [],
  isLoading: false,
});

interface VariablesContextProviderProps {
  variables?: SelectableVariable[];
  getVariables?: () => Promise<SelectableVariable[] | undefined>;
}

export const VariablesContextProvider: FCWithChildren<VariablesContextProviderProps> = ({
  variables = [],
  getVariables,
  children,
}) => {
  const [fetchedVariables, setFetchedVariables] = useState<SelectableVariable[]>(variables);
  const [isLoading, setIsLoading] = useState<boolean>(!!getVariables);

  useEffect(() => {
    if (!getVariables) {
      return;
    }
    (async () => {
      try {
        const fetchedVariables = await getVariables();
        if (!fetchedVariables) {
          setFetchedVariables([]);
        } else {
          const variableSuggestions = fetchedVariables.map(({ name, datatype, lastValue }) => ({
            name,
            datatype,
            lastValue,
          }));
          const uniqueSortedVariables = uniqBy(variableSuggestions, 'name').sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          setFetchedVariables(uniqueSortedVariables);
          setIsLoading(false);
        }
      } catch (err) {
        toast.error('Fetching of the rule variables failed');
        setFetchedVariables([]);
        setIsLoading(false);
      }
    })();
  }, []);

  const memoizedValues = useMemo(
    () => ({ variables: fetchedVariables.length ? fetchedVariables : variables, isLoading }),
    [fetchedVariables, isLoading, variables],
  );

  return <VariablesContext.Provider value={memoizedValues}>{children}</VariablesContext.Provider>;
};
