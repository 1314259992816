import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { Divider, FormHelperText, Stack, Typography } from '@mui/material';
import { WuiFormikTextField } from '@uiKit';
import { InputChecklistOperators, type IBuilderStepInputChecklist } from '@workerbase/domain/document';
import { FormikObserver } from 'utils/formik';
import { FormikToggle } from 'components/Input/Field';
import { FormBuilderSettingsTabsWrapper } from 'components/DocumentBuilder/FormBuilderSettingsTabsWrapper';
import { FilterGroupOperator, FilterTypes } from '@workerbase/domain/common';
import { InputChecklistSettingsFormValues } from './types';
import { FlaggingCriteriaForm } from './components/FlaggingCriteriaForm/FlaggingCriteriaForm';
import {
  DEFAULT_FLAGGING_CRITERIA,
  DEFAULT_FLAGGING_CRITERIA_CONDITION,
  DEFAULT_FLAGGING_CRITERIA_GROUP,
} from './components/FlaggingCriteriaForm';

interface BuilderStepInputChecklistSettingsProps {
  value: IBuilderStepInputChecklist;
  onChange: (value: IBuilderStepInputChecklist) => void;
}

const conditionSchema = Yup.object().shape({
  type: Yup.string().oneOf([FilterTypes.CONDITION]).required(),
  name: Yup.string().required(),
  operator: Yup.string().oneOf(Object.values(InputChecklistOperators)).required(),
  value: Yup.string().required('Value is required'),
});

const groupSchema = Yup.object().shape({
  type: Yup.string().oneOf([FilterTypes.GROUP]).required(),
  groupOperator: Yup.string().oneOf(Object.values(FilterGroupOperator)).required(),
  conditions: Yup.array()
    .of(Yup.lazy((value) => (value.type === FilterTypes.GROUP ? groupSchema : conditionSchema)))
    .required()
    .min(1),
});

const validationSchema = Yup.object({
  minSelect: Yup.number().integer().min(1).required(),
  multiple: Yup.boolean().required(),
  required: Yup.boolean(),
  hasFlaggingCriteria: Yup.boolean(),
  flaggingCriteria: Yup.object().when('hasFlaggingCriteria', {
    is: (val) => val === true,
    then: () => groupSchema,
    otherwise: () => Yup.object().nullable(),
  }),
});

export const BuilderStepInputChecklistSettings: FC<BuilderStepInputChecklistSettingsProps> = ({ value, onChange }) => {
  const intl = useIntl();

  const handleChange =
    (formik: FormikProps<InputChecklistSettingsFormValues>) => (values: InputChecklistSettingsFormValues) => {
      if (!values.multiple && values.minSelect !== null) {
        formik.setFieldValue('minSelect', null);
      }
      if (values.multiple && values.minSelect === null) {
        formik.setFieldValue('minSelect', 1);
      }

      onChange({
        ...value,
        minSelect: values.minSelect,
        multiple: values.multiple,
        required: values.required,
        flaggingCriteria: values.flaggingCriteria || null,
      });
    };

  return (
    <Formik
      initialValues={{
        stepId: value.id,
        minSelect: value.minSelect,
        multiple: value.multiple,
        required: value.required,
        hasFlaggingCriteria: !!((value?.flaggingCriteria?.conditions || []).length > 0),
        flaggingCriteria: value && value?.flaggingCriteria,
      }}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      validateOnChange
    >
      {(formik) => (
        <FormBuilderSettingsTabsWrapper
          header={
            <Typography variant="h6">
              {intl.formatMessage({ id: 'document.builder.input-checklist.settings' })}
            </Typography>
          }
          general={
            <Stack spacing={1} component={Form} id="note-form">
              <FormikObserver onChange={handleChange(formik)} />
              <FormikToggle name="multiple" label="form.checklist.allow-multiple" />
              {formik.values.multiple && (
                <>
                  <WuiFormikTextField
                    required
                    variant="outlined"
                    type="number"
                    name="minSelect"
                    label="form.checklist.minimum-selection"
                    fullWidth
                  />
                  {!!value.minSelect && value.minSelect > value.options.length && (
                    <FormHelperText error>
                      {intl.formatMessage({ id: 'document.builder.input-checklist.minimum-selection-impossible' })}
                    </FormHelperText>
                  )}
                </>
              )}
              <Divider />
              <FormikToggle name="required" label="form-validation.required" />
            </Stack>
          }
          flaggingCriteria={
            <>
              <FormikToggle
                name="hasFlaggingCriteria"
                label={intl.formatMessage({ id: 'document.set-flagging-criteria' })}
                labelIsTranslationId={false}
                onToggleCallback={(hasFlaggingCriteria: boolean) => {
                  formik.setFieldValue(
                    'flaggingCriteria',
                    hasFlaggingCriteria
                      ? {
                          ...DEFAULT_FLAGGING_CRITERIA,
                          conditions: [
                            {
                              ...DEFAULT_FLAGGING_CRITERIA_GROUP,
                              conditions: [
                                {
                                  ...DEFAULT_FLAGGING_CRITERIA_CONDITION,
                                  name: value.options[0].value,
                                },
                              ],
                            },
                          ],
                        }
                      : null,
                  );
                }}
              />

              {formik.values.hasFlaggingCriteria && (
                <FlaggingCriteriaForm name="flaggingCriteria" options={value.options} />
              )}
            </>
          }
        />
      )}
    </Formik>
  );
};
