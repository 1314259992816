import { AnyAction } from 'redux';

import { ListConfigProperties } from '@workerbase/api/http/user';
import { DatabaseItemsListConfig, DatabaseItemResponse } from '@workerbase/api/http/database';

import { UpdateListConfigActionPayload } from '@redux/common/ListConfig/actions';
import { DatabaseItemsActions, UpdateDatabaseItemsSortingActionPayload } from './actions';
import { getDatabaseItemsListConfigs } from './utils';

export type DatabaseItemsState = Readonly<{
  databaseItemsById: { [key: string]: DatabaseItemResponse };
  currentListItemsIds: string[];
  listConfigsByDatabaseId: { [key: string]: DatabaseItemsListConfig };
  propertiesByDatabaseId: {
    [key: string]: ListConfigProperties[];
  };
  errorMessage: string | null;
}>;

export const initialState: DatabaseItemsState = {
  databaseItemsById: {},
  currentListItemsIds: [],
  listConfigsByDatabaseId: {},
  propertiesByDatabaseId: {},
  errorMessage: null,
};

const reducer = (state: DatabaseItemsState = initialState, action: AnyAction): DatabaseItemsState => {
  switch (action.type) {
    case DatabaseItemsActions.UPDATE_ITEMS_LISTCONFIG_PROPERTIES: {
      return {
        ...state,
        propertiesByDatabaseId: {
          ...state.propertiesByDatabaseId,
          [action.payload.resourceId]: action.payload.properties,
        },
      };
    }
    case DatabaseItemsActions.UPDATE_ITEMS_UPDATE_SORTING: {
      const payload = action.payload as UpdateDatabaseItemsSortingActionPayload;
      const storedListConfigs = getDatabaseItemsListConfigs(state, payload.resourceId);
      storedListConfigs.sorting = payload.sorting;
      return {
        ...state,
        listConfigsByDatabaseId: {
          ...state.listConfigsByDatabaseId,
          [payload.resourceId]: storedListConfigs,
        },
      };
    }
    case DatabaseItemsActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return restoreDatabaseItemsListConfigs(state, payload);
    }
    default:
      return state;
  }
};

const restoreDatabaseItemsListConfigs = (state, payload: UpdateListConfigActionPayload) => {
  const resourceId = payload.resourceId || '';
  const listConfigsByDatabaseId = state.listConfigsByDatabaseId;
  const propertiesByDatabaseId = state.propertiesByDatabaseId;

  if (payload.sorting) {
    listConfigsByDatabaseId[resourceId] = {
      sorting: payload.sorting,
    };
  }

  propertiesByDatabaseId[resourceId] = payload.properties;

  return {
    ...state,
    listConfigsByDatabaseId,
    propertiesByDatabaseId,
  };
};

export default reducer;
