import { ListConfigProperties } from '@workerbase/api/http/user';
import { ConnectorGET, ConnectorPOST, ConnectorPUT } from '@workerbase/api/http/connector';

import { Connector } from 'services/types/Connector';

import { PaginationMeta } from '@workerbase/types/Response';

import { BaseAction } from '../common/actions';

import {
  updateFilteringFactory,
  updateSortingFactory,
  updatePaginationFactory,
  updateListConfigFactory,
  updateSelectedTabKeyFactory,
} from '../common/ListConfig/actions';

export enum ConnectorsActions {
  GET_CONNECTORS_REQUEST = '@@connectors/GET_CONNECTORS_REQUEST',
  GET_CONNECTORS_SUCCESS = '@@connectors/GET_CONNECTORS_SUCCESS',
  GET_CONNECTORS_FAILURE = '@@connectors/GET_CONNECTORS_FAILURE',
  GET_CONNECTOR_BY_ID_REQUEST = '@@connectors/GET_CONNECTOR_BY_ID_REQUEST',
  GET_CONNECTOR_BY_ID_SUCCESS = '@@connectors/GET_CONNECTOR_BY_ID_SUCCESS',
  GET_CONNECTOR_BY_ID_FAILURE = '@@connectors/GET_CONNECTOR_BY_ID_FAILURE',
  DELETE_CONNECTOR_BY_ID_REQUEST = '@@connectors/DELETE_CONNECTOR_BY_ID_REQUEST',
  DELETE_CONNECTOR_BY_ID_SUCCESS = '@@connectors/DELETE_CONNECTOR_BY_ID_SUCCESS',
  DELETE_CONNECTOR_BY_ID_FAILURE = '@@connectors/DELETE_CONNECTOR_BY_ID_FAILURE',
  CREATE_CONNECTOR_REQUEST = '@@connectors/CREATE_CONNECTOR_REQUEST',
  CREATE_MQTT_CONNECTOR_REQUEST = '@@connectors/CREATE_MQTT_CONNECTOR_REQUEST',
  UPDATE_CONNECTOR_BY_ID_REQUEST = '@@connectors/UPDATE_CONNECTOR_BY_ID_REQUEST',
  UPDATE_PAGINATION = '@@connectors/UPDATE_PAGINATION',
  UPDATE_SORTING = '@@connectors/UPDATE_SORTING',
  UPDATE_FILTERING = '@@connectors/UPDATE_FILTERING',
  UPDATE_LISTCONFIG_PROPERTIES = '@@connectors/UPDATE_LISTCONFIG_PROPERTIES',
  UPDATE_LIST_CONFIG = '@@connectors/UPDATE_LIST_CONFIG',
  UPDATE_SELECTED_TAB_KEY = '@@connectors/UPDATE_SELECTED_TAB_KEY',
  MQTT_SUBSCRIBE_EVENTS = '@@connectors/MQTT_SUBSCRIBE_EVENTS',
  MQTT_UNSUBSCRIBE_EVENTS = '@@connectors/MQTT_UNSUBSCRIBE_EVENTS',
  HANDLE_INCOMING_MQTT_EVENT = '@@connectors/HANDLE_INCOMING_MQTT_EVENT',
}

export const getConnectorsRequestAction = (projectId: string): BaseAction => ({
  type: ConnectorsActions.GET_CONNECTORS_REQUEST,
  payload: { projectId },
});

export const getConnectorsSuccess = (data: Connector[], meta: PaginationMeta): BaseAction => ({
  type: ConnectorsActions.GET_CONNECTORS_SUCCESS,
  payload: {
    connectors: data,
    meta,
  },
});

export const getConnectorsError = (errorMessage: string): BaseAction => ({
  type: ConnectorsActions.GET_CONNECTORS_FAILURE,
  payload: {
    errorMessage,
  },
});

// GET_CONNECTOR_BY_ID
export const getConnectorByIdRequestAction = (connectorId: string): BaseAction => ({
  type: ConnectorsActions.GET_CONNECTOR_BY_ID_REQUEST,
  payload: { connectorId },
});

export const getConnectorByIdSuccess = (data: Connector): BaseAction => ({
  type: ConnectorsActions.GET_CONNECTOR_BY_ID_SUCCESS,
  payload: {
    connector: data,
  },
});

export const getConnectorByIdError = (errorMessage: string): BaseAction => ({
  type: ConnectorsActions.GET_CONNECTOR_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// CREATE_CONNECTOR
export interface CreateConnectorRequestAction {
  type: string;
  payload: {
    connector: ConnectorPOST;
  };
}
export const createConnectorRequest = (connector: ConnectorPOST): CreateConnectorRequestAction => ({
  type: ConnectorsActions.CREATE_CONNECTOR_REQUEST,
  payload: { connector },
});

// DELETE_CONNECTOR_BY_ID
export interface DeleteConnectorByIdRequestAction {
  type: string;
  payload: {
    connectorId: string;
  };
}
export const deleteConnectorByIdRequest = (connectorId: string): DeleteConnectorByIdRequestAction => ({
  type: ConnectorsActions.DELETE_CONNECTOR_BY_ID_REQUEST,
  payload: { connectorId },
});

export const deleteConnectorByIdSuccess = (connectorId: string): DeleteConnectorByIdRequestAction => ({
  type: ConnectorsActions.DELETE_CONNECTOR_BY_ID_SUCCESS,
  payload: {
    connectorId,
  },
});

export const deleteConnectorByIdError = (errorMessage: string): BaseAction => ({
  type: ConnectorsActions.DELETE_CONNECTOR_BY_ID_FAILURE,
  payload: {
    errorMessage,
  },
});

// UPDATE_CONNECTOR_BY_ID
export interface UpdateConnectorByIdRequestAction {
  type: string;
  payload: {
    connector: ConnectorPUT;
    connectorId: string;
  };
}
export const updateConnectorByIdRequest = (
  connectorId: string,
  connector: ConnectorPUT,
): UpdateConnectorByIdRequestAction => ({
  type: ConnectorsActions.UPDATE_CONNECTOR_BY_ID_REQUEST,
  payload: { connector, connectorId },
});

// PAGINATION
export const updatePagination = updatePaginationFactory(ConnectorsActions.UPDATE_PAGINATION);
export const updateSorting = updateSortingFactory(ConnectorsActions.UPDATE_SORTING);
export const updateFiltering = updateFilteringFactory(ConnectorsActions.UPDATE_FILTERING);
export const updateSelectedTabKey = updateSelectedTabKeyFactory(ConnectorsActions.UPDATE_SELECTED_TAB_KEY);

export const updateListConfigProperties = (newProperties: ListConfigProperties[]) => ({
  type: ConnectorsActions.UPDATE_LISTCONFIG_PROPERTIES,
  payload: { properties: newProperties },
});

export const updateListConfig = updateListConfigFactory(ConnectorsActions.UPDATE_LIST_CONFIG);

export interface EventsSubscribeAction {
  type: ConnectorsActions.MQTT_SUBSCRIBE_EVENTS | ConnectorsActions.MQTT_UNSUBSCRIBE_EVENTS;
  payload: { connectorId: string };
}

export const subscribeToMqttEvents = (connectorId: string) => ({
  type: ConnectorsActions.MQTT_SUBSCRIBE_EVENTS,
  payload: { connectorId },
});

export const unsubscribeToMqttEvents = (connectorId: string) => ({
  type: ConnectorsActions.MQTT_UNSUBSCRIBE_EVENTS,
  payload: { connectorId },
});

export interface IncomingMqttEventAction {
  type: string;
  payload: {
    topic: string;
    message: { data: ConnectorGET };
    regexpArray: string[];
  };
}
export const handleIncomingMqttEvent = (topic, message, regexpArray): IncomingMqttEventAction => ({
  type: ConnectorsActions.HANDLE_INCOMING_MQTT_EVENT,
  payload: {
    topic,
    message,
    regexpArray,
  },
});
