import * as Sentry from '@sentry/react';
import { getIntl } from '../providers/IntlProvider';

export class SentryUtils {
  private static EVENT_MESSAGES_TO_IGNORE = ['ResizeObserver loop limit exceeded'];

  public static shouldIgnoreEvent(event: Sentry.Event) {
    const message = this.getMessageFromEvent(event);
    // eslint-disable-next-line no-restricted-syntax
    for (const messageToIgnore of this.EVENT_MESSAGES_TO_IGNORE) {
      if (message.includes(messageToIgnore)) {
        return true;
      }
    }
    return false;
  }

  public static getMessageFromEvent(event: Sentry.Event) {
    const intl = getIntl();
    let result = intl.formatMessage({
      id: 'global.error-unexpected',
    });
    const exceptionValues = event?.exception?.values;
    if (exceptionValues && exceptionValues.length) {
      const value = exceptionValues[0].value;
      if (value) {
        result = value;
      }
    }
    return result;
  }
}
