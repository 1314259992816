import { ComparingConditionOperator, ConditionOperator, DirectConditionOperator } from '@workerbase/domain/rule';

export const OPERATORS_TRANSLATION_ID_MAPPING: Record<ConditionOperator, string> = {
  [DirectConditionOperator.EMPTY]: 'rules.filter.empty',
  [ComparingConditionOperator.EQUAL]: 'rules.filter.equal',
  [DirectConditionOperator.IS_NOT_EMPTY]: 'rules.filter.is-not-empty',
  [ComparingConditionOperator.MATCHES]: 'rules.filter.matches',
  [ComparingConditionOperator.CONTAINS]: 'rules.filter.contains',
  [ComparingConditionOperator.BEGINS]: 'rules.filter.begins',
  [ComparingConditionOperator.ENDS]: 'rules.filter.ends',
  [DirectConditionOperator.EXISTS]: 'rules.filter.exists',
  [ComparingConditionOperator.EQUALS_VAR]: 'rules.filter.equals-variable',
  [ComparingConditionOperator.REGEX]: 'rules.filter.regex',
  [ComparingConditionOperator.DOES_NOT_EQUAL]: 'rules.filter.does-not-equal',
  [ComparingConditionOperator.DOES_NOT_MATCH]: 'rules.filter.does-not-match',
  [ComparingConditionOperator.DOES_NOT_CONTAIN]: 'rules.filter.does-not-contain',
  [ComparingConditionOperator.DOES_NOT_BEGIN]: 'rules.filter.does-not-begin',
  [ComparingConditionOperator.DOES_NOT_END]: 'rules.filter.does-not-end',
  [DirectConditionOperator.DOES_NOT_EXIST]: 'rules.filter.does-not-exist',
  [DirectConditionOperator.TRUE]: 'rules.filter.true',
  [DirectConditionOperator.FALSE]: 'rules.filter.false',
  [ComparingConditionOperator.GREATER_THAN]: '>',
  [ComparingConditionOperator.GREATER_OR_EQUAL]: '>=',
  [ComparingConditionOperator.LESS_THAN]: '<',
  [ComparingConditionOperator.LESS_OR_EQUAL]: '<=',
  [DirectConditionOperator.IS_CHECKED]: 'rules.filter.isChecked',
  [DirectConditionOperator.IS_NOT_CHECKED]: 'rules.filter.isNotChecked',
};
