/* eslint-disable max-classes-per-file */
import { Prop, Schema, SchemaFactory } from '@nestjs/mongoose';
import { IStepButton, StepsIconTypes } from '@workerbase/domain/workinstruction';
import { IsNumber, IsOptional, IsString } from 'class-validator';
import { ButtonBase, ButtonBaseStyles } from './BaseButton';

@Schema({ _id: false })
export class StepButtonStyles extends ButtonBaseStyles {}

@Schema({ _id: false })
export class StepButton extends ButtonBase implements IStepButton {
  @Prop({ required: true })
  @IsNumber()
  index: number;

  @Prop({
    type: String,
    enum: Object.values(StepsIconTypes),
  })
  @IsString()
  @IsOptional()
  icon?: StepsIconTypes;

  @Prop()
  @IsString()
  @IsOptional()
  id?: string;

  /**
   * @deprecated use text property instead
   */
  @Prop()
  @IsString()
  @IsOptional()
  title?: string;
}

export const StepButtonSchema = SchemaFactory.createForClass(StepButton);
