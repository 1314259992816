import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

export interface UseQueryParamOptions {
  replace?: boolean;
}

/**
 * Use this hook to easily manipulate query params in the URL.
 */
export const useQueryParams = () => {
  const { push, replace } = useHistory();
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const setQueryParam = useCallback(
    (key: string, value: string, options?: UseQueryParamOptions) => {
      const route = options?.replace ? replace : push;
      queryParams.set(key, value);
      route({ search: queryParams.toString() });
    },
    [replace, push, queryParams],
  );

  const removeQueryParam = useCallback(
    (key: string, options?: UseQueryParamOptions) => {
      const route = options?.replace ? replace : push;
      queryParams.delete(key);
      route({ search: queryParams.toString() });
    },
    [replace, push, queryParams],
  );

  /*
   * Toggle a query param. If the query param is already set, it will be removed.
   * If it is not set, it will be added with value "1".
   */
  const toggleQueryParam = useCallback(
    (key: string) => {
      if (queryParams.has(key)) {
        queryParams.delete(key);
      } else {
        queryParams.set(key, '1');
      }
      push({ search: queryParams.toString() });
    },
    [queryParams, push],
  );

  const memoizedValues = useMemo(
    () => ({ queryParams, setQueryParam, removeQueryParam, toggleQueryParam }),
    [queryParams, setQueryParam, removeQueryParam, toggleQueryParam],
  );

  return memoizedValues;
};
