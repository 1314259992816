// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextInput-module__print___ETN_7{overflow:visible !important}.TextInput-module__inputContainer___EI0zD{font-family:Proxima Nova;font-style:normal;font-weight:normal;font-size:16px;line-height:20px;letter-spacing:0px;border:1px solid #cdd2e0;box-sizing:border-box;border-radius:6px;height:40px;padding:4px 12px;width:100%;display:flex;background-color:#fff}.TextInput-module__inputContainer___EI0zD:not(.TextInput-module__disabled___Ax51T):hover{border:1px solid #8992a9}.TextInput-module__inputContainer___EI0zD:not(.TextInput-module__disabled___Ax51T):focus-within{border:1px solid #2660e6;outline:none}.TextInput-module__disabled___Ax51T{pointer-events:none;background-color:#f2f2f2;border-color:#e6e6e6}.TextInput-module__disabled___Ax51T .TextInput-module__input___IdrFI{color:#999}.TextInput-module__prefix___EwvJj,.TextInput-module__suffix___k2_iE{color:#58637e;line-height:30px}.TextInput-module__prefix___EwvJj{margin-right:6px}.TextInput-module__suffix___k2_iE{margin-left:6px}.TextInput-module__input___IdrFI{font-family:Proxima Nova;font-style:normal;font-weight:normal;font-size:16px;line-height:20px;letter-spacing:0px;flex-grow:1;margin:0;width:100%}.TextInput-module__input___IdrFI.TextInput-module__placeholder___k3iTr{color:#8992a9}.TextInput-module__input___IdrFI:-moz-placeholder{color:#8992a9}.TextInput-module__input___IdrFI::-moz-placeholder{color:#8992a9}.TextInput-module__input___IdrFI:-ms-input-placeholder{color:#8992a9}.TextInput-module__input___IdrFI::-webkit-input-placeholder{color:#8992a9}.TextInput-module__redBorder___HOCYB{border:1px solid #ff6868}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"print": `TextInput-module__print___ETN_7`,
	"inputContainer": `TextInput-module__inputContainer___EI0zD`,
	"disabled": `TextInput-module__disabled___Ax51T`,
	"input": `TextInput-module__input___IdrFI`,
	"prefix": `TextInput-module__prefix___EwvJj`,
	"suffix": `TextInput-module__suffix___k2_iE`,
	"placeholder": `TextInput-module__placeholder___k3iTr`,
	"redBorder": `TextInput-module__redBorder___HOCYB`
};
export default ___CSS_LOADER_EXPORT___;
