import { DatabaseItemsListConfig } from '@workerbase/api/http/database';
import { DatabaseItemsState } from './reducer';

export const DEFAULT_LIST_CONFIGS: DatabaseItemsListConfig = {
  pagination: {
    currentPage: 1,
    itemsPerPage: 20,
  },
  filtering: {
    searchTerms: '',
  },
};

export const getDatabaseItemsListConfigs = (
  state: DatabaseItemsState,
  databaseIdOrSlug: string,
): DatabaseItemsListConfig => {
  if (state.listConfigsByDatabaseId) {
    const storedListConfig: DatabaseItemsListConfig = state.listConfigsByDatabaseId[databaseIdOrSlug];
    if (storedListConfig) {
      return {
        ...DEFAULT_LIST_CONFIGS,
        ...storedListConfig,
      };
    }
  }
  return DEFAULT_LIST_CONFIGS;
};
