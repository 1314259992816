import { FilterGroupOperator, FilterTypes, IFilterCondition, IFilterGroup } from '@workerbase/domain/common';
import { InputChecklistOperators } from '@workerbase/domain/document';
import { DirectConditionOperator } from '@workerbase/domain/rule';

export const DEFAULT_FLAGGING_CRITERIA_CONDITION: IFilterCondition<InputChecklistOperators> = {
  type: FilterTypes.CONDITION,
  name: '',
  operator: DirectConditionOperator.IS_CHECKED,
  value: '',
};

export const DEFAULT_FLAGGING_CRITERIA_GROUP: IFilterGroup<InputChecklistOperators> = {
  type: FilterTypes.GROUP,
  groupOperator: FilterGroupOperator.AND,
  conditions: [DEFAULT_FLAGGING_CRITERIA_CONDITION],
};

export const DEFAULT_FLAGGING_CRITERIA: IFilterGroup<InputChecklistOperators> = {
  groupOperator: FilterGroupOperator.OR,
  type: FilterTypes.GROUP,
  conditions: [DEFAULT_FLAGGING_CRITERIA_GROUP],
};
