export enum DatabaseItemsQueryOperators {
  EQ = '$eq',
  NE = '$ne',
  GT = '$gt',
  GTE = '$gte',
  LT = '$lt',
  LTE = '$lte',
  IN = '$in',
  NIN = '$nin',
  EXISTS = '$exists',
  AND = '$and',
  OR = '$or',
  LIKE = '$like',
  NOT_LIKE = '$notLike',
}
