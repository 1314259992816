import { IPaginatedQueryParams } from '@workerbase/api/http/common';
import { ListConfigSorting } from '@workerbase/api/http/user';
import { SkillResponse, SkillCreateBody, SkillUpdateBody } from '@workerbase/api/http/skill';
import { Skill } from 'services/types/Skill';
import { PaginatedResponse } from '@workerbase/types/api/pagination';
import { api } from './api';
import { normalizeSkill } from '../normalizers/skills';

const SKILLS_ENDPOINT = '/api/v1/skills';

export const getSkills = async (
  page = 1,
  perpage = 20,
  sorting?: ListConfigSorting,
  filtering?: string,
): Promise<PaginatedResponse<Skill[]>> => {
  const params: IPaginatedQueryParams = {
    page,
    perpage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
  };

  const {
    data: { data, ...rest },
  } = await api.get<PaginatedResponse<SkillResponse[]>>(SKILLS_ENDPOINT, { params });

  return { data: data.map(normalizeSkill), ...rest };
};

type GetSkillsByIds = (ids: string[]) => Promise<GetSkillsByIdsResponse>;
export type GetSkillsByIdsResponse = Skill[];
export type GetSkillsByIdsParams = Parameters<GetSkillsByIds>;
// TODO: needs to be refactored in WB-3605/WB-3606
export const getSkillsByIds: GetSkillsByIds = async (ids) => {
  if (!ids.length) {
    return [];
  }
  const promises = ids.map((skillId) => api.get<{ data: SkillResponse }>(`${SKILLS_ENDPOINT}/${skillId}`));
  const responses = await Promise.all(promises);
  return responses.map((resp) => normalizeSkill(resp.data.data));
};

export const getSkillById = async (skillId: string): Promise<Skill> => {
  const {
    data: { data },
  } = await api.get<{ data: SkillResponse }>(`${SKILLS_ENDPOINT}/${skillId}`);

  return normalizeSkill(data);
};

export const createSkill = async (skill: SkillCreateBody): Promise<Skill> => {
  const {
    data: { data },
  } = await api.post<{ data: SkillResponse }>(SKILLS_ENDPOINT, skill);

  return normalizeSkill(data);
};

export const updateSkillById = async (skillId: string, skill: SkillUpdateBody): Promise<Skill> => {
  const {
    data: { data },
  } = await api.put<{ data: SkillResponse }>(`${SKILLS_ENDPOINT}/${skillId}`, skill);

  return normalizeSkill(data);
};

export const deleteSkillById = async (skillId: string): Promise<boolean> => {
  await api.delete<{ success: boolean }>(`${SKILLS_ENDPOINT}/${skillId}`);

  return true;
};
