import _ from 'lodash';
import { AnyAction } from 'redux';

import { SortOrder } from '@workerbase/domain/common';
import {
  ListConfigFiltering,
  ListConfigPagination,
  ListConfigSorting,
  ListConfigTabs,
} from '@workerbase/api/http/user';
import { ProjectLogsListConfig } from '@workerbase/api/http/project';
import { LogLevel } from '@workerbase/domain/log';

import { ProjectLog, ProjectLogView, ProjectLogViewOptions } from 'services/types/ProjectLog';

import { PaginationMeta } from '@workerbase/types/Response';

import { ProjectLogsActions } from './actions';
import { UpdateListConfigActionPayload } from '../common/ListConfig/actions';
import {
  updatePagination,
  updateSorting,
  updateFiltering,
  updateListConfig,
  updateSelectedTabKey,
  updateStateTabs,
} from '../common/ListConfig/reducers';

export type ProjectLogsState = Readonly<{
  logsById: { [key: string]: ProjectLog };
  listConfigs: ProjectLogsListConfig;
  currentListItemsIds: string[];
  errorMessage: string | null;
}>;

export const initialState: ProjectLogsState = {
  logsById: {},
  listConfigs: {
    properties: [
      {
        selector: 'id',
        omit: true,
      },
      {
        selector: 'action',
        omit: false,
      },
      {
        selector: 'level',
        omit: false,
      },
      {
        selector: 'meta.createdAt',
        omit: false,
      },
    ],
    pagination: { currentPage: 1, itemsPerPage: 20 },
    filtering: {
      searchTerms: '',
    },
    sorting: {
      selector: 'meta.createdAt',
      sortDirection: SortOrder.DESC,
    },
    tabs: {
      selectedTabKey: LogLevel.ERROR,
      categoryKey: ProjectLogView.LEVEL,
      options: ProjectLogViewOptions[ProjectLogView.LEVEL],
      categories: Object.values(ProjectLogView),
    },
  },
  currentListItemsIds: [],
  errorMessage: null,
};

interface GetProjectLogsSuccessActionPayload {
  logs: ProjectLog[];
  meta: PaginationMeta;
}

interface GetProjectLogsFailureActionPayload {
  errorMessage: string;
}

const reducer = (state: ProjectLogsState = initialState, action: AnyAction): ProjectLogsState => {
  switch (action.type) {
    case ProjectLogsActions.GET_PROJECT_LOGS_SUCCESS: {
      const payload = action.payload as GetProjectLogsSuccessActionPayload;

      const logsById = payload.logs.reduce((prev, log) => {
        const updatedLogs = prev;
        updatedLogs[log.id] = log;
        return prev;
      }, {});

      return {
        ...state,
        logsById: {
          ...state.logsById,
          ...logsById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
          tabs: updateStateTabs(
            initialState.listConfigs.tabs,
            state.listConfigs.tabs,
            Object.values(LogLevel).includes(payload.meta.category as LogLevel) ? payload.meta.category : undefined,
            payload.meta.categoryKey || ProjectLogView.NO_KEY,
            payload.meta.stats,
          ),
        },
        currentListItemsIds: payload.logs.map((logs) => logs.id),
      };
    }
    case ProjectLogsActions.GET_PROJECT_LOGS_FAILURE: {
      const payload = action.payload as GetProjectLogsFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    }
    case ProjectLogsActions.UPDATE_PAGINATION: {
      const payload = action.payload as ListConfigPagination;
      return updatePagination(state, payload);
    }
    case ProjectLogsActions.UPDATE_SORTING: {
      const payload = action.payload as ListConfigSorting;
      return updateSorting(state, payload);
    }
    case ProjectLogsActions.UPDATE_FILTERING: {
      const payload = action.payload as ListConfigFiltering;
      return updateFiltering(state, payload);
    }
    case ProjectLogsActions.UPDATE_LISTCONFIG_PROPERTIES:
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          properties: _.unionBy(action.payload.properties, state.listConfigs.properties || [], 'selector'),
        },
      };
    case ProjectLogsActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    case ProjectLogsActions.UPDATE_CATEGORY_KEY: {
      const { category } = action.payload;
      const options = ProjectLogViewOptions[category as ProjectLogView] || [];
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          tabs: {
            categories: Object.values(ProjectLogView),
            categoryKey: action.payload.category,
            options,
            selectedTabKey: options[0]?.key || '',
          },
        },
      };
    }
    case ProjectLogsActions.UPDATE_SELECTED_TAB_KEY: {
      const payload = action.payload as ListConfigTabs;
      return updateSelectedTabKey(state, payload);
    }

    default:
      return state;
  }
};

export default reducer;
