import { ListConfigProperties } from '@workerbase/api/http/user';
import { DatabaseItemsListConfig } from '@workerbase/api/http/database';

import { RootState } from '../types';
import { getDatabaseItemsListConfigs } from './utils';

export const getListConfigs = (databaseIdOrSlug: string) => (store: RootState) => {
  const paginationConfig = getDatabaseItemsListConfigs(store.databaseItems, databaseIdOrSlug);
  const propertiesConfig = getDatabaseItemsProperties(databaseIdOrSlug)(store);
  const listConfig = getDatabaseItemsListConfig(databaseIdOrSlug)(store);

  return {
    ...paginationConfig,
    properties: propertiesConfig || [],
    filtering: listConfig?.filtering,
  };
};

export const getDatabaseItemsProperties =
  (databasesId: string) =>
  (store: RootState): ListConfigProperties[] =>
    store.databaseItems.propertiesByDatabaseId && store.databaseItems.propertiesByDatabaseId[databasesId];

export const getDatabaseItemsListConfig =
  (databasesId: string) =>
  (store: RootState): DatabaseItemsListConfig =>
    store.databaseItems.propertiesByDatabaseId && store.databaseItems.listConfigsByDatabaseId[databasesId];
