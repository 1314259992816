import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router';
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';

import { useSelector } from 'react-redux';
import { RootState } from '@redux/types';
import { getUser } from '@redux/Login';

import { getProfilePictureByUserId } from 'services/networking/users';

import ProfileIcon from 'components/ProfileIcon';
import { LoggedUser } from 'services/types/User';
import { history } from '../../../../redux/configureStore';

import { Routes } from '../../../../routes';
import styles from './UserActions.module.scss';

const UserActions: React.FC = () => {
  const intl = useIntl();
  const user = useSelector<RootState, LoggedUser | null>(getUser);

  const userId = user?.id || '';

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const handleUserMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={styles.container}>
      <ProfileIcon userId={userId} onClick={handleUserMenuOpen} getProfilePictureByUserId={getProfilePictureByUserId} />
      <Menu
        className={styles.userActionsMenu}
        id="user-actions-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        open={isMenuOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push(generatePath(Routes.Profile, { userId }))}>
          <ListItemIcon className={styles.icon}>
            <PersonIcon fontSize="small" color="inherit" />
          </ListItemIcon>
          <Typography variant="inherit">{intl.formatMessage({ id: 'users.profile' })}</Typography>
        </MenuItem>
        <MenuItem onClick={() => history.push(Routes.Logout)}>
          <ListItemIcon className={styles.icon}>
            <ExitToAppIcon fontSize="small" color="inherit" />
          </ListItemIcon>
          <Typography variant="inherit">{intl.formatMessage({ id: 'header.logout' })}</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserActions;
