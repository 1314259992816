import { StepTypes } from '../StepTypes.enum';
import { IInputNumberStepBase } from './Base';

export const INPUT_NUMBER_PICKER_DECIMAL_DIGITS_DEFAULT = 0;
export const INPUT_NUMBER_PICKER_INTEGER_DIGITS_DEFAULT = 3;

export interface IInputNumberPickerStep extends IInputNumberStepBase {
  type: StepTypes.INPUT_NUMBER_PICKER;
  description: string;
}
