import { FieldProps } from 'formik';
import { get, isArray, isString, isUndefined } from 'lodash';

/* Helper for easy extraction of the exact field metadata from formik */
export const getFieldMeta = <K extends string = string>(
  name: K,
  form: { errors: FieldProps['form']['errors']; touched: FieldProps['form']['touched'] },
): { isTouched: boolean; isInvalid: boolean; errorText?: string } => {
  const { errors, touched } = form;
  const isTouched = !!get(touched, name);
  const thisError = get(errors, name);
  const error = isArray(thisError) ? thisError[0] : thisError;
  const errorText = isString(error) && error.length ? error : undefined;

  return {
    isTouched,
    isInvalid: !isUndefined(errorText),
    errorText,
  };
};
