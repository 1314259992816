import { StepTypes } from '../StepTypes.enum';
import { Inputable, IStepBase } from './Base';

export const INPUT_NOICE_LEVEL_MAX_VALUE_DEFAULT = 0;
export const INPUT_NOICE_LEVEL_DURATION_DEFAULT = 5;

export interface IInputNoiseLevelStep extends IStepBase, Inputable {
  type: StepTypes.INPUT_NOISE_LEVEL;
  autoContinue: boolean;
  autoStartMeasurement: boolean;
  inputRequired: boolean;
  warningThresholdInDecibel: number;
  measureDurationInSec: number;
}
