import { FocusEvent } from 'react';

import { GroupOption, Option } from 'components/Input/SelectInput';

export enum FieldType {
  OUTPUT_TEXT = 'output_text',
  INPUT_TEXT = 'input_text',
  INPUT_SELECT = 'input_select',
  INPUT_GROUP_SELECT = 'input_group_select',
  INPUT_CHECKBOX = 'input_checkbox',
}

interface BaseComponentOption {
  type: FieldType;
  key: string; // can be a fieldName (eg 'name') or a field path (eg 'action.taskId') in order to support Formik fields
}

interface OutputTextOption extends BaseComponentOption {
  type: FieldType.OUTPUT_TEXT;
  text: string;
}

export interface InputTextOption extends BaseComponentOption {
  type: FieldType.INPUT_TEXT;
  placeholder?: string;
  variablesAutocompleteEnabled?: boolean;
  isDisabled?: boolean;
  description?: string;
  error?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export interface InputSelectOption extends BaseComponentOption {
  type: FieldType.INPUT_SELECT;
  options: Option[];
  isDisabled?: boolean;
}

export interface InputGroupSelectOption extends BaseComponentOption {
  type: FieldType.INPUT_GROUP_SELECT;
  options: GroupOption[];
  isDisabled?: boolean;
  isSearchable?: boolean;
}

export interface InputCheckboxOption extends BaseComponentOption {
  type: FieldType.INPUT_CHECKBOX;
  labelTranslationId: string;
  isChecked: boolean;
  updateComponent: (newValue: boolean, component: unknown) => unknown;
}

export type ComponentOption =
  | OutputTextOption
  | InputTextOption
  | InputSelectOption
  | InputGroupSelectOption
  | InputCheckboxOption;
