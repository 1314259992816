import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, LinearProgress, Link, Stack, Typography } from '@mui/material';
import { loginSSORequestSuccess } from '@redux/Login';
import { useQueryParams } from 'hooks/useQueryParams';
import { mapSSOQueryParamsToUser } from 'providers/AuthProvider/util';
import { Routes } from '../../routes';
import LogoImage from '../../assets/media/wb-login-logo.svg';

export const LandingSSO: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const router = useHistory();
  const { queryParams } = useQueryParams();

  const successQueryParam = queryParams.get('success');
  const isSuccessfulSSOLogin = successQueryParam === 'true';
  const errorMessage = queryParams.get('message') ?? '';

  if (isSuccessfulSSOLogin) {
    const loginData = mapSSOQueryParamsToUser(queryParams);
    dispatch(loginSSORequestSuccess(loginData));
  } else {
    if (errorMessage) {
      toast.error(errorMessage, { toastId: 'sso-error' });
    }
    router.push(Routes.Login);
  }

  return (
    <Stack py={20} alignItems="center" justifyContent="center">
      <Box maxWidth={467} mb={8}>
        <img src={LogoImage} />
      </Box>

      <Typography variant="h6">{intl.formatMessage({ id: 'login.sso.microsoft' })}</Typography>
      <LinearProgress sx={{ width: '100%', maxWidth: '240px', mx: 'auto' }} />

      <Typography mt={4}>
        <FormattedMessage
          id="login.sso.error"
          values={{
            // @ts-ignore -- This is a valid code, intl expects ReactNode but Element is also valid
            a: (text: string) => (
              <Link component={RouterLink} fontWeight={600} to={Routes.Login}>
                {text}
              </Link>
            ),
          }}
        />
      </Typography>
    </Stack>
  );
};
