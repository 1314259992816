import React, { ReactNode, useRef } from 'react';
import { Stack, SxProps, Theme } from '@mui/material';

import { Notes } from 'components/Notes';
import { useNotesContext } from 'providers/NotesProvider';
import { WuiDrawer } from '@uiKit/WuiDrawer/WuiDrawer';

interface Props {
  children: ReactNode;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

/**
 * Simple UI Component used for the main container on many pages.
 */
// TODO: remove default padding and make sure to create a layout that contains the header and footer and main container
const MainContainer: React.FC<Props> = ({ sx, children, onClick }) => {
  const { resource } = useNotesContext();
  // set root node ref
  const rootNodeRef = useRef<HTMLDivElement>(null);

  return (
    <Stack ref={rootNodeRef} direction="row" sx={{ height: 'calc(100vh - 118px)' }}>
      {resource && <Notes />}
      <Stack
        direction="column"
        sx={{ height: '100%', width: '100%', overflow: 'auto', boxSizing: 'border-box', ...sx }}
        onClick={onClick}
      >
        {children}
      </Stack>
      <WuiDrawer root={rootNodeRef.current} />
    </Stack>
  );
};

export default MainContainer;
