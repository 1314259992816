export enum DocumentBuilderStepTypes {
  InfoText = 'info-text',
  InfoMedia = 'info-media',
  InputChecklist = 'input-checklist',
  InputText = 'input-text',
  InputMedia = 'input-media',
  InputNumber = 'input-number',
  InputScancode = 'input-scancode',
  ContainerSection = 'container-section',
}

export const InputStepTypes: Readonly<InputStepTypes[]> = [
  DocumentBuilderStepTypes.InputChecklist,
  DocumentBuilderStepTypes.InputMedia,
  DocumentBuilderStepTypes.InputNumber,
  DocumentBuilderStepTypes.InputScancode,
  DocumentBuilderStepTypes.InputText,
];

export type InputStepTypes = Exclude<
  DocumentBuilderStepTypes,
  DocumentBuilderStepTypes.InfoMedia | DocumentBuilderStepTypes.InfoText | DocumentBuilderStepTypes.ContainerSection
>;
export type InfoStepTypes = Extract<
  DocumentBuilderStepTypes,
  DocumentBuilderStepTypes.InfoMedia | DocumentBuilderStepTypes.InfoText
>;

export type DocumentBuilderBasicStepTypes = Exclude<
  DocumentBuilderStepTypes,
  DocumentBuilderStepTypes.ContainerSection
>;
