// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VariablesMentionSuggestion-module__print___XJovU{overflow:visible !important}.VariablesMentionSuggestion-module__container___cXmLM{display:flex;font-weight:bold;overflow:hidden}.VariablesMentionSuggestion-module__variable___wc4OJ{flex:1}.VariablesMentionSuggestion-module__dataType___sz7U8{margin-left:8px;color:#79a1ff;font-weight:600 !important;flex:1;font-family:"Courier New",Courier,monospace !important}.VariablesMentionSuggestion-module__dataType___sz7U8.VariablesMentionSuggestion-module__arrayOrObject___Xzfcl{color:#e985e9}.VariablesMentionSuggestion-module__dataType___sz7U8.VariablesMentionSuggestion-module__unknown___hPiuJ{color:#8992a9}.VariablesMentionSuggestion-module__lastValue___ViB9F{margin-left:8px;color:rgba(115,115,115,.9);white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"print": `VariablesMentionSuggestion-module__print___XJovU`,
	"container": `VariablesMentionSuggestion-module__container___cXmLM`,
	"variable": `VariablesMentionSuggestion-module__variable___wc4OJ`,
	"dataType": `VariablesMentionSuggestion-module__dataType___sz7U8`,
	"arrayOrObject": `VariablesMentionSuggestion-module__arrayOrObject___Xzfcl`,
	"unknown": `VariablesMentionSuggestion-module__unknown___hPiuJ`,
	"lastValue": `VariablesMentionSuggestion-module__lastValue___ViB9F`
};
export default ___CSS_LOADER_EXPORT___;
