import { IFilter, IFilterCondition, IFilterGroup, isFilterGroup } from '@workerbase/domain/common';
import { ConditionOperator } from '@workerbase/domain/rule';

/**
 * Generator function that returns the list of conditions inside a ViewFilterGroup
 * @param filter{}`IFilter`
 */
function* filterConditionsGenerator<T extends ConditionOperator>(
  filter: IFilterGroup<T>,
): Generator<IFilterCondition<T>> {
  for (const condition of filter.conditions) {
    if (isFilterGroup<T>(condition)) {
      yield* filterConditionsGenerator<T>(condition);
    } else {
      yield condition;
    }
  }
}

export const getFilterConditions = <T extends ConditionOperator>(filter: IFilter<T>) =>
  Array.from(filterConditionsGenerator<T>(filter));
