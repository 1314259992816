import React, { ChangeEvent, FocusEvent, useCallback } from 'react';
import {
  MentionsInput,
  Mention,
  MentionItem,
  MentionProps,
  MentionsInputProps,
  SuggestionDataItem,
} from 'react-mentions';

import { convertValueToMentionsFormat } from '@uiKit/WuiMentionTextField/utils';
import styles from './CustomMentionInput.module.scss';

const MENTIONS_REGEX = /@\[(.+?)\]\((.+?)\)/g;

/**
 * Component always outputs string without the mention formatting - @[display](id).
 * We need to add mention formatting on input and to remove it on output using trigger as prefix.
 * Mention values are shown in the suggestion list with prefix in the beginning, eg 'capacity' -> '$capacity'
 *
 * Example with given prefix: "$"
 * "Hello $caller" -> "Hello @[$caller]($caller)" -> "Hello $caller"
 */

export interface CustomMentionInputProps {
  multiline?: boolean;
  name?: string;
  value?: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  trigger?: string;
  mentions: string[];
  renderSuggestion?: MentionProps['renderSuggestion'];
}

/**
 * @deprecated Use WuiMentionTextField
 */
export const CustomMentionInput: React.FC<CustomMentionInputProps> = ({
  multiline = false,
  value,
  name,
  onChange,
  onBlur,
  placeholder,
  disabled,
  trigger = '$',
  mentions = [],
  renderSuggestion,
}) => {
  const handleMentionsChange: MentionsInputProps['onChange'] = useCallback(
    (
      /*
       * This is the event sent: { target: { value: string } } but Formik
       * expects a "real" ChangeEvent, so I don't put any type
       */
      event,
      newValue: string,
      newPlainTextValue: string,
      mentions: MentionItem[],
    ) => {
      const updatedEvent = event;
      /**
       * This is needed for Formik.
       * If the event does not have a name, Formik can't update its state.
       * MentionsInput does not send a proper Event object, so we need to manually add this value.
       */
      updatedEvent.target.name = name;

      /**
       * Replaces mentions @[displayValue](idValue) with display value in format `${trigger}{value}`
       * Example, "Option @[$varName](123) test" -> "Option $varName test"
       */
      updatedEvent.target.value = updatedEvent.target.value.replace(MENTIONS_REGEX, '$1');

      onChange(event);
    },
    [onChange],
  );

  const convertMentionsToSuggestionDataItem = useCallback(
    (mentions: string[]): SuggestionDataItem[] =>
      mentions.map((mention) => ({
        display: `${trigger}${mention}`,
        id: mention,
      })),
    [trigger],
  );

  return (
    <MentionsInput
      value={convertValueToMentionsFormat({ value, trigger, mentions })}
      onChange={handleMentionsChange}
      name={name}
      className="mentions"
      classNames={styles}
      placeholder={placeholder}
      onBlur={onBlur}
      disabled={disabled}
      singleLine={!multiline}
      autoComplete="off"
      allowSuggestionsAboveCursor
    >
      <Mention
        trigger={trigger}
        className={styles.mentions__mention}
        data={convertMentionsToSuggestionDataItem(mentions)}
        renderSuggestion={renderSuggestion}
      />
    </MentionsInput>
  );
};
