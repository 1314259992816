import { SortOrder } from '../../common';
import { UserListConfigTypes } from './ListConfigTypes.enum';

export interface IListConfig {
  resourceId?: string;
  listType: UserListConfigTypes;
  config: IListConfigSingle;
}

export interface IListConfigSingle {
  properties?: IListConfigProperties[];
  sorting?: IListConfigSorting;
  pagination?: IListConfigPagination;
  filtering?: IListConfigFiltering;
  tabs?: IListConfigTabs;
}

export interface IListConfigProperties {
  selector: string;
  omit: boolean;
}

export interface IListConfigSorting {
  selector: string;
  sortDirection: SortOrder;
}

export interface IListConfigPagination {
  totalItems?: number;
  currentPage: number;
  itemsPerPage: number;
}

export interface IListConfigFiltering {
  searchTerms: string;
}

export interface IListConfigTabs {
  selectedTabKey: string;
  categoryKey: string;
  categories: string[];
  options: IListConfigTabOption[];
}

export interface IListConfigTabOption {
  key: string;
  count: number;
  titleTranslationKey?: string;
  title?: string;
  activeColor: TabActiveColorTypes;
}

export enum TabActiveColorTypes {
  DEFAULT = 'default',
  DANGER = 'danger',
  GREEN = 'green',
  GRAY = 'gray',
}

export interface IListConfigCategoryOption {
  key: string;
  titleTranslationKey: string;
  count: number;
  activeColor: TabActiveColorTypes;
}

export enum ListConfigPropertySelectors {
  LINKS = 'links',
}
