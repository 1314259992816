import { ConditionResourcesGET, GetRulesQueryParams } from '@workerbase/types/api/rules';
import { ListConfigSorting } from '@workerbase/api/http/user';
import { RuleGET, RulePOST, RulePUT, Rule } from 'services/types/Rule';
import { ApiResponsePaginated } from '@workerbase/types/Response';
import { normalizeRule } from '../normalizers/rules';
import { api } from './api';

const RULES_ENDPOINT = '/api/v1/rules';

type GetRules = (options: {
  projectId: string;
  page?: number;
  perPage?: number;
  sorting?: ListConfigSorting;
  filtering?: string;
  selectedTabKey?: string;
  categoryKey?: string;
  aggregateLinks?: boolean;
}) => Promise<ApiResponsePaginated<Rule[]>>;

export const getRules: GetRules = async ({
  projectId,
  page = 1,
  perPage = 20,
  sorting,
  filtering,
  selectedTabKey,
  categoryKey,
  aggregateLinks,
}) => {
  const params: GetRulesQueryParams = {
    project: projectId,
    page,
    perpage: perPage,
    sort: sorting?.selector,
    order: sorting?.sortDirection,
    textSearch: filtering,
    category: selectedTabKey && categoryKey ? selectedTabKey : undefined,
    categoryKey: selectedTabKey && categoryKey ? categoryKey : undefined,
    aggregateLinks,
  };

  const {
    data: { data, ...rest },
  } = await api.get<ApiResponsePaginated<RuleGET[]>>(RULES_ENDPOINT, { params });
  return { data: data.map(normalizeRule), ...rest };
};

// See documentation here:
// https://www.notion.so/workerbase/Rule-Engine-c9c9fc35b5bc45fabcc5fd7f9ffb433c
// The API outputs the whole URL which looks like this:
// /api/v1/resources/{ResourceTypes.Project}
// So no need to append the BASE_URL
export const getConditionResources = async (resourceUrl: string): Promise<ConditionResourcesGET[]> => {
  const {
    data: { data },
  } = await api.get<{ data: ConditionResourcesGET[] }>(`/${resourceUrl}`);

  return data;
};

export const createRule = async (rule: RulePOST): Promise<Rule> => {
  const {
    data: { data },
  } = await api.post<{ data: RuleGET }>(RULES_ENDPOINT, rule);

  return normalizeRule(data);
};

export const getRuleById = async (ruleId: string): Promise<Rule> => {
  const {
    data: { data },
  } = await api.get<{ data: RuleGET }>(`${RULES_ENDPOINT}/${ruleId}`);

  return normalizeRule(data);
};

export const updateRuleById = async (ruleId: string, rule: RulePUT): Promise<Rule> => {
  const {
    data: { data },
  } = await api.put<{ data: RuleGET }>(`${RULES_ENDPOINT}/${ruleId}`, rule);

  return normalizeRule(data);
};

export const deleteRuleById = async (ruleId: string): Promise<boolean> => {
  await api.delete<{ success: boolean }>(`${RULES_ENDPOINT}/${ruleId}`);

  return true;
};

export const activateRule = async (ruleId: string): Promise<boolean> => {
  await api.post<{ success: boolean }>(`${RULES_ENDPOINT}/${ruleId}/activate`);

  return true;
};

export const deactivateRule = async (ruleId: string): Promise<boolean> => {
  await api.post<{ success: boolean }>(`${RULES_ENDPOINT}/${ruleId}/deactivate`);

  return true;
};
