import { useVariables } from 'hooks/useVariables';
import React, { FC } from 'react';
import { CustomMentionInput, CustomMentionInputProps } from '../CustomMentionInput';
import { VariablesMentionSuggestion } from './components/VariablesMentionSuggestion';

export type VariablesMentionInputProps = Omit<CustomMentionInputProps, 'mentions'>;

export const VariablesMentionInput: FC<VariablesMentionInputProps> = ({ name, value, onChange, multiline }) => {
  const { variables } = useVariables();

  const variableByName = variables.reduce((accumulator, variable) => {
    accumulator[variable.name] = variable;
    return accumulator;
  }, {});

  return (
    <CustomMentionInput
      name={name}
      value={value}
      multiline={multiline}
      onChange={onChange}
      mentions={variables.map((variable) => variable.name)}
      renderSuggestion={(suggestion, _search, _highlightedDisplay, _index, _focused) => {
        if (!suggestion.display) {
          return null;
        }
        const variableName = suggestion.display.replace('$', '');
        const variable = variableByName[variableName];
        return (
          <VariablesMentionSuggestion
            name={variable.name}
            datatype={variable.datatype}
            lastValue={variable.lastValue}
          />
        );
      }}
    />
  );
};
