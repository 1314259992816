import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { shouldDisplayLinks } from '@workerbase/domain/user';
import { RulesListConfig } from '@workerbase/api/http/rule';
import { PaginationMeta } from '@workerbase/types/Response';

import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';
import {
  activateRule,
  createRule,
  deactivateRule,
  deleteRuleById,
  getRuleById,
  getRules,
  updateRuleById,
} from 'services/networking/rules';
import { Rule } from 'services/types/Rule';

import { handleRequestError } from '../common';
import {
  ActivateOrDeactivateRuleByIdAction,
  activateOrDeactivateRuleByIdSuccess,
  CreateRuleRequestAction,
  deleteRuleByIdError,
  deleteRuleByIdSuccess,
  getRuleByIdError,
  getRuleByIdSuccess,
  getRulesError,
  getRulesRequestAction,
  getRulesSuccess,
  RulesActions,
  UpdateRuleByIdRequestAction,
} from './actions';
import { getListConfigs } from './selectors';

export function* getRulesRequestSaga(action) {
  try {
    const listConfigs: RulesListConfig = yield select(getListConfigs);
    const projectId = action.payload.projectId;

    const response = yield call(getRules, {
      projectId,
      page: listConfigs.pagination?.currentPage,
      perPage: listConfigs.pagination?.itemsPerPage,
      sorting: listConfigs.sorting,
      filtering: listConfigs.filtering?.searchTerms,
      selectedTabKey: listConfigs.tabs?.selectedTabKey,
      categoryKey: listConfigs.tabs?.categoryKey,
      aggregateLinks: shouldDisplayLinks(listConfigs),
    });

    const rules: Rule[] = response.data;
    const meta: PaginationMeta = response.meta;

    yield put(getRulesSuccess(rules, meta));
  } catch (error) {
    yield put(getRulesError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* deleteRuleByIdRequestSaga(action) {
  try {
    yield call(deleteRuleById, action.payload.ruleId);

    yield call(toast.success, 'Rule deleted');
    yield put(deleteRuleByIdSuccess(action.payload.ruleId));
  } catch (error) {
    yield put(deleteRuleByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* activateOrDeactivateRuleByIdRequestSaga(action: ActivateOrDeactivateRuleByIdAction) {
  try {
    let status = false;
    if (action.payload.activate) {
      status = yield call(activateRule, action.payload.ruleId);
    } else {
      status = yield call(deactivateRule, action.payload.ruleId);
    }
    if (status) {
      yield call(toast.success, `Rule has been ${action.payload.activate ? 'activated' : 'deactivated'} successfully.`);

      yield put(activateOrDeactivateRuleByIdSuccess(action.payload.ruleId, action.payload.activate));
      if (action.payload.projectId) {
        yield put(getRulesRequestAction(action.payload.projectId));
      }
    }
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* getRuleByIdRequestSaga(action) {
  try {
    const rule: Rule = yield call(getRuleById, action.payload.ruleId);

    yield put(getRuleByIdSuccess(rule));
  } catch (error) {
    yield put(getRuleByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* createRuleRequestSaga(action: CreateRuleRequestAction) {
  try {
    const rule: Rule = yield call(createRule, action.payload.rule);
    yield put(getRuleByIdSuccess(rule));
    yield put(push(`/projects/${rule.projectId}/rules`));
    yield call(toast.success, 'Rule created');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* updateRuleByIdRequestSaga(action: UpdateRuleByIdRequestAction) {
  try {
    const rule: Rule = yield call(updateRuleById, action.payload.ruleId, action.payload.rule);
    yield put(getRuleByIdSuccess(rule));
    yield put(push(`/projects/${rule.projectId}/rules`));
    yield call(toast.success, 'Rule updated');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* rulesSagas() {
  yield takeLatest(RulesActions.GET_RULES_REQUEST, getRulesRequestSaga);
  yield takeLatest(RulesActions.DELETE_RULE_BY_ID_REQUEST, deleteRuleByIdRequestSaga);
  yield takeLatest(RulesActions.ACTIVATE_OR_DEACTIVATE_RULE_BY_ID_REQUEST, activateOrDeactivateRuleByIdRequestSaga);
  yield takeLatest(RulesActions.CREATE_RULE_REQUEST, createRuleRequestSaga);
  yield takeEvery(RulesActions.GET_RULE_BY_ID_REQUEST, getRuleByIdRequestSaga);
  yield takeLatest(RulesActions.UPDATE_RULE_BY_ID_REQUEST, updateRuleByIdRequestSaga);
  yield takeLatest(RulesActions.UPDATE_SORTING, updateListConfigSaga);
  yield takeLatest(RulesActions.UPDATE_LISTCONFIG_PROPERTIES, updateListConfigSaga);
}
