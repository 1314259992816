import { AnyAction } from 'redux';

import { SortOrder } from '@workerbase/domain/common';
import {
  ListConfigFiltering,
  ListConfigPagination,
  ListConfigSorting,
  SkillsListConfig,
} from '@workerbase/api/http/user';
import { PaginationMeta } from '@workerbase/types/Response';

import { Skill } from 'services/types/Skill';
import { UpdateListConfigActionPayload } from '@redux/common/ListConfig/actions';

import { updatePagination, updateSorting, updateFiltering, updateListConfig } from '../common/ListConfig/reducers';
import { SkillsActions } from './actions';

export type SkillsState = Readonly<{
  skillsById: { [key: string]: Skill };
  listConfigs: SkillsListConfig;
  currentListItemsIds: string[];
  errorMessage: string | null;
  isLoading: boolean;
}>;

export const initialState: SkillsState = {
  skillsById: {},
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
    filtering: {
      searchTerms: '',
    },
    sorting: { selector: 'name', sortDirection: SortOrder.ASC },
    properties: [
      {
        selector: 'id',
        omit: true,
      },
      {
        selector: 'name',
        omit: false,
      },
      {
        selector: 'level',
        omit: false,
      },
      {
        selector: 'lastUsedAt',
        omit: false,
      },
    ],
  },
  currentListItemsIds: [],
  errorMessage: null,
  isLoading: false,
};

interface GetSkillsSuccessActionPayload {
  skills: Skill[];
  meta: PaginationMeta;
}

interface GetSkillSuccessActionPayload {
  skill: Skill;
}

interface GetSkillsFailureActionPayload {
  errorMessage: string;
}
interface SaveManySkillsActionPayload {
  skills: Skill[];
}

const reducer = (state: SkillsState = initialState, action: AnyAction): SkillsState => {
  switch (action.type) {
    case SkillsActions.GET_SKILLS_SUCCESS: {
      const payload = action.payload as GetSkillsSuccessActionPayload;

      const skillsById = payload.skills.reduce((prev, skill) => {
        const updatedSkills = prev;
        updatedSkills[skill.id] = skill;
        return prev;
      }, {});

      return {
        ...state,
        skillsById: {
          ...state.skillsById,
          ...skillsById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
        },
        isLoading: false,
        errorMessage: null,
        currentListItemsIds: payload.skills.map((skill) => skill.id),
      };
    }
    case SkillsActions.GET_SKILLS_FAILURE: {
      const payload = action.payload as GetSkillsFailureActionPayload;
      return {
        ...initialState,
        errorMessage: payload.errorMessage,
        isLoading: false,
        currentListItemsIds: [],
      };
    }
    case SkillsActions.UPDATE_PAGINATION: {
      const payload = action.payload as ListConfigPagination;
      return updatePagination(state, payload);
    }
    case SkillsActions.UPDATE_SORTING: {
      const payload = action.payload as ListConfigSorting;
      return updateSorting(state, payload);
    }
    case SkillsActions.UPDATE_FILTERING: {
      const payload = action.payload as ListConfigFiltering;
      return updateFiltering(state, payload);
    }
    case SkillsActions.UPDATE_LISTCONFIG_PROPERTIES:
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          properties: action.payload.properties,
        },
      };
    case SkillsActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }
    case SkillsActions.GET_SKILL_BY_ID_SUCCESS: {
      const payload = action.payload as GetSkillSuccessActionPayload;
      const fetchedSkill = payload.skill;
      const skill = {
        [fetchedSkill.id]: fetchedSkill,
      };

      return {
        ...state,
        skillsById: {
          ...state.skillsById,
          ...skill,
        },
      };
    }
    case SkillsActions.GET_SKILL_BY_ID_FAILURE: {
      const payload = action.payload as GetSkillsFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    }
    case SkillsActions.SAVE_MANY_SKILLS: {
      const { skills } = action.payload as SaveManySkillsActionPayload;
      const skillsById = skills.reduce((prev, skill) => {
        const updatedSkills = prev;
        updatedSkills[skill.id] = skill;
        return prev;
      }, {});
      return { ...state, skillsById: { ...state.skillsById, ...skillsById } };
    }
    case SkillsActions.DELETE_SKILL_BY_ID_SUCCESS: {
      const skillId: string = action.payload.skillId;

      const skillsById = { ...state.skillsById };
      if (skillsById[skillId]) {
        delete skillsById[skillId];
      }

      return {
        ...state,
        skillsById,
        currentListItemsIds: state.currentListItemsIds.filter((itemId) => itemId !== skillId),
      };
    }
    case SkillsActions.DELETE_SKILL_BY_ID_FAILURE: {
      const payload = action.payload as GetSkillsFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default reducer;
