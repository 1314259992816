import { IListConfigSingle, ListConfigPropertySelectors } from './ListConfig.interface';

// TODO: This function is only used in the frontend, so better move it to FE utils
export const shouldDisplayLinks = (listConfigs: IListConfigSingle) => {
  if (listConfigs.properties) {
    for (const property of listConfigs.properties) {
      if (property.selector === ListConfigPropertySelectors.LINKS && !property.omit) {
        return true;
      }
    }
  }
  return false;
};
