import { DocumentBuilderStepTypes } from '../DocumentBuilderStepTypes.enum';
import { BuilderSection, IBuilderStepBase } from '../DocumentBuilder.interface';
import { IDocumentBuilderSectionResult, IDocumentBuilderStepResultBase } from '../DocumentBuilderStepResult.interface';

export const IsSectionStep = (step: IBuilderStepBase): step is BuilderSection =>
  (step as BuilderSection).type === DocumentBuilderStepTypes.ContainerSection;

export const IsSectionResult = (
  stepResult: IDocumentBuilderStepResultBase,
): stepResult is IDocumentBuilderSectionResult => stepResult.type === DocumentBuilderStepTypes.ContainerSection;
