import { GoogleDriveFile } from '../../components/UploadFiles/GoogleDrivePicker/GoogleDrivePicker';

export interface WuiFileInputValue {
  id: string;
  name: string;
  size?: number;
  type: string;
}

export enum FileUploadStatus {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export enum FileOrigin {
  COMPUTER,
  GOOGLE_DRIVE,
}

export interface ComputerFile {
  file: File;
  origin: FileOrigin.COMPUTER;
}

export interface GDriveFile {
  file: GoogleDriveFile;
  accessToken: string;
  origin: FileOrigin.GOOGLE_DRIVE;
}

export type WuiFileInputFile = ComputerFile | GDriveFile;

export const isComputerFile = (file: WuiFileInputFile): file is ComputerFile => file.origin === FileOrigin.COMPUTER;
