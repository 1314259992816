import React, { FC } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { useDrawerContext } from './WuiDrawerContext';

interface WuiDrawerProps {
  root?: HTMLDivElement | null;
}

export const DRAWER_WIDTH = 320;

export const WuiDrawer: FC<WuiDrawerProps> = ({ root }) => {
  const { drawerContent, isOpen } = useDrawerContext();

  return (
    <MuiDrawer
      container={root}
      sx={{
        flexShrink: 0,
        flexGrow: 0,
        width: isOpen ? DRAWER_WIDTH : 0,
        opacity: isOpen ? 1 : 0,
        transition: (theme) =>
          theme.transitions.create(['width', 'opacity'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
      PaperProps={{
        sx: {
          px: 1.5,
          py: 2,
          zIndex: 30,
          position: 'relative',
          boxSizing: 'border-box',
          flexShrink: 0,
          width: DRAWER_WIDTH,
        },
      }}
      anchor="right"
      variant="persistent"
      open={isOpen}
    >
      {drawerContent}
    </MuiDrawer>
  );
};
