import { ListOptionsSource } from '../ListOptionsSource.enum';
import { StepTypes } from '../StepTypes.enum';
import { IInputListStepBase, IStepCore, InputList, InputListFromDatabase, InputListWithOptions } from '../steps/Base';

export const LISTABLE_STEPS = [
  StepTypes.INPUT_VALUE_LIST,
  StepTypes.INPUT_CHECKBOX_LIST,
  StepTypes.INPUT_INDEX_LIST,
  StepTypes.INPUT_TILE_LIST,
  StepTypes.INPUT_STEP_MENU,
  StepTypes.INPUT_BUTTON_LIST,
];

export const isListable = (step: IStepCore): step is InputList => LISTABLE_STEPS.includes(step.type);

export const isListOptionsFromOptions = (step: IInputListStepBase): step is InputListWithOptions =>
  step.source === ListOptionsSource.OPTIONS;

export const isListOptionsFromDatabase = (step: IInputListStepBase): step is InputListFromDatabase =>
  step.source === ListOptionsSource.DATABASE;
