import React from 'react';
import { Box } from '@mui/material';
import { FCWithChildren } from '@types';

export interface StepTextFieldPlaceholderProps {
  hasResult?: boolean;
}

export const StepTextFieldPlaceholder: FCWithChildren<StepTextFieldPlaceholderProps> = ({
  children,
  hasResult = false,
}) => (
  <Box
    sx={(theme) => ({
      height: '40px',
      padding: '8px',
      width: '100%',
      borderRadius: '4px',
      border: 'solid thin #ccc',
      color: hasResult ? theme.palette.grey[500] : '#ccc',
      boxSizing: 'border-box',
    })}
  >
    {children}
  </Box>
);
