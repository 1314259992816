import { push } from 'connected-react-router';
import { call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { EmailLoginResponse } from '@workerbase/api/http/auth';
import { makeLoginRequest } from 'services/networking/auth';
import { LoggedUser, User } from 'services/types/User';

import { mqttLogout } from '@redux/Mqtt/actions';
import { getListConfigSaga } from '@redux/common/ListConfig/sagas';
import { getIntl } from 'providers/IntlProvider';
import {
  setAccessTokenInLocalStorage,
  setDelayedLogoutOnSessionExp,
  setRefreshTokenInLocalStorage,
} from 'services/auth/auth.service';
import { Routes } from '../../routes';

import {
  LoginActions,
  setIsLoadingInitialListConfig,
  loginUserRequestError,
  loginUserRequestSuccess,
  loginUserUpdateData,
  logoutUser,
} from './actions';

import { getUser } from './selectors';

export function* loginSSOSaga(action) {
  try {
    setAccessTokenInLocalStorage(action.payload.loginToken);
    setRefreshTokenInLocalStorage(action.payload.refreshToken);
    yield put(loginUserRequestSuccess(action.payload));
    yield put(setIsLoadingInitialListConfig(true));
    yield call(getListConfigSaga, { payload: { userId: action.payload.user._id } });
    yield put(setIsLoadingInitialListConfig(false));
    setDelayedLogoutOnSessionExp(action.payload.refreshToken);
  } catch (error) {
    yield put(loginUserRequestError((error as Error).message));
  }
}
export function* loginUserRequestSaga(action) {
  try {
    const loginData: EmailLoginResponse = yield call(makeLoginRequest, action.payload);
    yield put(loginUserRequestSuccess(loginData));
    yield put(setIsLoadingInitialListConfig(true));
    yield call(getListConfigSaga, { payload: { userId: loginData.user._id } });
    yield put(setIsLoadingInitialListConfig(false));
    setDelayedLogoutOnSessionExp(loginData.refreshToken);
  } catch (error) {
    yield put(loginUserRequestError((error as Error).message));
  }
}

export function* logoutUserSaga(action) {
  yield put(push(Routes.Login));
  yield put(mqttLogout());
}

// Use to log the time left before session exp logout - debugging purposes (e.g. to see if the logout is delayed while using multi tabs)
/* let debugInterval: NodeJS.Timeout | null = null;
const startDelayedLogoutLogger = (delayInMs: number) => {
  if (debugInterval) {
    clearInterval(debugInterval);
  }

  const start = new Date().getTime();
  const end = start + delayInMs;

  debugInterval = setInterval(() => {
    const remainingTime = end - new Date().getTime();
    console.log(`Remaining time before logout: ${remainingTime / 1000} seconds`);
  }, 1000);

  return () => {
    if (!debugInterval) {
      return;
    }

    clearInterval(debugInterval);
  };
}; */

export function* logoutUserDelayedSaga(action) {
  const intl = getIntl();

  // const clearLogger = startDelayedLogoutLogger(action.payload.delayInMs);

  const { logout } = yield race({
    logout: take(LoginActions.LOGOUT_USER),
    timeout: delay(action.payload.delayInMs),
  });

  // If the user logs out before the timeout, cancel the logout
  if (logout) {
    // clearLogger();
    return;
  }

  yield put(logoutUser());
  toast.error(intl.formatMessage({ id: 'login.session-timeout' }));
  // clearLogger();
}

// When we update the logged in user, we also want to update the data of this user
// in the "Login" redux store.
// E.g. if logged in user change language, we want to update the language without
// forcing user to log out and log in again (so it updates its data in the Login redux store)
export function* updateLoggedInUserData(action) {
  const fetchedUser = action.payload.user as User;
  const loggedUser: LoggedUser | null = yield select(getUser);

  if (loggedUser === null) {
    return;
  }

  if (fetchedUser.id !== loggedUser.id) {
    return;
  }

  const updateUserDetails: Omit<
    LoggedUser,
    | 'isWorkbenchEnabled'
    | 'isOnPremise'
    | 'isMyWorkEnabled'
    | 'isAiEnabled'
    | 'isGDriveEnabled'
    | 'isHideProfilePictureEnabled'
  > = {
    id: fetchedUser.id,
    firstName: fetchedUser.firstName,
    lastName: fetchedUser.language,
    language: fetchedUser.language,
    email: fetchedUser.email,
    disableDeviceLock: fetchedUser.disableDeviceLock,
    isRootAdministrator: fetchedUser.isRootAdministrator,
    isDeveloper: fetchedUser.isDeveloper,
    roleIds: fetchedUser.rolesIds,
  };

  yield put(loginUserUpdateData(updateUserDetails));
}

export default function* loginSagas() {
  yield takeLatest(LoginActions.LOGIN_SSO_SUCCESS, loginSSOSaga);
  yield takeLatest(LoginActions.LOGIN_USER_REQUEST, loginUserRequestSaga);
  yield takeLatest(LoginActions.LOGOUT_USER, logoutUserSaga);
  yield takeLatest(LoginActions.LOGOUT_USER_DELAYED, logoutUserDelayedSaga);
  yield takeLatest(LoginActions.UPDATE_LOGGED_IN_USER, updateLoggedInUserData);
}
