import { cloneDeep } from 'lodash';

import {
  InputTileListBody,
  InputValueListBody,
  WorkinstructionGET,
  WorkinstructionPOST,
  WorkinstructionPUT,
} from '@workerbase/types/api/workinstructions';
import {
  INFO_EMPTY_ID,
  INPUT_NUMBER_DECIMAL_DIGITS_DEFAULT,
  INPUT_NUMBER_INTEGER_DIGITS_DEFAULT,
  INPUT_NUMBER_PICKER_DECIMAL_DIGITS_DEFAULT,
  INPUT_NUMBER_PICKER_INTEGER_DIGITS_DEFAULT,
  ImageScaleType,
  ListOptionsSource,
  StepTypes,
  isRendered,
} from '@workerbase/domain/workinstruction';
import { StepActionNextStep } from '@workerbase/types/MongoDB/Workinstruction/StepAction';
import { Devices } from '@workerbase/types/WorkinstructionStep/WorkinstructionStep';

import { WiWorkflowType } from 'components/WorkinstructionForm/types';
import { updateSpecificStepButtonIconAndAction } from 'components/WorkinstructionForm/utils/specificStepHelpers';
import { isNextSpecificStepButton } from 'components/WorkinstructionForm/utils/steps';
import { Workinstruction, WorkinstructionStep, WorkinstructionWizard } from 'services/types/Workinstruction';
import { ObjectId } from 'utils/generateObjectId';
import { generateWorkinstructionStep, getListOptionsSource } from 'utils/generateWorkinstructionStep';

const normalizeStep = (step: WorkinstructionStep, allSteps: WorkinstructionStep[]): WorkinstructionStep => {
  const updatedStep = {
    ...step,
    ...(isRendered(step)
      ? {
          buttons: step.buttons.map((button) => {
            // handle navigationDirection for old specific step buttons and update icon if needed
            if (
              isNextSpecificStepButton(button) &&
              !(button.action as StepActionNextStep).editorContext?.nextStepPosition
            ) {
              return updateSpecificStepButtonIconAndAction(button, step.id, allSteps);
            }
            return button;
          }),
        }
      : {}),
  };
  // There are well defined types in the project but it might happens that the backend is
  // sending not correct data (a required field might be missing).
  // The input type is "theoretically" correct but "practically" incorrect. This is why there are ts-ignore comments.
  // We can add checks here when we encounter problems with malformed / legacy steps
  switch (updatedStep.type) {
    case StepTypes.INPUT_NUMBER:
      if (typeof updatedStep.decimalDigits === 'undefined') {
        updatedStep.decimalDigits = INPUT_NUMBER_DECIMAL_DIGITS_DEFAULT;
      }
      if (typeof updatedStep.integerDigits === 'undefined') {
        updatedStep.integerDigits = INPUT_NUMBER_INTEGER_DIGITS_DEFAULT;
      }
      break;
    case StepTypes.INPUT_NUMBER_PICKER:
      if (typeof updatedStep.decimalDigits === 'undefined') {
        updatedStep.decimalDigits = INPUT_NUMBER_PICKER_DECIMAL_DIGITS_DEFAULT;
      }
      if (typeof updatedStep.integerDigits === 'undefined') {
        updatedStep.integerDigits = INPUT_NUMBER_PICKER_INTEGER_DIGITS_DEFAULT;
      }
      break;
    case StepTypes.INFO_PDF:
      updatedStep.page = String(Number(updatedStep.page) + 1); // backend counts 'page' starting at '0';
      break;
    case StepTypes.INPUT_BUTTON_LIST:
    case StepTypes.INPUT_VALUE_LIST:
    case StepTypes.INPUT_INDEX_LIST:
    case StepTypes.INPUT_CHECKBOX_LIST:
    case StepTypes.INPUT_TILE_LIST:
      updatedStep.source = getListOptionsSource(updatedStep);
      break;
    case StepTypes.INPUT_STEP_MENU:
      // StepMenu has only list with options
      updatedStep.source = ListOptionsSource.OPTIONS;
      break;
    case StepTypes.INFO_PHOTO:
      if (!updatedStep.styles?.image?.scaleType) {
        updatedStep.styles = { image: { scaleType: ImageScaleType.FILL } };
      }
      break;
    default:
      break;
  }

  return updatedStep;
};

const normalizeSteps = (steps: WorkinstructionStep[]): WorkinstructionStep[] => {
  const normalizedSteps: WorkinstructionStep[] = steps
    .filter((step) => step.type !== StepTypes.INFO_EMPTY)
    .map((step: WorkinstructionStep, _stepIndex, allSteps) => normalizeStep(step, allSteps))
    .sort((step1, step2) => {
      if (step1.index === undefined) {
        return step2.index === undefined ? 0 : -1;
      }
      if (step2.index === undefined) {
        return 1;
      }
      return step1.index - step2.index;
    });

  return normalizedSteps.length > 0
    ? normalizedSteps
    : [generateWorkinstructionStep({ type: StepTypes.INFO_TEXT, workflowType: WiWorkflowType.PRIMARY })];
};

export function normalizeWorkinstruction(workinstruction: WorkinstructionGET): Workinstruction;
export function normalizeWorkinstruction(workinstruction: Partial<WorkinstructionGET>): Partial<Workinstruction>;
export function normalizeWorkinstruction(workinstruction: Partial<WorkinstructionGET>): Partial<Workinstruction> {
  const wiSteps = workinstruction.steps || [];
  const orderedSteps = normalizeSteps(wiSteps);

  const initialStepId = wiSteps.find((step) => step.type === StepTypes.INFO_EMPTY)
    ? orderedSteps[0].id
    : workinstruction.initialStepId;

  return {
    id: workinstruction._id,
    name: workinstruction.name,
    description: workinstruction.description,
    createdAt: workinstruction.meta?.createdAt,
    updatedAt: workinstruction.meta?.updatedAt,
    lastUsedAt: workinstruction.lastUsedAt,
    priority: workinstruction.priority,
    projectId: workinstruction.project,
    initialStepId,
    showAsApp: workinstruction.isApp || false,
    visibleWhenInProgress: workinstruction.visibleWhenInProgress || false,
    isEvent: workinstruction.isEvent || false,
    rolesIds: workinstruction.roleIds || [],
    skillIds: workinstruction.skillIds || [],
    steps: orderedSteps,
    maxTime: workinstruction.maxTime,
    icon: workinstruction.icon,
    abortReasons: workinstruction.abortReasons?.map((abortReason) => abortReason.text) || [],
    myWork: {
      isExcluded: workinstruction.myWork?.isExcluded || false,
      title: workinstruction.myWork?.title || null,
      description: workinstruction.myWork?.description || null,
      maxInprogressTime: workinstruction.myWork?.maxInprogressTime || null,
    },
    links: workinstruction.links,
    sidebar: workinstruction.sidebar,
    connectedTo: workinstruction.connectedTo || [],
  };
}

export function convertWorkinstructionToWorkinstructionWizard(workinstruction: Workinstruction): WorkinstructionWizard {
  return {
    ...workinstruction,
    steps: workinstruction.steps.map((step) => {
      if ([StepTypes.INPUT_VALUE_LIST, StepTypes.INPUT_TILE_LIST].includes(step.type)) {
        const updatedStep = step as InputValueListBody | InputTileListBody;
        // customNextStep can be undefined for old workinstructions
        updatedStep.customNextStep = !!updatedStep.customNextStep; // convert undefined to false

        return updatedStep;
      }

      return step;
    }),
    devicesCompatibility: {
      [Devices.WATCH]: true,
      [Devices.PHONE]: true,
      [Devices.TABLET]: true,
    },
  };
}

const buildStepsFromWorkinstructionWizard = (workinstruction: WorkinstructionWizard): WorkinstructionStep[] => {
  if (workinstruction.isEvent) {
    return [
      {
        _id: ObjectId(),
        type: StepTypes.INFO_EMPTY,
        id: INFO_EMPTY_ID,
        title: INFO_EMPTY_ID,
        buttons: [],
      },
    ];
  }

  const result = workinstruction.steps.map((step, index) => {
    const convertedStep = cloneDeep(step);
    switch (convertedStep.type) {
      case StepTypes.INPUT_NUMBER:
      case StepTypes.INPUT_NUMBER_PICKER:
        if (convertedStep.defaultValueDecimal?.length === 0) {
          delete convertedStep.defaultValueDecimal;
        }
        break;
      case StepTypes.INFO_PDF: {
        convertedStep.page = String(Number(convertedStep.page) - 1); // backend counts 'page' starting at '0'
      }
    }
    return {
      ...convertedStep,
      index,
    };
  });

  return result;
};

export const convertWorkinstructionWizardToWorkinstructionPOST = (
  workinstruction: WorkinstructionWizard,
): WorkinstructionPOST => ({
  description: workinstruction.description,
  isApp: workinstruction.showAsApp,
  visibleWhenInProgress: workinstruction.showAsApp && workinstruction.visibleWhenInProgress,
  initialStepId: workinstruction.isEvent ? 'infoempty' : workinstruction.initialStepId,
  isEvent: workinstruction.isEvent,
  name: workinstruction.name,
  steps: buildStepsFromWorkinstructionWizard(workinstruction),
  project: workinstruction.projectId,
  priority: Number(workinstruction.priority),
  skillIds: workinstruction.skillIds ?? [],
  roleIds: workinstruction.rolesIds, // Maybe we need to send this only if isApp is true
  maxTime: workinstruction.maxTime,
  icon: workinstruction.showAsApp ? workinstruction.icon : undefined,
  abortReasons: workinstruction.abortReasons.map((abortReason) => ({
    text: abortReason,
  })),
  myWork: workinstruction.myWork,
  sidebar: { buttons: workinstruction.sidebar?.buttons ?? [] },
  connectedTo: workinstruction.showAsApp ? workinstruction.connectedTo : undefined,
});

export const convertWorkinstructionWizardToWorkinstructionPUT = (
  workinstruction: WorkinstructionWizard,
): WorkinstructionPUT => ({
  ...convertWorkinstructionWizardToWorkinstructionPOST(workinstruction),
  _id: workinstruction.id,
});
