import { Box, Tabs, Tab, Stack } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

export interface FormBuilderSettingsTabsWrapperProps {
  header?: React.ReactNode;
  general?: React.ReactNode;
  displayOptions?: React.ReactNode;
  flaggingCriteria?: React.ReactNode;
}

export const FormBuilderSettingsTabsWrapper: React.FC<FormBuilderSettingsTabsWrapperProps> = ({
  header,
  general,
  displayOptions,
  flaggingCriteria,
}) => {
  const [tab, setTab] = React.useState(0);
  const intl = useIntl();

  const showTabByIndex = (tabIndex: number) => (tab === tabIndex ? 'block' : 'none');

  return (
    <Stack>
      {header}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)}>
          <Tab label={intl.formatMessage({ id: 'document.builder.tabs.general' })} value={0} />
          {displayOptions ? (
            <Tab label={intl.formatMessage({ id: 'document.builder.tabs.display-options' })} value={1} />
          ) : null}
          {flaggingCriteria ? (
            <Tab label={intl.formatMessage({ id: 'document.builder.tabs.flagging-criteria' })} value={2} />
          ) : null}
        </Tabs>
      </Box>
      <Box>
        <Box sx={{ mt: 1.5, display: showTabByIndex(0) }}>{general}</Box>
        <Box sx={{ mt: 1.5, display: showTabByIndex(1) }}>{displayOptions}</Box>
        <Box sx={{ mt: 1.5, display: showTabByIndex(2) }}>{flaggingCriteria}</Box>
      </Box>
    </Stack>
  );
};
