// ISO 639-1 language codes
export enum Languages {
  AR = 'ar',
  BG = 'bg',
  CS = 'cs',
  DA = 'da',
  DE = 'de',
  EL = 'el',
  EN = 'en',
  ES = 'es',
  ET = 'et',
  FI = 'fi',
  FR = 'fr',
  HE = 'he',
  HI = 'hi',
  HR = 'hr',
  HU = 'hu',
  ID = 'id',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  LT = 'lt',
  NL = 'nl',
  NO = 'no',
  PL = 'pl',
  PT = 'pt',
  RO = 'ro',
  RU = 'ru',
  SL = 'sl',
  SV = 'sv',
  UK = 'uk',
  ZH = 'zh',
}
