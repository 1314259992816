import React from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FCWithChildren } from '@types';
import { handleRequestError } from '@redux/common/sagas';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {
      // when using useMutation if onError callback is not defined, this will be called by default
      onError: (error) => {
        handleRequestError({ payload: { error } });
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      // useQuery error will always call handleRequestError, if we ever want to block this behavior, use query.meta
      // https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#defining-on-demand-messages
      handleRequestError({ payload: { error, customMessage: query?.meta?.customMessage } });
    },
  }),
});

export const QueryProvider: FCWithChildren = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
