import React from 'react';
import { useIntl } from 'react-intl';
import { get, isString } from 'lodash';
import { Stack, Grid, SxProps, Theme } from '@mui/material';
import { GroupSelectInput, SelectInput } from 'components/Input/SelectInput';
import SwitchWithLabel, { SwitchPosition } from 'components/Input/SwitchWithLabel/SwitchWithLabel';
import TextInput from 'components/Input/TextInput';
import { ComponentOption, FieldType } from './types';

import styles from './ItemsList.module.scss';

export { Option } from 'components/Input/SelectInput';

interface Props<T> {
  // We could add more typing here to enforce the key of
  // Input component to be in the keys of values. But this adds lot of complexity
  // and brings little value (the more important typing is the current one below)
  components: ComponentOption[];
  values: T;
  onChange: (newValue: T) => void;
  sx?: SxProps<Theme>;
  direction?: 'row' | 'column';
}

export const ItemsList = <T = unknown,>({ components, values, onChange, sx, direction = 'row' }: Props<T>) => {
  const intl = useIntl();

  const createComponent = (component: ComponentOption) => {
    const value = get(values, component.key);
    const updateValueForKey = (value: string | boolean, key: string) => {
      const newValues = { ...values };
      newValues[key] = value;
      return newValues;
    };
    switch (component.type) {
      case FieldType.OUTPUT_TEXT:
        return (
          <Grid item xs key={component.key}>
            <div className={styles.textField}>{component.text}</div>
          </Grid>
        );
      case FieldType.INPUT_TEXT:
        return (
          <Grid item xs key={component.key}>
            <TextInput
              error={component.error}
              description={component.description}
              type="text"
              placeholder={component.placeholder}
              variablesAutocompleteEnabled={component.variablesAutocompleteEnabled}
              disabled={component.isDisabled || false}
              field={{
                name: component.key,
                value,
                onChange: (event) => {
                  onChange(updateValueForKey(event.target.value, component.key));
                },
                onBlur: component.onBlur,
              }}
            />
            {component.error && (
              <span className={styles.error}>
                {intl.formatMessage({ id: component.error, defaultMessage: component.error })}
              </span>
            )}
          </Grid>
        );
      case FieldType.INPUT_SELECT:
        return (
          <Grid item xs key={component.key}>
            <SelectInput
              options={component.options}
              isDisabled={!!component.isDisabled}
              field={{
                name: component.key,
                value,
                onChange: (value) => {
                  const newValue = isString(value) ? value : value[0];
                  onChange(updateValueForKey(newValue, component.key));
                },
              }}
            />
          </Grid>
        );
      case FieldType.INPUT_GROUP_SELECT:
        return (
          <Grid item xs key={component.key}>
            <GroupSelectInput
              options={component.options}
              isDisabled={!!component.isDisabled}
              isSearchable={component.isSearchable}
              field={{
                name: component.key,
                value,
                onChange: (groupValue) => {
                  const newValue = groupValue?.values || null;
                  if (newValue) {
                    onChange(updateValueForKey(newValue, component.key));
                  }
                },
              }}
            />
          </Grid>
        );
      case FieldType.INPUT_CHECKBOX:
        return (
          <div className={styles.checkBox} key={component.key}>
            <SwitchWithLabel
              labelTranslationId={component.labelTranslationId}
              checked={component.isChecked}
              switchPosition={SwitchPosition.LEFT}
              onChange={(newValue) => {
                onChange(component.updateComponent(newValue, values) as T);
              }}
            />
          </div>
        );
      default:
        return undefined;
    }
  };

  return (
    <Stack direction={direction} gap={direction === 'row' ? 1 : 0.5} sx={sx} flexWrap="wrap">
      {components.map((component) => (
        <React.Fragment key={component.key}>{createComponent(component)}</React.Fragment>
      ))}
    </Stack>
  );
};
