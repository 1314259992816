import { SortOrder } from '@workerbase/domain/common';
import { GraphQLQueryVariables } from '@workerbase/types/graphql/GraphQLQueryVariables';

export enum GroupOperation {
  COUNT = 'COUNT',
}

export enum GraphqlArgsTypeNames {
  BOOLEAN = 'Boolean',
  INT = 'Int',
  STRING = 'String',
  SORT_ORDER = 'GraphQLSortOrder',
  FILTER = 'Filter',
  GROUP = 'GroupArgs',
}

export interface ListArgs {
  perpage?: number;
  page?: number;
  sort?: string;
  order?: SortOrder;
  filter?: GraphQLQueryVariables;
  group?: {
    key: string;
    operation: GroupOperation;
  };
  textSearch?: string;
  preciseCount?: boolean;
}

export interface PageInfo {
  page: number;
  perpage: number;
  sort: string;
  order: SortOrder;
  maxpage: number;
  totalItems: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GraphQLResponseBody<Data = any> {
  data: Data;
  errors?: GraphQLResponseBodyError[];
}

export interface GraphQLListResponse<Item> {
  edges: Item[];
  pageInfo: PageInfo;
}

export interface GraphQLResponseBodyError {
  message: string;
}

export interface GraphQLGroupResponse {
  groupedEdges: { _id: string; value: number }[];
}
