import { INotifyLocationValueLocation } from '@workerbase/domain/notification';

export enum FERecipientTypes {
  ROLES = 'roles',
  USER = 'user',
  VARIABLES = 'variables',
  CALLER = 'caller',
}

export interface RecipientRoles {
  type: FERecipientTypes.ROLES;
  roles: string[];
}
export interface RecipientUser {
  type: FERecipientTypes.USER;
  isAssignedUserVar: false;
  assignedUser: string;
}

export interface RecipientVariables {
  type: FERecipientTypes.VARIABLES;
  variables: string;
}

export interface RecipientCaller {
  type: FERecipientTypes.CALLER;
  selfinstruction: true;
}

export type RecipientWithoutCaller = RecipientRoles | RecipientUser | RecipientVariables;

export type Recipient = RecipientRoles | RecipientUser | RecipientVariables | RecipientCaller;

// If isAssignedUserVar = true, the location related keys are not needed
/**
 * @deprecated
 */
export interface RecipientPOST extends NotifyLocationPOST {
  roles?: string[]; // roleIds
  assignedUser?: string;
  isAssignedUserVar?: boolean;
  assignedUserVar?: string[];
  isUserMessage?: boolean;
  isUserTask?: boolean;
  selfinstruction: boolean;
}

export interface NotifyLocationPOST {
  notifyLocations?: INotifyLocationValueLocation[];
  notifyLocationIsVar?: boolean;
  notifyLocationVar?: string;
}
