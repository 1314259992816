import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import Field, { FieldProps } from './Field';

type FormikToggleProps = Omit<FieldProps, 'onToggle' | 'isEnabled' | 'fieldName'> & {
  name: string;
  mapOutgoingValue?: (value: boolean) => string | boolean | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- can be of any type
  mapIncomingValue?: (value: any) => boolean;
  onToggleCallback?: (val: boolean) => void;
};

export const FormikToggle: FC<FormikToggleProps> = ({
  name,
  mapOutgoingValue,
  mapIncomingValue,
  onToggleCallback,
  ...props
}) => {
  const { setFieldValue, values } = useFormikContext();
  const value = get(values, name);

  return (
    <Field
      {...props}
      fieldName={name}
      isEnabled={mapIncomingValue ? mapIncomingValue(value) : !!value}
      onToggle={(val) => {
        setFieldValue(name, mapOutgoingValue ? mapOutgoingValue(val) : val);
        onToggleCallback?.(val);
      }}
    />
  );
};
