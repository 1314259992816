export enum DatabaseColumnTypes {
  STRING = 'String',
  STRING_ARRAY = 'StringArray',
  INTEGER = 'Integer',
  DOUBLE = 'Double',
  LOCATION = 'Location',
  LOCATION_ARRAY = 'LocationArray',
  BOOLEAN = 'Boolean',
  DATETIME = 'DateTime',
  DATE = 'Date',
  TIME = 'Time',
  USER = 'User',
  USER_ARRAY = 'UserArray',
  ROLE = 'Role',
  // ROLE_ARRAY = 'RoleArray',
  SKILL = 'Skill',
  // SKILL_ARRAY = 'SkillArray',
  MEDIA = 'Media',
  MEDIA_ARRAY = 'MediaArray',
  REFERENCE = 'Reference',
  ENUM = 'Enum',
  // REFERENCE_ARRAY = 'ReferenceArray',
}
