import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { loader } from '@monaco-editor/react';
import { init as initSentry, Event, replayIntegration } from '@sentry/react';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import App from './App';
import configureStore, { history } from './redux/configureStore';
import { ToastUtils } from './utils/ToastUtils';
import { SentryUtils } from './utils/SentryUtils';

LicenseInfo.setLicenseKey(
  '716bb493d35ee3176705426a03ec250cTz04NDQzMSxFPTE3Mzk2OTQwNzUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

if (SENTRY_DSN) {
  console.warn('Sentry Error Reporting is enabled');
  initSentry({
    dsn: SENTRY_DSN,
    release: `frontend@${VERSION}`,
    environment: window.location.hostname,
    attachStacktrace: true,
    autoSessionTracking: true,
    integrations: [replayIntegration({ maskAllText: false })],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    beforeSend(event: Event) {
      if (!SentryUtils.shouldIgnoreEvent(event)) {
        ToastUtils.showToastWithErrorReportingId(SentryUtils.getMessageFromEvent(event), event.event_id);
      }
      return event;
    },
  });
}

loader.config({ paths: { vs: '/monaco-editor/vs' } });
export const store = configureStore();

const root = createRoot(document.getElementById('root')!);
root.render(<App store={store} history={history} />);
