import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Routes } from '../routes';

const RAL_STORAGE_KEY = 'redirectAfterLogin';
const getRedirectAfterLoginPath = () => sessionStorage.getItem(RAL_STORAGE_KEY);
const clearRedirectAfterLoginPath = () => {
  sessionStorage.removeItem(RAL_STORAGE_KEY);
};
export const useRedirectAfterLogin = () => {
  const isValidRoute = !!useRouteMatch({ path: Object.values(Routes) });
  const location = useLocation();
  const history = useHistory();

  /**
   * Set the path to redirect to after successful login.
   * @param path - The path to redirect to after successful login.
   * If the path is not a valid route, the path will not be set.
   * If the path is not provided, the current path will be set.
   */
  const setRedirectAfterLoginPath = useCallback(
    (path?: string) => {
      if (!isValidRoute) {
        clearRedirectAfterLoginPath();
        console.warn(`Invalid route to redirect to after successful login: ${path}`);

        return;
      }
      const attemptedPath = location.pathname + location.search + location.hash;

      sessionStorage.setItem(RAL_STORAGE_KEY, attemptedPath);
    },
    [isValidRoute, location.hash, location.pathname, location.search],
  );

  /**
   * Intended to be used in the react component that handles the protected route check
   */
  const redirectAfterLoginCheck = useCallback(() => {
    const redirectAfterLoginPath = getRedirectAfterLoginPath();
    if (redirectAfterLoginPath) {
      clearRedirectAfterLoginPath();
      history.push(redirectAfterLoginPath);
      return null;
    }
  }, [history]);

  const memoizedValues = useMemo(
    () => ({
      setRedirectAfterLoginPath,
      getRedirectAfterLoginPath,
      clearRedirectAfterLoginPath,
      redirectAfterLoginCheck,
    }),
    [redirectAfterLoginCheck, setRedirectAfterLoginPath],
  );

  return memoizedValues;
};
