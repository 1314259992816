import { Languages } from '@workerbase/domain/common';
import { BaseAction } from '../common/actions';

export enum SettingsActions {
  GET_LANGUAGES = '@@settings/GET_LANGUAGES',
  GET_LANGUAGES_SUCCESS = '@@settings/GET_LANGUAGES_SUCCESS',
  UPDATE_LANGUAGES = '@@settings/UPDATE_LANGUAGES',
  UPDATE_LANGUAGES_SUCCESS = '@@settings/UPDATE_LANGUAGES_SUCCESS',
}

export const getLanguagesAction = (): BaseAction => ({
  type: SettingsActions.GET_LANGUAGES,
  payload: {},
});

export const getLanguagesSuccessAction = (languages: Languages[]): BaseAction => ({
  type: SettingsActions.GET_LANGUAGES_SUCCESS,
  payload: {
    languages,
  },
});

export const updateLanguagesAction = (languages: Languages[]): BaseAction => {
  const result = {
    type: SettingsActions.UPDATE_LANGUAGES,
    payload: {
      languages,
    },
  };
  return result;
};

export const updateLanguagesSuccessAction = (languages: Languages[]): BaseAction => ({
  type: SettingsActions.UPDATE_LANGUAGES_SUCCESS,
  payload: {
    languages,
  },
});
