import { isEmpty } from 'lodash';
import { FilterCondition, FilterGroupOperator, FilterTypes, IFilter, isFilterGroup } from '@workerbase/domain/common';
import { ComparingConditionOperator, ConditionOperator, DirectConditionOperator } from '@workerbase/domain/rule';
import { AvailableCondition } from '@workerbase/types/RuleCondition/AvailableCondition';
import { GraphQLGroupOperators, GraphQLOperators } from '@workerbase/types/graphql/GraphQLOperators';
import { GraphQLValue } from '@workerbase/types/graphql/GraphQLValue';
import { GraphQLQueryVariables } from '@workerbase/types/graphql/GraphQLQueryVariables';
import { ConditionValueTypes } from '@workerbase/types/RuleCondition/ConditionValuesTypes';
import { excludeInvalidConditions } from './excludeInvalidConditions';

const EMPTY_VALUES = [null, ''];

const getConditionFromMapper: Record<ConditionOperator, (key: string, value?: GraphQLValue) => GraphQLQueryVariables> =
  {
    [ComparingConditionOperator.EQUAL]: (key, value) => ({ [key]: { [GraphQLOperators.EQ]: value } }),
    [ComparingConditionOperator.DOES_NOT_EQUAL]: (key, value) => ({ [key]: { [GraphQLOperators.NE]: value } }),
    [ComparingConditionOperator.GREATER_THAN]: (key, value) => ({ [key]: { [GraphQLOperators.GT]: value } }),
    [ComparingConditionOperator.GREATER_OR_EQUAL]: (key, value) => ({ [key]: { [GraphQLOperators.GTE]: value } }),
    [ComparingConditionOperator.LESS_THAN]: (key, value) => ({ [key]: { [GraphQLOperators.LT]: value } }),
    [ComparingConditionOperator.LESS_OR_EQUAL]: (key, value) => ({ [key]: { [GraphQLOperators.LTE]: value } }),
    [ComparingConditionOperator.CONTAINS]: (key, value) => ({
      [key]: { [GraphQLOperators.REGEX]: `.*${value}.*` },
    }),
    [ComparingConditionOperator.DOES_NOT_CONTAIN]: () => {
      throw new Error('Operator cannot be used');
    },
    [ComparingConditionOperator.BEGINS]: (key, value) => ({
      [key]: { [GraphQLOperators.REGEX]: `${value}.*` },
    }),
    [ComparingConditionOperator.DOES_NOT_BEGIN]: () => {
      throw new Error('Operator cannot be used');
    },
    [ComparingConditionOperator.ENDS]: (key, value) => ({
      [key]: { [GraphQLOperators.REGEX]: `.*${value}` },
    }),
    [ComparingConditionOperator.DOES_NOT_END]: () => {
      throw new Error('Operator cannot be used');
    },
    [ComparingConditionOperator.REGEX]: (key, value) => ({
      [key]: { [GraphQLOperators.REGEX]: value },
    }),
    [ComparingConditionOperator.MATCHES]: () => {
      throw new Error('Deprecated operator. Use regex instead');
    },
    [ComparingConditionOperator.DOES_NOT_MATCH]: () => {
      throw new Error('Deprecated operator. Use regex instead');
    },
    [DirectConditionOperator.IS_CHECKED]: () => {
      throw new Error('Operator cannot be used');
    },
    [DirectConditionOperator.IS_NOT_CHECKED]: () => {
      throw new Error('Operator cannot be used');
    },
    [DirectConditionOperator.EMPTY]: (key) => ({ [key]: { [GraphQLOperators.IN]: EMPTY_VALUES } }),
    [DirectConditionOperator.IS_NOT_EMPTY]: (key) => ({ [key]: { [GraphQLOperators.NIN]: EMPTY_VALUES } }),
    [DirectConditionOperator.TRUE]: (key) => ({ [key]: { [GraphQLOperators.EQ]: true } }),
    [DirectConditionOperator.FALSE]: (key) => ({ [key]: { [GraphQLOperators.EQ]: false } }),
    [ComparingConditionOperator.EQUALS_VAR]: () => {
      throw new Error('Operator cannot be used');
    },
    [DirectConditionOperator.EXISTS]: (key) => ({ [key]: { [GraphQLOperators.EXISTS]: true } }),
    [DirectConditionOperator.DOES_NOT_EXIST]: (key) => ({ [key]: { [GraphQLOperators.EXISTS]: false } }),
  };

const GroupTypeToGraphQLSelector: Record<FilterGroupOperator, GraphQLGroupOperators> = {
  [FilterGroupOperator.AND]: GraphQLOperators.AND,
  [FilterGroupOperator.OR]: GraphQLOperators.OR,
};

const mapper = <Conditions extends ConditionOperator>(
  condition: FilterCondition<Conditions>,
): GraphQLQueryVariables => {
  if (condition.type === FilterTypes.CONDITION) {
    return getConditionFromMapper[condition.operator](condition.name, condition.value);
  }

  // Ignore Groups with empty conditions
  if (!condition.conditions || !condition.conditions.length) {
    return {};
  }

  return {
    [GroupTypeToGraphQLSelector[condition.groupOperator]]: condition.conditions.map((condition) =>
      mapper<Conditions>(condition),
    ),
  };
};

const imposeCoercionOfFilterValueByType = <Conditions extends ConditionOperator>(
  filters: IFilter<Conditions>,
  availableConditions: AvailableCondition[],
) => {
  const coercedFilter = { ...filters, conditions: [...filters.conditions] };

  coercedFilter.conditions = coercedFilter.conditions.map((condition) => {
    if (isFilterGroup(condition)) {
      return imposeCoercionOfFilterValueByType(condition, availableConditions);
    }

    const conditionWithAllFields = availableConditions?.find(
      (availableCondition) => availableCondition.value === condition.name,
    );
    if (conditionWithAllFields?.type === ConditionValueTypes.NUMBER) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - we know that value should be a number in this case - types to be refactored
      condition.value = Number.parseInt(condition.value, 10);
    }

    return condition;
  });

  return coercedFilter;
};

export const mapFilterToGraphQLQuery = <Conditions extends ConditionOperator>(
  filter: IFilter<Conditions>,
  conditions?: AvailableCondition[],
): GraphQLQueryVariables | undefined => {
  const prunedFilter = excludeInvalidConditions(filter);
  const coercedFilter = conditions ? imposeCoercionOfFilterValueByType(prunedFilter, conditions) : prunedFilter;
  const mappedFilter = mapper(coercedFilter);

  return !isEmpty(mappedFilter) ? mappedFilter : undefined;
};
