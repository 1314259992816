import { StepTypes } from '../StepTypes.enum';
import { IStep } from '../Step.interface';
import { ILayoutAssemblyPictureStep } from './LayoutAssemblyPictureStep.interface';
import { ILayoutAssemblyVideoStep } from './LayoutAssemblyVideoStep.interface';
import { ILayoutAssemblyDetailStep } from './LayoutAssemblyDetailStep.interface';
import { ILayoutScrewFittingStep } from './LayoutScrewFittingStep.interface';

const LAYOUT_TEMPLATE_STEPS = [
  StepTypes.LAYOUT_ASSEMBLY_PICTURE,
  StepTypes.LAYOUT_ASSEMBLY_VIDEO,
  StepTypes.LAYOUT_ASSEMBLY_DETAIL,
  StepTypes.LAYOUT_SCREW_FITTING,
] as const; // as const is required for creating union type down below

// gives a union type from array
export type LayoutTemplateStepTypes = (typeof LAYOUT_TEMPLATE_STEPS)[number];

export type LayoutTemplateSteps =
  | ILayoutAssemblyPictureStep
  | ILayoutAssemblyVideoStep
  | ILayoutAssemblyDetailStep
  | ILayoutScrewFittingStep;

export const isLayoutTemplateStep = (step: IStep): step is LayoutTemplateSteps =>
  (LAYOUT_TEMPLATE_STEPS as any as StepTypes[]).includes(step.type);
