import { colors, fontFamily, fontSize, fontWeight } from '../../../stylesheet';
import { Option } from './types/Option';

export const getStyles = (error: boolean, highlightCondition?: (data: Option) => boolean) => ({
  container: (styles) => ({
    ...styles,
    fontFamily: fontFamily.textRegularFontFamily,
    fontSize: fontSize.textRegularFontSize,
    fontWeight: fontWeight.textSemiboldFontWeight,
    minHeight: '40px',
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    minHeight: '40px',
    boxSizing: 'border-box',
    ...(error && !isFocused ? { borderColor: colors.R200 } : {}),
  }),
  multiValue: (styles) => {
    const color = colors.B100;
    return {
      ...styles,
      backgroundColor: color,
      borderRadius: '4px',
      padding: '2px 12px',
    };
  },
  menu: (styles, state) => ({
    ...styles,
    zIndex: 5, // It prevents it from being under the react-mention (like on the rule page)
  }),
  option: (styles, { data, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor:
      highlightCondition && highlightCondition(data) && !isFocused && !isSelected ? colors.N50 : styles.backgroundColor,
  }),
  multiValueRemove: (styles, state) =>
    state.data.isFixed
      ? { ...styles, display: 'none' }
      : {
          ...styles,
          color: state.isFocused ? colors.R200 : colors.N500,
          backgroundColor: colors.B100,
        },
});

export const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral20: colors.N300,
  },
});
