import { AnyAction } from 'redux';

import {
  ListConfigFiltering,
  ListConfigPagination,
  ListConfigSorting,
  ListConfigTabs,
} from '@workerbase/api/http/user';
import { TasksListConfig } from '@workerbase/api/http/task';
import { TaskCategoryKeys } from '@workerbase/types/TaskCategoryKeys';
import { PaginationMeta } from '@workerbase/types/Response';

import { Task, CategoryOptions } from 'services/types/Task';
import { TasksActions } from './actions';
import { UpdateListConfigActionPayload } from '../common/ListConfig/actions';
import {
  updatePagination,
  updateSorting,
  updateFiltering,
  updateSelectedTabKey,
  updateStateTabs,
  updateListConfig,
} from '../common/ListConfig/reducers';

export type TasksState = Readonly<{
  tasksById: { [key: string]: Task };
  listConfigs: TasksListConfig;
  currentListItemsIds: string[];
  errorMessage: string | null;
  projectSubscribed: string | null;
  isLoading: boolean;
}>;

export const initialState: TasksState = {
  tasksById: {},
  listConfigs: {
    pagination: { currentPage: 1, itemsPerPage: 20 },
    filtering: {
      searchTerms: '',
    },
    tabs: {
      selectedTabKey: 'open',
      categoryKey: TaskCategoryKeys.STATUS,
      options: CategoryOptions[TaskCategoryKeys.STATUS],
      categories: Object.values(TaskCategoryKeys),
    },
  },
  currentListItemsIds: [],
  errorMessage: null,
  projectSubscribed: null,
  isLoading: false,
};

interface GetTasksSuccessActionPayload {
  tasks: (Partial<Task> & { id })[];
  meta: PaginationMeta;
}

interface GetTaskSuccessActionPayload {
  task: Partial<Task> & { id };
}

interface GetTasksFailureActionPayload {
  errorMessage: string;
}

// eslint-disable-next-line complexity
const reducer = (state: TasksState = initialState, action: AnyAction): TasksState => {
  switch (action.type) {
    case TasksActions.GET_TASKS_REQUEST: {
      if (action.payload.forceLoading) {
        return { ...state, isLoading: true };
      }
      return state;
    }
    case TasksActions.GET_TASKS_SUCCESS: {
      const payload = action.payload as GetTasksSuccessActionPayload;

      const tasksById = payload.tasks?.reduce((prev, task) => {
        const updatedTasks = prev;
        updatedTasks[task.id] = task;
        return prev;
      }, {});

      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          ...tasksById,
        },
        listConfigs: {
          ...state.listConfigs,
          pagination: {
            ...state.listConfigs.pagination,
            currentPage: payload.meta.page,
            itemsPerPage: payload.meta.perpage,
            totalItems: payload.meta.totalItems,
          },
          tabs: updateStateTabs(
            initialState.listConfigs.tabs,
            state.listConfigs.tabs,
            payload.meta.category,
            payload.meta.categoryKey || TaskCategoryKeys.NO_KEY,
            payload.meta.stats,
          ),
        },
        isLoading: false,
        errorMessage: null,
        currentListItemsIds: payload.tasks?.map((task) => task.id) || state.currentListItemsIds,
      };
    }
    case TasksActions.GET_TASK_BY_ID_SUCCESS: {
      const payload = action.payload as GetTaskSuccessActionPayload;
      const fetchedTask = payload.task;
      const task = {
        [fetchedTask.id]: fetchedTask,
      };

      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          ...task,
        },
      };
    }
    case TasksActions.DELETE_TASK_BY_ID_SUCCESS: {
      const taskId: string = action.payload.taskId;

      const tasksById = { ...state.tasksById };
      if (tasksById[taskId]) {
        delete tasksById[taskId];
      }

      return {
        ...state,
        tasksById,
        currentListItemsIds: state.currentListItemsIds.filter((itemId) => itemId !== taskId),
      };
    }
    case TasksActions.DELETE_TASK_BY_ID_FAILURE: {
      const payload = action.payload as GetTasksFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
        isLoading: false,
      };
    }
    case TasksActions.GET_TASKS_FAILURE: {
      const payload = action.payload as GetTasksFailureActionPayload;
      return {
        ...state,
        errorMessage: payload.errorMessage,
        isLoading: false,
        currentListItemsIds: [],
      };
    }
    case TasksActions.UPDATE_PAGINATION: {
      const payload = action.payload as ListConfigPagination;
      return updatePagination(state, payload);
    }
    case TasksActions.UPDATE_SORTING: {
      const payload = action.payload as ListConfigSorting;
      return updateSorting(state, payload);
    }
    case TasksActions.UPDATE_FILTERING: {
      const payload = action.payload as ListConfigFiltering;
      return updateFiltering(state, payload);
    }
    case TasksActions.UPDATE_SELECTED_TAB_KEY: {
      const payload = action.payload as ListConfigTabs;
      return updateSelectedTabKey(state, payload);
    }
    case TasksActions.MQTT_SUBSCRIBE:
      return {
        ...state,
        projectSubscribed: action.payload.projectSubscribed,
      };
    case TasksActions.MQTT_UNSUBSCRIBE:
      return {
        ...state,
        projectSubscribed: null,
      };
    case TasksActions.UPDATE_CATEGORY_KEY: {
      const { category } = action.payload;
      const options = CategoryOptions[category as TaskCategoryKeys] || [];
      return {
        ...state,
        listConfigs: {
          ...state.listConfigs,
          tabs: {
            categories: Object.values(TaskCategoryKeys),
            categoryKey: action.payload.category,
            options,
            selectedTabKey: options[0]?.key || '',
          },
        },
      };
    }
    case TasksActions.UPDATE_LIST_CONFIG: {
      const payload = action.payload as UpdateListConfigActionPayload;
      return updateListConfig(state, payload);
    }

    default:
      return state;
  }
};

export default reducer;
