import { updateListConfigSaga } from '@redux/common/ListConfig/sagas';
import { shouldDisplayLinks } from '@workerbase/domain/user';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createWorkinstruction,
  deleteWorkinstructionById,
  getWorkinstructionById,
  getWorkinstructions,
  updateWorkinstructionById,
} from 'services/networking/workinstructions';
import { PaginationMeta } from '@workerbase/types/Response';
import { Workinstruction } from 'services/types/Workinstruction';
import { handleRequestError } from '../common';
import {
  CreateWorkinstructionRequestAction,
  deleteWorkinstructionByIdError,
  deleteWorkinstructionByIdSuccess,
  getWorkinstructionByIdError,
  getWorkinstructionByIdSuccess,
  getWorkinstructionsError,
  getWorkinstructionsSuccess,
  UpdateWorkinstructionByIdRequestAction,
  WorkinstructionsActions,
  getWorkinstructionsRequestAction,
} from './actions';
import { getListConfigs, getWorkinstructionWithId } from './selectors';

export function* getWorkinstructionsRequestSaga(action) {
  try {
    const listConfigs = yield select(getListConfigs);
    const projectId = action.payload.projectId;

    const response = yield call(getWorkinstructions, {
      projectId,
      page: listConfigs.pagination.currentPage,
      perPage: listConfigs.pagination.itemsPerPage,
      sorting: listConfigs.sorting,
      filtering: listConfigs.filtering.searchTerms,
      aggregateLinks: shouldDisplayLinks(listConfigs),
    });

    const workinstructions: Workinstruction[] = response.data;
    const meta: PaginationMeta = response.meta;

    yield put(getWorkinstructionsSuccess(workinstructions, meta));
  } catch (error) {
    yield put(getWorkinstructionsError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* getWorkinstructionByIdRequestSaga(action) {
  try {
    const workinstruction: Workinstruction = yield call(getWorkinstructionById, action.payload.workinstructionId);

    yield put(getWorkinstructionByIdSuccess(workinstruction));
  } catch (error) {
    yield put(getWorkinstructionByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* createWorkinstructionRequestSaga(action: CreateWorkinstructionRequestAction) {
  try {
    const workinstruction: Workinstruction = yield call(createWorkinstruction, action.payload.workinstruction);
    yield put(getWorkinstructionByIdSuccess(workinstruction));
    yield put(push(`/projects/${workinstruction.projectId}/workinstructions`));
    yield call(toast.success, 'Workinstruction created');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export function* deleteWorkinstructionByIdRequestSaga(action) {
  try {
    const { workinstructionId } = action.payload;
    const workinstruction: Workinstruction = yield select(getWorkinstructionWithId(workinstructionId));

    yield call(deleteWorkinstructionById, workinstructionId);

    yield call(toast.success, 'Workinstruction deleted');
    yield put(deleteWorkinstructionByIdSuccess(workinstructionId));

    yield put(getWorkinstructionsRequestAction(workinstruction.projectId));
  } catch (error) {
    yield put(deleteWorkinstructionByIdError((error as Error).message));
    yield put(handleRequestError(error));
  }
}

export function* updateWorkinstructionByIdRequestSaga(action: UpdateWorkinstructionByIdRequestAction) {
  try {
    const workinstruction: Workinstruction = yield call(
      updateWorkinstructionById,
      action.payload.workinstructionId,
      action.payload.workinstruction,
    );
    yield put(getWorkinstructionByIdSuccess(workinstruction));
    yield put(push(`/projects/${workinstruction.projectId}/workinstructions`));
    yield call(toast.success, 'Workinstruction updated');
  } catch (error) {
    yield put(handleRequestError(error));
  }
}

export default function* workinstructionsSagas() {
  yield takeLatest(WorkinstructionsActions.GET_WORKINSTRUCTIONS_REQUEST, getWorkinstructionsRequestSaga);
  yield takeLatest(WorkinstructionsActions.CREATE_WORKINSTRUCTION_REQUEST, createWorkinstructionRequestSaga);
  yield takeLatest(WorkinstructionsActions.GET_WORKINSTRUCTION_BY_ID_REQUEST, getWorkinstructionByIdRequestSaga);
  yield takeLatest(WorkinstructionsActions.DELETE_WORKINSTRUCTION_BY_ID_REQUEST, deleteWorkinstructionByIdRequestSaga);
  yield takeLatest(WorkinstructionsActions.UPDATE_WORKINSTRUCTION_BY_ID_REQUEST, updateWorkinstructionByIdRequestSaga);
  yield takeLatest(WorkinstructionsActions.UPDATE_SORTING, updateListConfigSaga);
  yield takeLatest(WorkinstructionsActions.UPDATE_LISTCONFIG_PROPERTIES, updateListConfigSaga);
}
