import { RoleResponse } from '@workerbase/api/http/role';
import { Role } from '../types/Role';

export const normalizeRole = (role: RoleResponse): Role => ({
  id: role._id,
  description: role.description,
  name: role.name,
  apps: role.apps,
  deviceApps: role.deviceApps,
});
