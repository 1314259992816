import React from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getIsAuthenticated, getUser } from '@redux/Login';
import { RootState } from '@redux/types';

import { LoggedUser } from 'services/types/User';
import { AccessRole, hasUserAccessRole } from 'utils/accessRole';
import { useRedirectAfterLogin } from 'hooks/useRedirectAfterLogin';
import { Routes } from '../../routes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- to allow any props
interface LoggedInRouteProps extends Record<string, any> {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- to allow any props
  component: React.ComponentType<any>;
  accessRoles?: [AccessRole, ...AccessRole[]];
  accessDeniedRedirectPath?: Routes;
  accessDeniedToastMessage?: string; // can be a translation id
}

const LoggedInRoute: React.FC<LoggedInRouteProps> = ({
  component: Component,
  computedMatch,
  accessRoles,
  accessDeniedRedirectPath = Routes.Login,
  accessDeniedToastMessage = 'route.access-denied',
  ...otherProps
}) => {
  const intl = useIntl();
  const isAuthenticated = useSelector<RootState, boolean>(getIsAuthenticated);
  const loggedInUser = useSelector<RootState, LoggedUser | null>(getUser);
  const { setRedirectAfterLoginPath, redirectAfterLoginCheck } = useRedirectAfterLogin();
  const history = useHistory();

  if (!isAuthenticated || loggedInUser === null) {
    setRedirectAfterLoginPath();

    history.push(Routes.Login);
    return null;
  }

  redirectAfterLoginCheck();

  // if user doesn't have at least one corresponding role
  if (accessRoles && !accessRoles.some((role) => hasUserAccessRole(loggedInUser, role))) {
    toast.error(intl.formatMessage({ id: accessDeniedToastMessage, defaultMessage: accessDeniedToastMessage }), {
      toastId: 'permission-denied', // temp fix to prevent toast showing up twice
    });

    history.push(accessDeniedRedirectPath);
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading -- For these special Router route, we allow props spreading.
  return <Component match={computedMatch} {...otherProps} />;
};

export default LoggedInRoute;
