import React, { createContext, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '@redux/Login';
import {
  getRefreshTokenFromLocalStorage,
  isRefreshTokenKey,
  setDelayedLogoutOnSessionExp,
} from 'services/auth/auth.service';

export const AuthProviderContext = createContext(null);

// TODO: Let's return User data from the AuthProviderContext e.g useAuth hook
export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const setupSessionEndLogout = useCallback(
    (refreshToken: string | null) => {
      // If the refresh token is removed, then the user has logged out from another tab
      if (!refreshToken) {
        dispatch(logoutUser());

        return;
      }

      // If the refresh token is updated, then the user has extended a session from another tab
      setDelayedLogoutOnSessionExp(refreshToken);
    },
    [dispatch],
  );

  // initial setup, check if the user is logged in and setup the session expire logout
  useEffect(() => {
    const refreshToken = getRefreshTokenFromLocalStorage();
    if (refreshToken) {
      setupSessionEndLogout(refreshToken);
    }
  }, [setupSessionEndLogout]);

  // listen for changes to the refresh token
  useEffect(() => {
    function handleLocalStorageChange(e: StorageEvent) {
      if (e.key && isRefreshTokenKey(e.key)) {
        setupSessionEndLogout(e.newValue);
      }
    }

    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, [setupSessionEndLogout]);

  return <AuthProviderContext.Provider value={null}>{children}</AuthProviderContext.Provider>;
};
