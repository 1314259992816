import { MediaStepTypes } from '@workerbase/types/WorkinstructionStep/WorkinstructionStep';
import { StepTypes } from '@workerbase/domain/workinstruction';

export class Constants {
  static PROJECT_FILE_SIZE_LIMIT = 524288000;

  static STEP_MEDIA_TYPE_MAPPING: {
    [key in StepTypes]?: MediaStepTypes;
  } = {
    [StepTypes.INPUT_PHOTO]: MediaStepTypes.IMAGE,
    [StepTypes.INPUT_AUDIO]: MediaStepTypes.AUDIO,
    [StepTypes.INPUT_VIDEO]: MediaStepTypes.VIDEO,
  };

  // The maximum result set size in elasticsearch is 10000
  static MAX_PER_PAGE = 10000 as const;

  static LOGIN_SSO_ERROR_DISPLAY_TIME_MS = 60 * 1000;
}
