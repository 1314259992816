export enum SafetySymbolsMandatory {
  M001 = 'M001',
  M002 = 'M002',
  M003 = 'M003',
  M004 = 'M004',
  M005 = 'M005',
  M006 = 'M006',
  M007 = 'M007',
  M008 = 'M008',
  M009 = 'M009',
  M010 = 'M010',
  M011 = 'M011',
  M012 = 'M012',
  M013 = 'M013',
  M014 = 'M014',
  M015 = 'M015',
  M016 = 'M016',
  M017 = 'M017',
  M018 = 'M018',
  M019 = 'M019',
  M020 = 'M020',
  M021 = 'M021',
  M022 = 'M022',
  M023 = 'M023',
  M024 = 'M024',
  // M025 = 'M025',
  M026 = 'M026',
  M027 = 'M027',
  M028 = 'M028',
  M029 = 'M029',
  M030 = 'M030',
  M031 = 'M031',
  M032 = 'M032',
  // M033 = 'M033',
  // M034 = 'M034',
  // M035 = 'M035',
  // M036 = 'M036',
  // M037 = 'M037',
  // M038 = 'M038',
  // M039 = 'M039',
  // M040 = 'M040',
  // M041 = 'M041',
  // M042 = 'M042',
  // M043 = 'M043',
  // M044 = 'M044',
  // M045 = 'M045',
  M046 = 'M046',
  M047 = 'M047',
  M048 = 'M048',
  // M049 = 'M049',
  // M050 = 'M050',
  // M051 = 'M051',
  // M052 = 'M052',
  // M053 = 'M053',
  // M054 = 'M054',
  // M055 = 'M055',
  M056 = 'M056',
  M057 = 'M057',
  M058 = 'M058',
  M059 = 'M059',
  // M060 = 'M060',
  M061 = 'M061',
  M062 = 'M062',
}

export enum SafetySymbolsSafeCondition {
  E001 = 'E001',
  E002 = 'E002',
  E003 = 'E003',
  E004 = 'E004',
  // The following symbols were previously part of ISO 7010, but have since been withdrawn from the standard. E005, E006
  // E005 = 'E005',
  // E006 = 'E006',
  E007 = 'E007',
  E008 = 'E008',
  E009 = 'E009',
  E010 = 'E010',
  E011 = 'E011',
  E012 = 'E012',
  E013 = 'E013',
  E014 = 'E014',
  E015 = 'E015',
  E016 = 'E016',
  E017 = 'E017',
  E018 = 'E018',
  E019 = 'E019',
  E020 = 'E020',
  E021 = 'E021',
  E022 = 'E022',
  E023 = 'E023',
  E024 = 'E024',
  E025 = 'E025',
  E026 = 'E026',
  E027 = 'E027',
  E028 = 'E028',
  E029 = 'E029',
  E030 = 'E030',
  E031 = 'E031',
  E032 = 'E032',
  E033 = 'E033',
  E034 = 'E034',
  E035 = 'E035',
  E036 = 'E036',
  E037 = 'E037',
  E038 = 'E038',
  E039 = 'E039',
  E040 = 'E040',
  E041 = 'E041',
  E042 = 'E042',
  E043 = 'E043',
  E044 = 'E044',
  E045 = 'E045',
  E046 = 'E046',
  E047 = 'E047',
  E048 = 'E048',
  E049 = 'E049',
  E050 = 'E050',
  E051 = 'E051',
  E052 = 'E052',
  E053 = 'E053',
  E054 = 'E054',
  E055 = 'E055',
  E056 = 'E056',
  E057 = 'E057',
  E058 = 'E058',
  E059 = 'E059',
  E060 = 'E060',
  E061 = 'E061',
  E062 = 'E062',
  E063 = 'E063',
  E064 = 'E064',
  E065 = 'E065',
  // E066 = 'E066', This symbol number is not used in the standard
  E067 = 'E067',
  E068 = 'E068',
  E069 = 'E069',
  E070 = 'E070',
}

export enum SafetySymbolsWarning {
  W001 = 'W001',
  W002 = 'W002',
  W003 = 'W003',
  W004 = 'W004',
  W005 = 'W005',
  W006 = 'W006',
  W007 = 'W007',
  W008 = 'W008',
  W009 = 'W009',
  W010 = 'W010',
  W011 = 'W011',
  W012 = 'W012',
  W013 = 'W013',
  W014 = 'W014',
  W015 = 'W015',
  W016 = 'W016',
  W017 = 'W017',
  W018 = 'W018',
  W019 = 'W019',
  W020 = 'W020',
  W021 = 'W021',
  W022 = 'W022',
  W023 = 'W023',
  W024 = 'W024',
  W025 = 'W025',
  W026 = 'W026',
  W027 = 'W027',
  W028 = 'W028',
  W029 = 'W029',
  W030 = 'W030',
  W031 = 'W031',
  W032 = 'W032',
  W033 = 'W033',
  W034 = 'W034',
  W035 = 'W035',
  W036 = 'W036',
  W037 = 'W037',
  W038 = 'W038',
  W039 = 'W039',
  W040 = 'W040',
  W041 = 'W041',
  W042 = 'W042',
  W043 = 'W043',
  W044 = 'W044',
  W045 = 'W045',
  W046 = 'W046',
  W047 = 'W047',
  W048 = 'W048',
  W049 = 'W049',
  W050 = 'W050',
  W051 = 'W051',
  W052 = 'W052',
  W053 = 'W053',
  W054 = 'W054',
  W055 = 'W055',
  W056 = 'W056',
  W057 = 'W057',
  W058 = 'W058',
  W059 = 'W059',
  W060 = 'W060',
  W061 = 'W061',
  W062 = 'W062',
  W063 = 'W063',
  W064 = 'W064',
  W065 = 'W065',
  W066 = 'W066',
  W067 = 'W067',
  W068 = 'W068',
  W069 = 'W069',
  W070 = 'W070',
  W071 = 'W071',
  W072 = 'W072',
  W073 = 'W073',
  W074 = 'W074',
  W075 = 'W075',
  W076 = 'W076',
  W077 = 'W077',
  W078 = 'W078',
  W079 = 'W079',
  W080 = 'W080',
}

export enum SafetySymbolsProhibition {
  P001 = 'P001',
  P002 = 'P002',
  P003 = 'P003',
  P004 = 'P004',
  P005 = 'P005',
  P006 = 'P006',
  P007 = 'P007',
  P008 = 'P008',
  P009 = 'P009',
  P010 = 'P010',
  P011 = 'P011',
  P012 = 'P012',
  P013 = 'P013',
  P014 = 'P014',
  P015 = 'P015',
  P016 = 'P016',
  P017 = 'P017',
  P018 = 'P018',
  P019 = 'P019',
  P020 = 'P020',
  P021 = 'P021',
  P022 = 'P022',
  P023 = 'P023',
  P024 = 'P024',
  P025 = 'P025',
  P026 = 'P026',
  P027 = 'P027',
  P028 = 'P028',
  P029 = 'P029',
  P030 = 'P030',
  P031 = 'P031',
  P032 = 'P032',
  P033 = 'P033',
  P034 = 'P034',
  P035 = 'P035',
  P036 = 'P036',
  P037 = 'P037',
  P038 = 'P038',
  P039 = 'P039',
  P040 = 'P040',
  P041 = 'P041',
  P042 = 'P042',
  P043 = 'P043',
  P044 = 'P044',
  P045 = 'P045',
  P046 = 'P046',
  P047 = 'P047',
  P048 = 'P048',
  P049 = 'P049',
  P050 = 'P050',
  P051 = 'P051',
  P052 = 'P052',
  P053 = 'P053',
  P054 = 'P054',
  P055 = 'P055',
  P056 = 'P056',
  P057 = 'P057',
  P058 = 'P058',
  P059 = 'P059',
  P060 = 'P060',
  P061 = 'P061',
  P062 = 'P062',
  P063 = 'P063',
  P064 = 'P064',
  P065 = 'P065',
  P066 = 'P066',
  P067 = 'P067',
  P068 = 'P068',
  P069 = 'P069',
  P070 = 'P070',
  P071 = 'P071',
  P072 = 'P072',
  P073 = 'P073',
  P074 = 'P074',
  P075 = 'P075',
}

export enum SafetySymbolsFireSafety {
  F001 = 'F001',
  F002 = 'F002',
  F003 = 'F003',
  F004 = 'F004',
  F005 = 'F005',
  F006 = 'F006',
  F007 = 'F007',
  F008 = 'F008',
  F009 = 'F009',
  F010 = 'F010',
  F011 = 'F011',
  F012 = 'F012',
  F013 = 'F013',
  F014 = 'F014',
  F015 = 'F015',
  F016 = 'F016',
  F017 = 'F017',
  F018 = 'F018',
  F019 = 'F019',
}

export enum SafetySymbolCategories {
  MANDATORY = 'mandatory',
  SAFE_CONDITION = 'safe-condition',
  WARNING = 'warning',
  PROHIBITION = 'prohibition',
  FIRE_SAFETY = 'fire-safety',
}
