import { FilterGroupOperator, FilterTypes, IFilterCondition, IFilterGroup } from '@workerbase/domain/common';
import { FLAGGING_CRITERIA_CONDITION_NAME, InputNumberOperators } from '@workerbase/domain/document';
import { ComparingConditionOperator } from '@workerbase/domain/rule';

export const DEFAULT_FLAGGING_CRITERIA_CONDITION: IFilterCondition<InputNumberOperators> = {
  type: FilterTypes.CONDITION,
  name: FLAGGING_CRITERIA_CONDITION_NAME,
  operator: ComparingConditionOperator.EQUAL,
  value: '1',
};

export const DEFAULT_FLAGGING_CRITERIA_GROUP: IFilterGroup<InputNumberOperators> = {
  type: FilterTypes.GROUP,
  groupOperator: FilterGroupOperator.AND,
  conditions: [DEFAULT_FLAGGING_CRITERIA_CONDITION],
};

export const DEFAULT_FLAGGING_CRITERIA: IFilterGroup<InputNumberOperators> = {
  groupOperator: FilterGroupOperator.OR,
  type: FilterTypes.GROUP,
  conditions: [DEFAULT_FLAGGING_CRITERIA_GROUP],
};
