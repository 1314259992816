import { WorkinstructionsListConfig } from '@workerbase/api/http/workinstruction';

import { Workinstruction } from 'services/types/Workinstruction';
import { RootState } from '../types';

export const getWorkinstructionsOnCurrentPage = (store: RootState) =>
  store.workinstructions.currentListItemsIds.map((id) => store.workinstructions.workinstructionsById[id]);

export const getListConfigs = (store: RootState): WorkinstructionsListConfig => store.workinstructions.listConfigs;

export const getWorkinstructionWithId =
  (workinstructionId?: string) =>
  (store: RootState): Workinstruction | undefined =>
    workinstructionId ? store.workinstructions.workinstructionsById[workinstructionId] : undefined;
