// Documentation is available here:
// https://www.notion.so/workerbase/WI-Step-Types-549e2ee77b364d12b55ba874fa6b18c2

import { StepsIconTypes } from '@workerbase/domain/workinstruction';
import { Step } from '../MongoDB/Workinstruction/Step';
import { StepButton } from '../MongoDB/Workinstruction/StepButton';

export enum MediaStepTypes {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export type WorkinstructionStep = Step;
export type WorkinstructionSteps = Step[];

export enum Devices {
  WATCH = 'watch',
  PHONE = 'phone',
  TABLET = 'tablet',
}

export enum StepButtonColors {
  YELLOW = '#FFD43D',
  RED = '#F74E47',
  BLUE = '#287AF5',
  GREEN = '#279567',
  BLACK = '#252A37',
  WHITE = '#DEEDFF',
}

const StepButtonStyles: Record<'PRIMARY' | 'SUCCESS' | 'WARNING' | 'DANGER', StepButtonStyles> = Object.freeze({
  PRIMARY: {
    backgroundColor: StepButtonColors.BLUE,
    textColor: StepButtonColors.WHITE,
  },
  SUCCESS: {
    backgroundColor: StepButtonColors.GREEN,
    textColor: StepButtonColors.WHITE,
  },
  WARNING: {
    backgroundColor: StepButtonColors.YELLOW,
    textColor: StepButtonColors.BLACK,
  },
  DANGER: {
    backgroundColor: StepButtonColors.RED,
    textColor: StepButtonColors.WHITE,
  },
});

export const generateButtonStylesForIconType = (icon?: StepsIconTypes): StepButtonStyles => {
  switch (icon) {
    case StepsIconTypes.WARNING_RED:
    case StepsIconTypes.WARNING_RED_CLEAR_BG:
    case StepsIconTypes.CLOSE:
      return StepButtonStyles.DANGER;
    case StepsIconTypes.QUESTION_YELLOW:
    case StepsIconTypes.WARNING_YELLOW:
      return StepButtonStyles.WARNING;
    case StepsIconTypes.CONFIRM_ENCIRCLED_GREEN:
      return StepButtonStyles.SUCCESS;
    default:
      return StepButtonStyles.PRIMARY;
  }
};

export interface StepButtonStyles {
  backgroundColor: string; // hex color
  textColor: string; // hex color
}

export { StepButton };
